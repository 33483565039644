import { Fragment, useEffect, useState } from 'react';
import { Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import {
  TableCell,
  TableRow,
  Typography,
  TextField,
  Box,
  Button,
  Grid,
  MenuItem,
  FormControl,
  Theme,
  Chip,
  InputAdornment,
} from '@material-ui/core';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import dateFormat from 'dateformat';
import Backdrop from '@mui/material/Backdrop';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../redux/reducers';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import LigneFacturation from '../../../Interface/LigneFacturation';
import Operation from '../../../Interface/Operation';

const urlApi = process.env.REACT_APP_BACKEND_URL;
const urlsymfony = process.env.REACT_APP_DOMAIN_BACK_SF_URL;

toast.configure();

const tabOpAuto = [
  1, 13, 12, 16, 17, 1004, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 1007, 15, 14
];
const tabIdOperationMalusAndBonus = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 1007];
const tabIdOperationFormation = [16, 17];
const IdOperationBM = 1007;
const tabIdOperationForPrixUnitaireInput = [16, 17, 5, 1006];
const tabIdFormationAndPanneTechnique = [13, 16, 17];
const tabIdOperationForfaitTypaFact = [1, 13, 14, 16, 17];
const tabRetourNullDmt = [
  1, 17, 15, 16, 14, 13, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 1007, 1005, 1006
];
const tabNotEditableFamilleOperation = [6];

const statutBdcWhoNeedDuplication = [10, 11];
const statutBdcEnProduction: number = 11;

const useStyles = makeStyles((theme: Theme) => ({
  actionBtn: {
    cursor: 'pointer',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  loading: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  tableRowUp: {
    backgroundColor: '#B6B6BF',
  },
  tableRowDown: {
    backgroundColor: '#EAECEE',
  },
  th: {
    color: 'white',
  },
}));

export interface RefCoutHoraire {
  id: number;
  dateDebut: Date;
  dateFin: Date;
  pays: string;
  niveau: string;
  langueSpecialite: string;
  coutHoraire: number;
  coutFormation: number;
  bu: string;
}

const RowLigneFact = (props) => {
  const classes = useStyles();
  const logger = useSelector<Reducers>((store) => store.logger);
  const {
    index,
    // eslint-disable-next-line no-unused-vars
    tabOperation,
    familleOperation,
    operation,
    typeFacturation,
    bus,
    langueTrt,
    handleUpdateTarifOfLignFact,
    handleDeleteClick,
    statutLeadDeletableOfLignaFact,
    idOperationDeletableOfLignaFact,
    arrayLignaFacturation,
    bonDeCommande,
    objectifQuantitatif,
    objectifQual,
    allcoutHoraire,
    paysProduction,
    idBuManuel,
    idLangTrtManuel,
    statutBdc
  } = props;
  const [ligneFacturation, setLigneFacturation] = useState<
    LigneFacturation | undefined
  >();
  const [openCollapse, setOpenCollapse] = useState(
    (Number(index?.isParamPerformed) !== 1 && Number(index?.operation) !== 15) && true
  );
  const [openSelectMultiple, setOpenSelectMultiple] = useState<boolean>(false);
  const [openSelectMultiple2, setOpenSelectMultiple2] = useState<boolean>(false);
  const [operations, setOperations] = useState<Operation[]>([]);
  const [operationsActe, setOperationsActe] = useState<Operation[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [coutHoraire, setCoutHoraire] = useState<RefCoutHoraire[]>([]);
  const [isClickSave, setIsClickSave] = useState<boolean>(false);

  const [urlImage, setUrlImage] = useState<String>('');

  const [indicatorQuant, setIndicatorQuant] = useState([]);
  const [indicatorQual, setIndicatorQual] = useState([]);

  const arrLignFact: LigneFacturation[] = arrayLignaFacturation;

  const [errorInput, setErrorInput] = useState<any>({
    bu: false,
    typeFacturation: false,
    langueTrt: false,
    paysProduction: false,
    paysFacturation: false,
    familleOperation: false,
    operation: false,
    coutHoraire: false,
    horaireProduction: false,
    categorieLead: false,
    volumeATraite: false,
    applicatifDate: false,
  });

  const [errorInputTypeFactActe, setErrorInputTypeFactActe] = useState<any>({
    tempsProductifs: false,
    tarifHoraireCible: false,
    dmt: false,
    volumeATraite: false,
  });

  const [errorInputTypeFactHeureAndForfait, setErrorInputTypeFactHeureAndForfait] = useState<any>({
    nbHeureMensuel: false,
    nbEtp: false,
  });

  const [errorInputTypeFactMixte, setErrorInputTypeFactMixte] = useState<any>({
    productiviteActe: false,
    prixUnitaireActe: false,
    prixUnitaireHeure: false
  });

  /**
  * Logique calcul prix unitaire
  * Prix unitaire = tarifHoraireCible / (tempProdictiifs / dmt)
  */
  useEffect(() => {
    if (Number(ligneFacturation?.typeFacturation) === 1) {
      if (ligneFacturation?.tarifHoraireCible && ligneFacturation?.tempsProductifs && ligneFacturation?.dmt) {
        const tarifHoraireCibleValue = ligneFacturation?.tarifHoraireCible;
        const tempProdValue =
          parseInt(ligneFacturation?.tempsProductifs?.slice(0, 2)) +
          parseInt(ligneFacturation?.tempsProductifs?.slice(3, 5)) / 60 +
          parseInt(ligneFacturation?.tempsProductifs?.slice(6, 8)) / 3600;
        const dmtValue =
          parseInt(ligneFacturation?.dmt?.slice(0, 2)) +
          parseInt(ligneFacturation?.dmt?.slice(3, 5)) / 60 +
          parseInt(ligneFacturation?.dmt?.slice(6, 8)) / 3600;

        const prix = tarifHoraireCibleValue / (tempProdValue / dmtValue);

        setLigneFacturation({
          ...ligneFacturation,
          prixUnit: prix?.toFixed(2)
        });
      }
    }
  }, [
    ligneFacturation?.typeFacturation,
    ligneFacturation?.tarifHoraireCible,
    ligneFacturation?.tempsProductifs,
    ligneFacturation?.dmt
  ]);

  const transformText = (text: string): string => {
    return (text)?.toLowerCase()?.replace(/\s+/g, '');
  };

  const valueOfParametrageColumn = () => {
    if (Number(ligneFacturation?.isParamPerformed) !== 1 && Number(ligneFacturation?.operation !== 15)) {
      return (
        <Tooltip title="A paramètrer">
          <CloseIcon color="error" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Déja parametré">
          <CheckIcon color="success" />
        </Tooltip>
      );
    }
  };

  /**
   * Injection data index dans notre intreface LigneFacturation
   */
  useEffect(() => {
    setLigneFacturation({
      ...index,
      duree: index?.Duree,
      langueTrt: index?.langueTrt,
      nbHeureMensuel: index?.nbHeureMensuel,
    });

    setIndicatorQuant(index?.indicateurQt);
    setIndicatorQual(index?.indicateurQl);

    // Mettre la valeur du champ description dans url image bonus/malus
    if (index?.description) {
      setUrlImage(index?.description);
    }
  }, []);

  useEffect(() => {
    if (Number(ligneFacturation?.typeFacturation) === 7) {
      let volumeMens: number;
      let prixActe: number;

      if (ligneFacturation?.productiviteActe &&
        ligneFacturation?.nbHeureMensuel &&
        ligneFacturation?.nbEtp) {
        /**
        * Si type de facturation est mixte
        * Volume mensuel = productiviteActe * nbHeureMensuel * nbEtp
        */
        volumeMens = (Number(ligneFacturation?.productiviteActe) * Number(ligneFacturation?.nbHeureMensuel)) * Number(ligneFacturation?.nbEtp);
      }

      if (ligneFacturation?.tarifHoraireCible &&
        ligneFacturation?.productiviteActe &&
        ligneFacturation?.prixUnitaireHeure) {
        /**
        * Si type de facturation est mixte
        * prix Unitaire Acte = (tarifHoraireCible - prixUnitaireHeure) / productiviteActe
        */

        prixActe = (Number(ligneFacturation?.tarifHoraireCible) - Number(ligneFacturation?.prixUnitaireHeure)) / Number(ligneFacturation?.productiviteActe);
      }

      setLigneFacturation({
        ...ligneFacturation,
        prixUnitaireActe: String(prixActe?.toFixed(2)),
        volumeATraite: String(volumeMens?.toFixed(2)),
      });
    }
  }, [
    ligneFacturation?.typeFacturation,
    ligneFacturation?.tarifHoraireCible,
    ligneFacturation?.prixUnitaireHeure,
    ligneFacturation?.productiviteActe,
    ligneFacturation?.nbHeureMensuel,
    ligneFacturation?.nbEtp
  ]);

  /**
   * Loqique bu et langue de traitement selectionné
   * auto dans l'operation ajout automatique qui a besoin
   */
  const uniqueSetBu = [...(new Set(idBuManuel) as any)];
  const uniqSetLangTrt = [...(new Set(idLangTrtManuel) as any)];

  const checkAttribut = (attr) => {
    if (attr === undefined || attr === null || String(attr).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const getbusLabel = (val) => {
    return bus.find((x) => x.id === val)?.libelle;
  };

  const getlangLabel = (lng) => {
    return langueTrt.find((x) => x.id === lng)?.libelle;
  };

  const getCoutHoraire = (pays, bu, lngTrt) => {
    return allcoutHoraire?.find(
      (element) =>
        element?.pays?.toLowerCase() === pays?.toLowerCase() &&
        element?.bu?.toLowerCase() === bu?.toLowerCase() &&
        element?.langue_specialite?.toLowerCase() === lngTrt?.toLowerCase()
    );
  };

  /**
   * Fonction qui retourne la valeur du profil agent
   */
  useEffect(() => {
    if (coutHoraire.length === 1) {
      setLigneFacturation({
        ...ligneFacturation,
        coutHoraire: Number(coutHoraire[0]?.id),
      });
    }
  }, [coutHoraire]);

  useEffect(() => {
    if (checkAttribut(ligneFacturation?.bu) && checkAttribut(ligneFacturation?.langueTrt)) {
      const libelleBu = getbusLabel(Number(ligneFacturation?.bu));
      const libellelngtrt = getlangLabel(Number(ligneFacturation?.langueTrt));

      const idcouthoraire = getCoutHoraire(
        paysProduction,
        libelleBu,
        libellelngtrt
      )?.id;

      setLigneFacturation({
        ...ligneFacturation,
        coutHoraire: idcouthoraire,
      });
    }
  }, [ligneFacturation?.bu, ligneFacturation?.langueTrt]);

  useEffect(() => {
    if (tabIdFormationAndPanneTechnique.includes(ligneFacturation?.operation) &&
      uniqueSetBu?.length === 1 && uniqSetLangTrt?.length === 1) {
      const libelleBu = getbusLabel(uniqueSetBu[0]);
      const libellelngtrt = getlangLabel(uniqSetLangTrt[0]);

      const idcouthoraire = getCoutHoraire(
        paysProduction,
        libelleBu,
        libellelngtrt
      )?.id;

      setLigneFacturation({
        ...ligneFacturation,
        coutHoraire: idcouthoraire,
      });
    }
  }, [
    ligneFacturation?.operation,
    uniqueSetBu,
    uniqSetLangTrt,
    paysProduction
  ]);

  /**
   * Retourne la valeur par defaut du Bu
   */
  /* useEffect(() => {
    if (uniqueSetBu.length === 1) {
      setLigneFacturation({
        ...ligneFacturation,
        bu: Number(uniqueSetBu[0])
      });
    }

  }, [uniqueSetBu]); */
  /**
  * Retourne la valeur par defaut du langue du traitement
  */
  /* useEffect(() => {
    if (uniqSetLangTrt.length === 1) {
      setLigneFacturation({
        ...ligneFacturation,
        langueTrt: Number(uniqSetLangTrt[0])
      });
    }

  }, [uniqSetLangTrt]); */
  const showMessageToastSuccess = () => {
    toast.success('Mise à jour a été effectuée avec succès .', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const resetValueOfAllRequiredInput = () => {
    setErrorInput((prev: any) => ({
      ...prev,
      bu: false,
      langueTrt: false,
      prixUnit: false,
      profilAgent: false,
      tarifHoraireCible: false,
      tempProductif: false,
      dmt: false,
      nbheureMensuel: false,
      ressourceFormer: false,
      nbEtp: false,
      duree: false,
      majoriteHnoDimanche: false,
      majoriteHnoHorsDimanche: false,
      applicatifDate: false
    }));

    setErrorInputTypeFactActe((prev: any) => ({
      ...prev,
      tempsProductifs: false,
      tarifHoraireCible: false,
      dmt: false,
      volumeATraite: false,
    }));

    setErrorInputTypeFactHeureAndForfait((prev: any) => ({
      ...prev,
      nbheureMensuel: false,
      nbEtp: false
    }));

    setErrorInputTypeFactMixte((prev: any) => ({
      ...prev,
      productiviteActe: false,
      prixUnitaireActe: false,
      prixUnitaireHeure: false,
    }));
  };

  const checkModifiedTarif = (): boolean => {
    let isModified: boolean = false;

    if (Number(statutBdc) === statutBdcEnProduction && index?.uniqBdcFqOperation) {
      if (Number(index?.typeFacturation) === 7) {
        if (Number(index?.prixUnitaireHeure)?.toFixed(2) !== Number(ligneFacturation?.prixUnitaireHeure)?.toFixed(2)) {
          isModified = true;
        }
        if (Number(index?.prixUnitaireActe)?.toFixed(2) !== Number(ligneFacturation?.prixUnitaireActe)?.toFixed(2)) {
          isModified = true;
        }
      } else {
        if (Number(index?.prixUnit)?.toFixed(2) !== Number(ligneFacturation?.prixUnit)?.toFixed(2)) {
          isModified = true;
        }
      }
    }

    return isModified;
  };

  useEffect(() => {
    const operationAll: Operation[] = operation;

    // Recupère les operations necessaire dans l'input operation
    if (operationAll?.length > 0) {
      const tabOperations = operationAll?.filter(elem => elem?.familleOperation?.id === Number(ligneFacturation?.familleOperation) && !(elem?.libelle?.toLowerCase())?.includes('hno'));

      setOperations(tabOperations);
    }
  }, [ligneFacturation?.bu, ligneFacturation?.familleOperation]);

  useEffect(() => {
    // Recupère les operations pour designation acte
    if (ligneFacturation?.bu) {
      const tmpBu: string = getbusLabel(ligneFacturation?.bu);

      const familleActe: number = familleOperation?.find(fo => (transformText(fo?.libelle)?.includes(transformText(tmpBu)) && transformText(fo?.libelle)?.includes('acte')))?.id;

      const acteOperation: Operation[] = operation?.filter(elem => (elem?.familleOperation?.id === familleActe && !(elem?.libelle?.toLowerCase())?.includes('hno')));

      setOperationsActe(acteOperation);
    }
  }, [ligneFacturation?.bu]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if (name === 'productiviteActe') {
      const productiviteActeValue: number = value < 0 ? 0 : value > 1 ? 1 : value;

      setLigneFacturation({
        ...ligneFacturation,
        productiviteActe: productiviteActeValue
      });
    } else {
      setLigneFacturation({
        ...ligneFacturation,
        indicateurQt: indicatorQuant as any,
        indicateurQl: indicatorQual as any,
        [name]: value,
      });
    }
  };

  const handleCloseSelectMulti = () => {
    setOpenSelectMultiple((prev) => !prev);
  };

  const handleCloseSelectMulti2 = () => {
    setOpenSelectMultiple2((prev) => !prev);
  };

  const handleChangeSelectMultitple = (e: any) => {
    e.stopPropagation();
    handleCloseSelectMulti();
    if (ligneFacturation?.objectifQuantitatif?.length > e.target.value.length) {
      const key = ligneFacturation?.objectifQuantitatif?.findIndex(obj => !e.target.value.includes(obj));
      const values = [...indicatorQuant];
      values.splice(key, 1);
      setIndicatorQuant(values);
      setLigneFacturation({
        ...ligneFacturation,
        indicateurQt: values as any,
        objectifQuantitatif: e.target.value,
      });
    } else {
      setIndicatorQuant([
        ...indicatorQuant,
        {
          indicator: '',
          objectifQt: ''
        }
      ]);
      setLigneFacturation({
        ...ligneFacturation,
        objectifQuantitatif: e.target.value,
      });
    }
  };

  const handleChangeSelectMultitple2 = (e: any) => {
    e.stopPropagation();
    handleCloseSelectMulti2();
    if (ligneFacturation?.objectifQualitatif?.length > e.target.value.length) {
      const key = ligneFacturation?.objectifQualitatif?.findIndex(obj => !e.target.value.includes(obj));
      const values = [...indicatorQual];
      values.splice(key, 1);
      setIndicatorQual(values);
      setLigneFacturation({
        ...ligneFacturation,
        indicateurQl: values as any,
        objectifQualitatif: e.target.value,
      });
    } else {
      setIndicatorQual([
        ...indicatorQual,
        {
          indicator: '',
          objectifQl: ''
        }
      ]);
      setLigneFacturation({
        ...ligneFacturation,
        objectifQualitatif: e.target.value,
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeIndicatorQt = (index, e) => {
    const values = [...indicatorQuant];
    /* eslint dot-notation: 2 */
    values[index].objectifQt = String(ligneFacturation?.objectifQuantitatif[index]);
    values[index][e.target.name] = e.target.value;

    setIndicatorQuant(values);

    setLigneFacturation({
      ...ligneFacturation,
      indicateurQt: values as any
    });
  };

  const handleChangeIndicatorQual = (index, event) => {
    const values = [...indicatorQual];
    /* eslint dot-notation: 2 */
    values[index].objectifQl = String(ligneFacturation?.objectifQualitatif[index]);
    values[index][event.target.name] = event.target.value;

    setIndicatorQual(values);

    setLigneFacturation({
      ...ligneFacturation,
      indicateurQl: values as any
    });
  };

  // Fonction exctracté pour l'ajout bdc opération
  const saveDataLigneFact = (data: LigneFacturation) => {
    setIsClickSave(true);

    // Si le bdc n'est en production
    if (!statutBdcWhoNeedDuplication?.includes(statutBdc)) {
      axios.put(
        `${urlApi}bdc/operation/${data?.id}`, data,
        {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        }
      ).then((res) => {
        if (res.data.error) {
          toast.warning(res.data.message, {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        } else {
          showMessageToastSuccess();
          setOpenCollapse(false);
          setLigneFacturation({
            ...data,
            isParamPerformed: 1,
          });
          // Mettre le nom de l'image stocké en local dans urlImage pour l'affiche
          if (Number(data?.operation) === IdOperationBM) {
            setUrlImage(String(res.data));
          }
        }
        setIsClickSave(false);
      }).catch((e) => {
        showMessageToastError();
        setIsClickSave(false);
      });
    } else {
      /**
       * Si le bdc est en production
       * Avec ligne de facturation bonus ou malus ou Hno
       */
      if (tabIdOperationMalusAndBonus?.includes(Number(data?.operation)) ||
        Number(data?.isHnoHorsDimanche) === 1 ||
        Number(data?.isHnoDimanche) === 1) {
        axios.put(
          `${urlApi}bdc/operation/${data?.id}`, data,
          {
            headers: {
              Authorization: `Bearer ${(logger as any)?.token}`,
            },
          }
        ).then((res) => {
          if (res.data.error) {
            toast.warning(res.data.message, {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          } else {
            showMessageToastSuccess();
            setOpenCollapse(false);
            setLigneFacturation({
              ...data,
              isParamPerformed: 1,
            });
            // Mettre le nom de l'image stocké en local dans urlImage pour l'affiche
            if (Number(data?.operation) === IdOperationBM) {
              setUrlImage(String(res.data));
            }
          }
          setIsClickSave(false);
        }).catch((e) => {
          showMessageToastError();
          setIsClickSave(false);
        });
      } else {
        let isModifiedPrixUnit: boolean = false;
        let isModifiedPrixActe: boolean = false;
        let isModifiedPrixHeure: boolean = false;

        if (Number(data?.typeFacturation) === 7) {
          if (Number(index?.prixUnitaireHeure)?.toFixed(2) !== Number(ligneFacturation?.prixUnitaireHeure)?.toFixed(2)) {
            isModifiedPrixHeure = true;
          }
          if (Number(index?.prixUnitaireActe)?.toFixed(2) !== Number(ligneFacturation?.prixUnitaireActe)?.toFixed(2)) {
            isModifiedPrixActe = true;
          }
        } else {
          if (Number(index?.prixUnit)?.toFixed(2) !== Number(ligneFacturation?.prixUnit)?.toFixed(2)) {
            isModifiedPrixUnit = true;
          }
        }

        if (isModifiedPrixUnit || isModifiedPrixActe || isModifiedPrixHeure) {
          const finaleData: LigneFacturation = {
            ...data,
            isTarifEdited: 1
          };

          handleUpdateTarifOfLignFact(finaleData);
          setTimeout(() => {
            showMessageToastSuccess();
            setOpenCollapse(false);
            setIsClickSave(false);
          }, 1000);
        } else {
          // S'il n'y a pas de changement au niveau du prix unitiare ou tarif
          axios.put(
            `${urlApi}bdc/operation/${data?.id}`, data,
            {
              headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
              },
            }
          ).then((res) => {
            if (res.data.error) {
              toast.warning(res.data.message, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
              });
            } else {
              showMessageToastSuccess();
              setOpenCollapse(false);
              setLigneFacturation({
                ...data,
                isParamPerformed: 1,
              });
              // Mettre le nom de l'image stocké en local dans urlImage pour l'affiche
              if (Number(data?.operation) === IdOperationBM) {
                setUrlImage(String(res.data));
              }
            }
            setIsClickSave(false);
          }).catch((e) => {
            showMessageToastError();
            setIsClickSave(false);
          });
        }
      }
    }
  };

  const IsHnoHorsDimancheOperation = () => {
    if (
      Number(index?.isHnoHorsDimanche) === 1 ||
      Number(ligneFacturation?.isHnoHorsDimanche) === 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const IsHnoDimancheOperation = () => {
    if (
      Number(index?.isHnoDimanche) === 1 ||
      Number(ligneFacturation?.isHnoDimanche) === 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  let tarifHoraireCibleLabel: any = 'Tarif horaire cible';
  let volumeAtraiterLabel: any = 'Volume mensuel à traiter';

  if ([1, 7].includes(Number(ligneFacturation?.typeFacturation))) {
    const tarifHoraireTxt = Number(ligneFacturation?.typeFacturation) === 1 ? 'Tarif horaire cible' : 'Tarif horaire cible globale';
    const volumeMensuelTxt = Number(ligneFacturation?.typeFacturation) === 1 ? 'Volume mensuel à traiter' : 'Volume mensuel à traiter pour l\'acte';

    tarifHoraireCibleLabel = (
      <Fragment>
        <span>{tarifHoraireTxt}</span>
        <span style={{ color: 'red', fontSize: 20 }}> *</span>
      </Fragment>
    );

    volumeAtraiterLabel = (
      <Fragment>
        <span>{volumeMensuelTxt}</span>
        <span style={{ color: 'red', fontSize: 20 }}> *</span>
      </Fragment>
    );
  }

  const nbheureMensuelLabel =
    !IsHnoHorsDimancheOperation() && !IsHnoDimancheOperation() ? (
      <Fragment>
        <span>Nombre d&apos;heure mensuel à facturé par ETP</span>
        <span style={{ color: 'red', fontSize: 20 }}> *</span>
      </Fragment>
    ) : (
      'Nombre d\'heure mensuel à facturé par ETP'
    );

  const nbEtpLabel =
    !IsHnoHorsDimancheOperation() && !IsHnoDimancheOperation() ? (
      <Fragment>
        <span>Nombre ETP</span>
        <span style={{ color: 'red', fontSize: 20 }}> *</span>
      </Fragment>
    ) : (
      'Nombre ETP'
    );

  const checkInputRequerid = (data: LigneFacturation) => {
    if (!data?.prixUnit) {
      setErrorInput((prev) => ({ ...prev, prixUnit: true }));
    }
    if (!ligneFacturation?.bu) {
      setErrorInput((prev) => ({ ...prev, bu: true }));
    }
    if (!ligneFacturation?.langueTrt) {
      setErrorInput((prev) => ({ ...prev, langueTrt: true }));
    }
    if (!ligneFacturation?.coutHoraire) {
      setErrorInput((prev) => ({ ...prev, profilAgent: true }));
    }
    if (!data?.tarifHoraireCible) {
      setErrorInput((prev) => ({ ...prev, tarifHoraireCible: true }));
    }
    if (!data?.tempsProductifs?.length) {
      setErrorInput((prev) => ({ ...prev, tempProductif: true }));
    }
    if (!data?.dmt) {
      setErrorInput((prev) => ({ ...prev, dmt: true }));
    }
    if (
      !IsHnoDimancheOperation() &&
      !IsHnoHorsDimancheOperation() &&
      !data?.nbHeureMensuel
    ) {
      setErrorInput((prev) => ({ ...prev, nbheureMensuel: true }));
    }
    if (
      !IsHnoHorsDimancheOperation() &&
      !IsHnoDimancheOperation() &&
      !data?.nbEtp
    ) {
      setErrorInput((prev) => ({ ...prev, nbEtp: true }));
    }
    if (!data?.ressourceFormer?.length) {
      setErrorInput((prev) => ({ ...prev, ressourceFormer: true }));
    }
    if (!data?.duree?.length) {
      setErrorInput((prev) => ({ ...prev, duree: true }));
    }
    if (!data?.majoriteHnoDimanche) {
      setErrorInput((prev) => ({ ...prev, majoriteHnoDimanche: true }));
    }
    if (!data?.majoriteHnoHorsDimanche) {
      setErrorInput((prev) => ({ ...prev, majoriteHnoHorsDimanche: true }));
    }
    if (checkModifiedTarif && !data?.applicatifDate) {
      setErrorInput((prev) => ({ ...prev, applicatifDate: true }));
    }

    // Control champs obligatoire pour le type de facturation mixte
    if (Number(ligneFacturation?.typeFacturation) === 7) {
      // Productivité Acte
      if (!checkAttribut(ligneFacturation?.productiviteActe)) {
        setErrorInputTypeFactMixte((prev: any) => ({ ...prev, productiviteActe: true }));
      }
      // Prix Acte
      if (!checkAttribut(ligneFacturation?.prixUnitaireActe)) {
        setErrorInputTypeFactMixte((prev: any) => ({ ...prev, prixUnitaireActe: true }));
      }
      // Prix Heure
      if (!checkAttribut(ligneFacturation?.prixUnitaireHeure)) {
        setErrorInputTypeFactMixte((prev: any) => ({ ...prev, prixUnitaireHeure: true }));
      }
    }
  };

  const validateSave = (data: LigneFacturation) => {
    if (
      data?.typeFacturation === 1 &&
      !tabIdOperationMalusAndBonus.includes(data?.operation) &&
      data?.isHnoDimanche !== 1 &&
      data?.isHnoHorsDimanche !== 1 &&
      data?.operation !== 15
    ) {
      if (
        data &&
        data?.tarifHoraireCible &&
        data?.tempsProductifs?.length &&
        data?.dmt &&
        data?.prixUnit &&
        data?.coutHoraire
      ) {
        if (checkModifiedTarif()) {
          ligneFacturation?.applicatifDate && saveDataLigneFact(data);
        } else {
          saveDataLigneFact(data);
        }
      }
    } else if (data?.operation === 16) {
      if (
        data &&
        data?.bu &&
        data?.langueTrt &&
        data?.duree?.length &&
        data?.ressourceFormer?.length &&
        data?.prixUnit &&
        data?.coutHoraire
      ) {
        if (checkModifiedTarif()) {
          ligneFacturation?.applicatifDate && saveDataLigneFact(data);
        } else {
          saveDataLigneFact(data);
        }
      }
    } else if (data?.operation === 17) {
      if (data && data?.prixUnit) {
        if (checkModifiedTarif()) {
          ligneFacturation?.applicatifDate && saveDataLigneFact(data);
        } else {
          saveDataLigneFact(data);
        }
      }
    } else if (data?.operation === 13) {
      if (
        data &&
        data?.bu &&
        data?.langueTrt &&
        data?.prixUnit &&
        data?.coutHoraire
      ) {
        if (checkModifiedTarif()) {
          ligneFacturation?.applicatifDate && saveDataLigneFact(data);
        } else {
          saveDataLigneFact(data);
        }
      }
    } else if (data?.isHnoDimanche === 1) {
      if (data && data?.majoriteHnoDimanche) {
        saveDataLigneFact(data);
      }
    } else if (data?.isHnoHorsDimanche === 1) {
      if (data && data?.majoriteHnoHorsDimanche) {
        saveDataLigneFact(data);
      }
    } else if (
      !IsHnoHorsDimancheOperation() &&
      !IsHnoDimancheOperation() &&
      data?.typeFacturation === 3
    ) {
      if (data && data?.nbEtp) {
        if (checkModifiedTarif()) {
          ligneFacturation?.applicatifDate && saveDataLigneFact(data);
        } else {
          saveDataLigneFact(data);
        }
      }
    } else {
      if (checkModifiedTarif()) {
        ligneFacturation?.applicatifDate && saveDataLigneFact(data);
      } else {
        saveDataLigneFact(data);
      }
    }
  };

  // Fonction qui enregistre les données bdc opération
  const handleSaveLigneFacturation = (data: LigneFacturation) => {
    // Reinitialise tout les validations à false
    resetValueOfAllRequiredInput();

    // Check formulaire obligatoire vide
    checkInputRequerid(data);

    // Validation ajout si les données obligatoires sont remplis
    validateSave(data);
  };

  // eslint-disable-next-line no-unused-vars
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // File converti to BASE64..........
  const handleChangeFile = async (event) => {
    const file = event.target.files[0];
    const base64Front = await convertBase64(file);

    setLigneFacturation({
      ...ligneFacturation,
      pdfDescription: base64Front as any,
    });
  };

  const getHNOtypeFacturationValue = () => {
    const ligneFacturationMere: LigneFacturation = arrLignFact?.find((y) => y?.operation === index?.operation && y?.valueHno === 'Oui');

    const typeFacturationMere: number = ligneFacturationMere?.typeFacturation;

    switch (typeFacturationMere) {
      case 7:
        switch (Number(index?.typeFacturation)) {
          case 1:
            return 'à l\'acte.';
          case 3:
            return 'à l\'heure.';
          default:
            return '';
        }
      default:
        return '';
    }
  };

  const nbEtpInput = (
    <Grid item xs={3}>
      <TextField
        id="outlined-number"
        name="nbEtp"
        label={nbEtpLabel}
        error={errorInputTypeFactHeureAndForfait.nbEtp}
        type="number"
        value={ligneFacturation?.nbEtp}
        onChange={(e) => handleChangeInput(e)}
        InputLabelProps={{
          shrink: ligneFacturation?.nbEtp && true,
        }}
        InputProps={{
          readOnly: (IsHnoHorsDimancheOperation() || IsHnoDimancheOperation()) && true,
        }}
        variant="standard"
      />
    </Grid>
  );

  const nbHeureMensuelInput = (
    <Grid item xs={3}>
      <TextField
        id="outlined-number"
        name="nbheureMensuel"
        label={nbheureMensuelLabel}
        error={errorInputTypeFactHeureAndForfait.nbheureMensuel}
        type="number"
        value={ligneFacturation?.nbHeureMensuel}
        onChange={(e) => handleChangeInput(e)}
        InputLabelProps={{
          shrink: ligneFacturation?.nbHeureMensuel && true,
        }}
        InputProps={{
          readOnly: (IsHnoHorsDimancheOperation() || IsHnoDimancheOperation()) && true,
        }}
        variant="standard"
      />
    </Grid>
  );

  const volumeATraiteInput = (
    <Grid item xs={3}>
      <TextField
        name="volumeATraite"
        type="number"
        label={volumeAtraiterLabel}
        value={ligneFacturation?.volumeATraite}
        InputProps={{
          readOnly: true,
        }}
        variant="standard"
        InputLabelProps={{
          shrink: ligneFacturation?.volumeATraite && true
        }}
      />
    </Grid>
  );

  const nbHeureAndVolumeMensuelInput = () => {
    switch (Number(ligneFacturation?.typeFacturation)) {
      case 3:
      case 5:
        return (
          <Fragment>
            {nbEtpInput}
            {nbHeureMensuelInput}
          </Fragment>
        );
      case 7:
        return (
          <Fragment>
            {nbEtpInput}
            {nbHeureMensuelInput}
            {volumeATraiteInput}
          </Fragment>
        );
      default:
        return volumeATraiteInput;
    }
  };

  const buLabel = (
    <Fragment>
      <span>Business unit</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const lngTrtLabel = (
    <Fragment>
      <span>Langue de traitement</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const prixUnitaire = (
    <Fragment>
      <span>Prix unitaire</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const tp = (
    <Fragment>
      <span>Temps productifs</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const dmt = (
    <Fragment>
      <span>Durée moyen traitement</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const dureeLabel = (
    <span style={{ color: 'red', fontSize: 20 }}>
      <span style={{ color: 'black', fontSize: 16 }}>Durée(Heure)</span> *
    </span>
  );

  const ressourceLabel = (
    <span style={{ color: 'red', fontSize: 20 }}>
      <span style={{ color: 'black', fontSize: 16 }}>
        Nombre de ressource à former
      </span>{' '}
      *
    </span>
  );

  const majHorsDimancheLabel = (
    <Fragment>
      <span>Majorité HNO (Du lundi au vendredi de 22h à 05h59)</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const majDimancheLabel = (
    <Fragment>
      <span>Majorité HNO (Dimanche et jours fériés)</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const productiviteActeLabel = (
    <Fragment>
      <span>Productivité acte</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const prixUnitAHeureLabel = (
    <Fragment>
      <span>Prix unitaire à l’heure</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const dateApplicatifLabel = (
    <Fragment>
      <span>Date applicatif</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  // Logique objectif qualitatifs input value
  const objectifQualInput = () => {
    if (
      tabOpAuto.includes(Number(index.operation)) ||
      Number(index.isHnoDimanche) === 1 ||
      Number(index.isHnoHorsDimanche) === 1
    ) {
      return null;
    } else {
      return (
        <Grid item xs={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="profil-contact">
              Objectifs qualitatifs
            </InputLabel>
            <Select
              open={openSelectMultiple2}
              onClick={() => handleCloseSelectMulti2()}
              multiple
              displayEmpty
              variant="standard"
              value={ligneFacturation?.objectifQualitatif || []}
              onChange={handleChangeSelectMultitple2}
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {((selected as string[]) || []).map((value) => (
                      <Chip
                        key={value}
                        label={
                          (objectifQual || []).find(
                            (item) => item.id === parseInt(value)
                          )?.libelle
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                );
              }}
              label="Objectifs qualitatifs"
              inputProps={{
                name: 'objectifQualitatif',
              }}
            >
              <MenuItem disabled value="">
                <em>Objectifs qualitatifs</em>
              </MenuItem>
              {(objectifQual || []).map((item, key) => (
                <MenuItem key={key} value={item.id}>
                  {item.libelle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(ligneFacturation?.objectifQualitatif || []).map((qualitatif, index) => (
            <div key={qualitatif}>
              <TextField
                name="indicator"
                label={`Indicateur pour ${objectifQual.find((z) => z.id === qualitatif)?.libelle}`}
                value={indicatorQual[index]?.indicator}
                onChange={(event) => handleChangeIndicatorQual(index, event)}
                variant="standard"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </div>
          ))}
        </Grid>
      );
    }
  };

  // Logique objectif quantitatifs input value
  const objectifQuantInput = () => {
    if (
      tabOpAuto.includes(Number(index.operation)) ||
      Number(index.isHnoDimanche) === 1 ||
      Number(index.isHnoHorsDimanche) === 1
    ) {
      return null;
    } else {
      return (
        <Grid item xs={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="profil-contact">
              Objectifs quantitatifs
            </InputLabel>
            <Select
              open={openSelectMultiple}
              onClick={() => handleCloseSelectMulti()}
              multiple
              displayEmpty
              label="Objectifs quantitatifs"
              inputProps={{
                name: 'objectifQuantitatif',
              }}
              variant="standard"
              value={ligneFacturation?.objectifQuantitatif || []}
              onChange={handleChangeSelectMultitple}
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {((selected as string[]) || []).map((value) => (
                      <Chip
                        key={value}
                        label={
                          (objectifQuantitatif || []).find(
                            (item) => item.id === parseInt(value)
                          )?.libelle
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                );
              }}
            >
              <MenuItem disabled value="">
                <em>Objectifs quantitatifs</em>
              </MenuItem>
              {(objectifQuantitatif || []).map((item, key) => (
                <MenuItem key={key} value={item.id}>
                  {item.libelle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(ligneFacturation?.objectifQuantitatif || []).map((quantitatif, index) => (
            <div key={quantitatif}>
              <TextField
                name="indicator"
                label={`Indicateur pour ${objectifQuantitatif.find((x) => x.id === quantitatif)?.libelle}`}
                value={indicatorQuant[index]?.indicator}
                onChange={(e) => handleChangeIndicatorQt(index, e)}
                variant="standard"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </div>
          ))}
        </Grid>
      );
    }
  };

  /**
   * Il apparaît si,
   * type de facturation === 1
   * n'est pas bonus et malus
   * operation different de 15
   */
  const tarifHoraireCible = () => {
    if ([1, 7].includes(Number(ligneFacturation?.typeFacturation))) {
      if (!tabIdOperationMalusAndBonus.includes(Number(ligneFacturation?.operation)) &&
        Number(ligneFacturation?.operation) !== 15 &&
        Number(ligneFacturation?.isHnoHorsDimanche) !== 1 &&
        Number(ligneFacturation?.isHnoDimanche) !== 1) {
        return (
          <Grid item xs={3}>
            <TextField
              id="outlined-number"
              name="tarifHoraireCible"
              label={tarifHoraireCibleLabel}
              error={errorInputTypeFactActe.tarifHoraireCible}
              type="number"
              value={ligneFacturation?.tarifHoraireCible}
              onChange={(e) => handleChangeInput(e)}
              className={classes.textField}
              InputLabelProps={{
                shrink: ligneFacturation?.tarifHoraireCible && true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="CA/H cible">
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </Grid>
        );
      }
    }
  };

  /**
  * Il apparaît si,
  * type de facturation === 1
  * n'est pas bonus et malus
  * operation different de 15
  */
  const tempProductif = () => {
    if (Number(index?.typeFacturation) === 1) {
      if (!tabIdOperationMalusAndBonus.includes(Number(ligneFacturation?.operation)) &&
        Number(ligneFacturation?.operation) !== 15 &&
        Number(ligneFacturation?.isHnoHorsDimanche) !== 1 &&
        Number(ligneFacturation?.isHnoDimanche) !== 1) {
        return (
          <Grid item xs={3}>
            <TextField
              label={tp}
              error={errorInputTypeFactActe.tempsProductifs}
              type="time"
              name="tempsProductifs"
              value={ligneFacturation?.tempsProductifs || null}
              onChange={(e) => handleChangeInput(e)}
              defaultValue="00:00:00"
              className={classes.textField}
              InputLabelProps={{
                shrink: ligneFacturation?.tempsProductifs && true,
              }}
              inputProps={{
                step: 2,
              }}
            />
          </Grid>
        );
      }
    }
  };

  /**
  * Il apparaît si,
  * n'est pas bonus et malus
  * n'est pas dans le tableau tabRetourNullDmt
  */
  const dureeMoyenTraitement = () => {
    if (!tabRetourNullDmt.includes(Number(ligneFacturation?.operation)) &&
      Number(ligneFacturation?.isHnoHorsDimanche) !== 1 &&
      Number(ligneFacturation?.isHnoDimanche) !== 1) {
      return (
        <Grid item xs={3}>
          <TextField
            label={dmt}
            error={errorInputTypeFactActe.dmt}
            name="dmt"
            type="time"
            value={ligneFacturation?.dmt || null}
            onChange={(e) => handleChangeInput(e)}
            defaultValue="00:00:00"
            className={classes.textField}
            InputLabelProps={{
              shrink: ligneFacturation?.dmt && true,
            }}
            inputProps={{
              step: 2,
            }}
          />
        </Grid>
      );
    }
  };

  const productiviteInput = (
    <Grid item xs={3}>
      <TextField
        label={productiviteActeLabel}
        name="productiviteActe"
        type="number"
        fullWidth
        error={errorInputTypeFactMixte?.productiviteActe}
        value={ligneFacturation?.productiviteActe}
        onChange={(e) => handleChangeInput(e)}
        InputLabelProps={{
          shrink: (ligneFacturation?.productiviteActe ||
            ligneFacturation?.productiviteActe === 0) && true,
        }}
        inputProps={{
          step: 0.05
        }}
        variant="standard"
      />
    </Grid>
  );

  const prixUnitaireHeureInput = (
    <Grid item xs={3}>
      <TextField
        label={prixUnitAHeureLabel}
        name="prixUnitaireHeure"
        type="number"
        fullWidth
        error={errorInputTypeFactMixte?.prixUnitaireHeure}
        value={ligneFacturation?.prixUnitaireHeure}
        onChange={(e) => handleChangeInput(e)}
        InputLabelProps={{
          shrink: ligneFacturation?.prixUnitaireHeure && true,
        }}
        variant="standard"
      />
    </Grid>
  );

  const prixUnitaireActe = (
    <Grid item xs={3}>
      <TextField
        label='Prix unitaire à l’acte'
        name="prixUnitaireActe"
        type="number"
        fullWidth
        error={errorInputTypeFactMixte?.prixUnitaireActe}
        value={ligneFacturation?.prixUnitaireActe}
        onChange={(e) => handleChangeInput(e)}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          readOnly: ligneFacturation?.prixUnitaireActe && true,
        }}
        variant="standard"
      />
    </Grid>
  );

  /**
  * Champ prix unitaire
  * Il apparaît si,
  * n'est pas bonus et malus
  * n'est pas Hno
  */
  const prixUnit = () => {
    if (Number(ligneFacturation?.typeFacturation) === 1 &&
      !tabIdOperationMalusAndBonus.includes(Number(ligneFacturation?.operation)) &&
      Number(ligneFacturation?.operation)) {
      return (
        <Grid item xs={3}>
          <TextField
            label={prixUnitaire}
            name="prixUnit"
            type="number"
            value={ligneFacturation?.prixUnit}
            onChange={(e) => handleChangeInput(e)}
            InputLabelProps={{
              shrink: ligneFacturation?.prixUnit && true,
            }}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
          />
        </Grid>
      );
    } else if (
      Number(ligneFacturation?.operation) === 13 ||
      Number(ligneFacturation?.operation) === 13 ||
      Number(ligneFacturation?.typeFacturation) === 3 ||
      tabIdOperationForPrixUnitaireInput.includes(Number(index?.operation))
    ) {
      return (
        <Grid item xs={3}>
          <TextField
            label={prixUnitaire}
            name="prixUnit"
            type="number"
            error={errorInput.prixUnit}
            value={ligneFacturation?.prixUnit}
            onChange={(e) => handleChangeInput(e)}
            InputLabelProps={{
              shrink: ligneFacturation?.prixUnit && true,
            }}
            variant="standard"
          />
        </Grid>
      );
    } else if (
      (tabIdOperationMalusAndBonus.includes(Number(index?.operation)) &&
        Number(ligneFacturation?.operation) !== 5) ||
      Number(ligneFacturation?.operation) === 15
    ) {
      return (
        <Grid item xs={3}>
          <TextField
            label="Prix unitaire"
            name="prixUnit"
            type="number"
            error={errorInput.prixUnit}
            value={ligneFacturation?.prixUnit}
            onChange={(e) => handleChangeInput(e)}
            InputLabelProps={{
              shrink: ligneFacturation?.prixUnit && true,
            }}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
          />
        </Grid>
      );
    } else {
      return (
        <Grid item xs={3}>
          <TextField
            label="Prix unitaire"
            name="prixUnit"
            type="number"
            value={ligneFacturation?.prixUnit}
            onChange={(e) => handleChangeInput(e)}
            InputLabelProps={{
              shrink: ligneFacturation?.prixUnit && true,
            }}
            variant="standard"
          />
        </Grid>
      );
    }
  };

  // Logique value input profil agent
  const profilAgentInput = () => {
    if (
      Number(ligneFacturation?.operation) === 1 ||
      Number(ligneFacturation?.operation) === 15 ||
      tabIdOperationMalusAndBonus.includes(Number(index?.operation))
    ) {
      return null;
    } else if (Number(ligneFacturation?.operation) === 14) {
      if (checkAttribut(ligneFacturation?.coutHoraire)) {
        return (
          <Grid item xs={3}>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel
                shrink={ligneFacturation?.coutHoraire && true}
                htmlFor="outlined-coutHoraire-always-notched"
              >
                Profil agent
              </InputLabel>
              <Select
                native
                label="Profil agent"
                value={ligneFacturation?.coutHoraire}
                onChange={(e) => handleChangeInput(e)}
                inputProps={{
                  name: 'coutHoraire',
                }}
                disabled
              >
                <option value=""></option>
                {allcoutHoraire.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.pays} - {item.bu} - {item.langue_specialite} - {item.cout_horaire}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      }
    } else if (Number(ligneFacturation?.isHnoDimanche) === 1 ||
      Number(ligneFacturation?.isHnoHorsDimanche) === 1) {
      return (
        <Grid item xs={3}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel
              shrink={ligneFacturation?.coutHoraire && true}
              htmlFor="outlined-coutHoraire-always-notched"
            >
              Profil agent
            </InputLabel>
            <Select
              native
              label="Profil agent"
              value={ligneFacturation?.coutHoraire}
              onChange={(e) => handleChangeInput(e)}
              inputProps={{
                name: 'coutHoraire',
              }}
              disabled
            >
              <option value=""></option>
              {allcoutHoraire.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.pays} - {item.bu} - {item.langue_specialite} - {item.cout_horaire}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    } else if (Number(index?.familleOperation) === 64 || Number(index?.operation) === 1006) {
      return (
        <Grid item xs={3}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel
              shrink={ligneFacturation?.coutHoraire && true}
              htmlFor="outlined-coutHoraire-always-notched"
            >
              Profil agent
            </InputLabel>
            <Select
              native
              label="Profil agent"
              value={ligneFacturation?.coutHoraire}
              onChange={(e) => handleChangeInput(e)}
              inputProps={{
                name: 'coutHoraire',
              }}
            >
              <option value=""></option>
              {allcoutHoraire.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.pays} - {item.bu} - {item.langue_specialite} - {item.cout_horaire}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={3}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel
              shrink={ligneFacturation?.coutHoraire && true}
              htmlFor="outlined-coutHoraire-always-notched"
            >
              Profil agent<span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              native
              label="Profil agent"
              value={ligneFacturation?.coutHoraire}
              onChange={(e) => handleChangeInput(e)}
              inputProps={{
                name: 'coutHoraire',
              }}
            >
              <option value=""></option>
              {allcoutHoraire.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.pays} - {item.bu} - {item.langue_specialite} - {item.cout_horaire}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    }
  };

  // Logique affichage bu
  const buInput = () => {
    if (
      tabIdOperationFormation.includes(Number(index?.operation)) ||
      Number(index?.operation) === 13
    ) {
      return (
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="bu" shrink={ligneFacturation?.bu && true}>
            <span style={{ color: 'red', fontSize: 20 }}>*</span>
          </InputLabel>
          <Select
            value={ligneFacturation?.bu}
            onChange={(e) => handleChangeInput(e)}
            label={buLabel}
            error={errorInput.bu}
            disabled={uniqueSetBu.length === 1 && true}
            inputProps={{
              name: 'bu',
            }}
            native
          >
            <option value=""></option>
            {bus.map((item) => (
              <option key={item.id} value={item.id}>
                {item.libelle}
              </option>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return bus.find((z) => z.id === +index.bu)?.libelle;
    }
  };

  // Logique affichage langue de traitement
  const langueDeTrt = () => {
    if (Number(index?.operation) === 1) {
      return null;
    } else if (
      tabIdOperationFormation.includes(Number(index?.operation)) ||
      Number(index?.operation) === 13
    ) {
      return (
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="langTrt" shrink={ligneFacturation?.langueTrt && true}>
            <span style={{ color: 'red', fontSize: 20 }}>*</span>
          </InputLabel>
          <Select
            value={ligneFacturation?.langueTrt}
            label={lngTrtLabel}
            error={errorInput.langueTrt}
            disabled={uniqSetLangTrt.length === 1 && true}
            onChange={(e) => handleChangeInput(e)}
            inputProps={{
              name: 'langueTrt',
            }}
            native
          >
            <option value=""></option>
            {langueTrt.map((item) => (
              <option key={item.id} value={item.id}>
                {item.libelle}
              </option>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return langueTrt.find((z) => z.id === +index.langueTrt)?.libelle;
    }
  };

  return (
    <Fragment>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isClickSave}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <TableRow className={classes.tableRowUp}>
        <TableCell align="center" style={{ color: 'black' }}>
          <Stack direction="row">
            {!tabNotEditableFamilleOperation.includes(Number(index?.familleOperation)) && (
              <IconButton
                aria-label="expand row"
                size="small"
                style={{ color: 'black' }}
                onClick={() => setOpenCollapse(!openCollapse)}
              >
                {openCollapse ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            <Box mt={1} ml={2}>
              {
                familleOperation.find((z) => z.id === +index?.familleOperation)
                  ?.libelle
              }
            </Box>
          </Stack>
        </TableCell>
        <TableCell>
          {operations.find((z) => z.id === +index.operation)?.libelle}
          {
            index?.isHnoDimanche === 1
              ? ` HNO ${getHNOtypeFacturationValue()} (Dimanche et jours fériés)`
              : index?.isHnoHorsDimanche === 1 &&
              ` HNO ${getHNOtypeFacturationValue()} (Du lundi au vendredi de 22h à 05h59)`
          }
          {tabIdFormationAndPanneTechnique?.includes(index?.operation) &&
            langueTrt.find((z) => z.id === +index.langueTrt)?.libelle}
        </TableCell>
        <TableCell>
          {
            typeFacturation.find((z) => z.id === +index.typeFacturation)
              ?.libelle
          }
        </TableCell>
        <TableCell>{buInput()}</TableCell>
        <TableCell>{langueDeTrt()}</TableCell>
        <TableCell>{ligneFacturation?.categorieLead}</TableCell>
        <TableCell></TableCell>
        <TableCell>{valueOfParametrageColumn()}</TableCell>
        <TableCell>
          <Stack spacing={0.5} direction="row">
            {(statutLeadDeletableOfLignaFact.includes(
              bonDeCommande?.statutLead
            ) ||
              idOperationDeletableOfLignaFact.includes(+index.operation)) && (
                <Typography
                  title="Supprimer cette ligne de facturation"
                  className={classes.actionBtn}
                  onClick={() => handleDeleteClick(index.id)}
                >
                  <Delete />
                </Typography>
              )}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                style={{ color: '#e75113' }}
                variant="h6"
                gutterBottom
                component="div"
              >
                Paramétrage
              </Typography>
              <Grid container spacing={4}>
                {objectifQuantInput()}

                {objectifQualInput()}

                {ligneFacturation?.designationActe && (
                  <Grid item xs={3}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel htmlFor="operation" shrink={ligneFacturation?.designationActe && true}>
                        Désignation acte <span style={{ color: 'red', fontSize: 20 }}>*</span>
                      </InputLabel>
                      <Select
                        value={ligneFacturation?.designationActe}
                        onChange={(e) => handleChangeInput(e)}
                        label="Désignation acte"
                        variant="standard"
                        inputProps={{
                          name: 'designationActe',
                        }}
                        native
                      >
                        <option value=""></option>
                        {operationsActe.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.libelle}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {tarifHoraireCible()}

                {tempProductif()}

                {Number(ligneFacturation?.typeFacturation) === 1 && dureeMoyenTraitement()}

                {Number(ligneFacturation?.typeFacturation) === 7 && productiviteInput}

                {Number(ligneFacturation?.typeFacturation) === 7 && prixUnitaireActe}

                {!tabOpAuto.includes(ligneFacturation?.operation) && nbHeureAndVolumeMensuelInput()}

                {Number(ligneFacturation?.typeFacturation) === 7 && prixUnitaireHeureInput}

                {Number(index?.operation) === 16 && (
                  <Fragment>
                    <Grid item xs={3}>
                      <TextField
                        label={dureeLabel}
                        error={errorInput.duree}
                        name="duree"
                        value={ligneFacturation?.duree || null}
                        onChange={(e) => handleChangeInput(e)}
                        autoComplete="new-password"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        name="ressourceFormer"
                        label={ressourceLabel}
                        error={errorInput.ressourceFormer}
                        value={ligneFacturation?.ressourceFormer || null}
                        onChange={(e) => handleChangeInput(e)}
                        autoComplete="new-password"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Fragment>
                )}

                {(!IsHnoHorsDimancheOperation() &&
                  !IsHnoDimancheOperation() &&
                  !tabIdOperationMalusAndBonus.includes(Number(ligneFacturation?.operation)) &&
                  Number(ligneFacturation?.typeFacturation) !== 7 &&
                  Number(ligneFacturation?.operation) !== 1007) &&
                  prixUnit()}

                {profilAgentInput()}

                {tabIdOperationForfaitTypaFact.includes(
                  Number(index?.operation)
                ) &&
                  Number(index?.operation) !== 13 && (
                    <Grid item xs={3}>
                      <FormControl className={classes.formControl}>
                        <FormLabel
                          id="demo-row-radio-buttons-group-label"
                          style={{ fontSize: '11px' }}
                        >
                          Offert
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="offert"
                          key={index}
                          value={ligneFacturation?.offert || ''}
                          onChange={(e) => handleChangeInput(e)}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio color="warning" />}
                            label="Oui"
                            labelPlacement="start"
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio color="warning" />}
                            label="Non"
                            labelPlacement="start"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}

                {IsHnoHorsDimancheOperation() && (
                  <Grid item xs={3}>
                    <TextField
                      name="majoriteHnoHorsDimanche"
                      label={majHorsDimancheLabel}
                      error={errorInput.majoriteHnoHorsDimanche}
                      type="number"
                      value={ligneFacturation?.majoriteHnoHorsDimanche || null}
                      defaultValue={index?.majoriteHnoHorsDimanche}
                      onChange={(e) => handleChangeInput(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  </Grid>
                )}

                {IsHnoDimancheOperation() && (
                  <Grid item xs={3}>
                    <TextField
                      name="majoriteHnoDimanche"
                      label={majDimancheLabel}
                      error={errorInput.majoriteHnoDimanche}
                      type="number"
                      value={ligneFacturation?.majoriteHnoDimanche || null}
                      defaultValue={index?.majoriteHnoDimanche}
                      onChange={(e) => handleChangeInput(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  </Grid>
                )}

                {tabIdOperationMalusAndBonus.includes(
                  Number(index?.operation)
                ) && (
                    <Fragment>
                      <Grid item xs={12}>
                        <label htmlFor="file">Import Pdf</label> <br />
                        <Typography display="initial">
                          <input
                            accept="application/pdf"
                            className={classes.formControl}
                            id="raised-button-file"
                            name="pdfDescription"
                            onChange={handleChangeFile}
                            multiple
                            type="file"
                          />
                        </Typography>
                      </Grid>
                      {
                        urlImage.length > 0 && (
                          <Grid item xs={12}>
                            <Box justifyContent="center" display="flex">
                              <img
                                src={urlsymfony + 'bonusMalusImage/' + urlImage}
                                alt="Description du bonus/Malus"
                                width={500}
                                height={830}
                              />
                            </Box>
                          </Grid>
                        )
                      }
                    </Fragment>
                  )}

                {(checkModifiedTarif() || ligneFacturation?.applicatifDate) && (
                  <Grid item xs={3}>
                    <TextField
                      name="applicatifDate"
                      label={dateApplicatifLabel}
                      type="date"
                      error={errorInput.applicatifDate}
                      value={
                        ligneFacturation?.applicatifDate &&
                        dateFormat(ligneFacturation?.applicatifDate, 'yyyy-mm-dd')
                      }
                      onChange={(e) => handleChangeInput(e)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Box>
                <div
                  style={{
                    textAlign: 'right',
                    marginTop: '15px',
                    marginBottom: '15px',
                  }}
                >
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    onClick={() => handleSaveLigneFacturation(ligneFacturation)}
                  >
                    Enregistrer
                  </Button>
                </div>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default RowLigneFact;
