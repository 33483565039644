import { ChangeEvent, FC, ReactNode } from 'react';
import {
  FilledInputProps,
  FormControl,
  FormHelperText,
  InputProps,
  OutlinedInputProps,
  TextField,
} from '@material-ui/core';
import { useStyles } from './styles';
import classNames from 'classnames';

/** {value: any, name: string} */
export interface MyCustomEvent {
  value: any;
  name: string;
}

export interface CustomTextFieldProps {
  label: string;
  name: string;
  value?: any;
  required?: boolean;
  error?: boolean;
  classeName?: string;
  fullWidth?: boolean;
  InputProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
  helperTextProps?: {
    color: string;
    content: ReactNode;
  };
  disabled?: boolean;
  type?: string;
  /** MyCustomEvent : {value: any, name: string} */
  onChange?: (event: MyCustomEvent) => void;
}

const CustomTextField: FC<CustomTextFieldProps> = ({
  label,
  name,
  value,
  error,
  onChange,
  required,
  helperTextProps,
  InputProps,
  classeName,
  fullWidth,
  type = 'text',
  disabled = false,
}) => {
  const classes = useStyles(helperTextProps?.color, fullWidth)();

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onChange &&
      onChange({ name: event.target.name, value: event.target.value });
  };

  return (
    <FormControl className={classNames(classes.root, classeName)}>
      <TextField
        type={type}
        className={classes.textField}
        value={value}
        name={name}
        onChange={handleChange}
        label={label}
        variant="outlined"
        error={error}
        InputProps={InputProps}
        required={required}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {helperTextProps && (
        <FormHelperText className={classes.helperText}>
          {helperTextProps.content}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomTextField;
