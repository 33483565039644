import { combineReducers } from 'redux';
import { loggerUserReducer, UserInformation } from './loggerUserReducer';

export interface Reducers {
  logger: UserInformation | null;
}

const allReducers = combineReducers<Reducers>({
  logger: loggerUserReducer,
});

export default allReducers;
