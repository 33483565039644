import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    bodyStyle: {
      paddingTop: '1px',
      paddingRight: '40px',
      paddingLeft: '40px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '100%',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%',
    },
    table: {
      minWidth: 650,
    },
    progress: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
        // marginRigth: theme.spacing(2),
      },
    },

    loading: {
      paddingTop: '1px',
      paddingRight: '40px',
      paddingLeft: '40px',
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    actionBtn: {
      cursor: 'pointer',
    },
    warning: {
      color: 'red',
      display: 'flex',
      alignItems: 'center',
    },
    alert: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    thead: {
      backgroundColor: '#808B96'
    },
    th: {
      color: 'white',
      fontSize: '14px'
    }
  })
);
