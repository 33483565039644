// import React from 'react'
import { Component } from 'react';
import ContentPage from './ContentPage';

class ComponentToPrint extends Component {
  render () {
    return <ContentPage idClient={this.props.children} />;
  }
}

export default ComponentToPrint;
