import { useState, Fragment, useEffect, SyntheticEvent, forwardRef } from 'react';
import axios from 'axios';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
// import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import { BdcOperationPerClient, BdcOperationPerClientClass } from '../../Interface/BdcOperationPerClient';
import HausseClientAjour from '../../Interface/HausseClientAjour';
import { useSelector } from 'react-redux';
import { Reducers } from '../../redux/reducers';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import Snackbar from '@mui/material/Snackbar';
import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  }));
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert (
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const HausseIndiceSyntecRow = (props) => {
    const [hausseClientByBack, setHausseClientByBack] = useState<HausseClientAjour>();
    const [couleur, setCouleur] = useState(0);
    const [open, setOpen] = useState(false);
    const [openErrer, setOpenErrer] = useState(false);
    const [voirBdc, setVoirBdc] = useState(0);
    const [voirContrat, setVoirContrat] = useState(0);
    const urlViewDoc = process.env.REACT_APP_VIEW_DOC_URL;
    const [radioVal, setRadioVal] = useState<string>('Syntec');
    const [clauseTab, setClauseTab] = useState<string>('oui');
    const [initial, setInitial] = useState<string>('');
    const [actuel, setActuel] = useState<string>('');
    const [dateContrat, setDateContrat] = useState('');
    const [contartbdc, setContartbdc] = useState('');
    const decInitial = parseFloat(initial.replace(',', '.'));
    const decActuel = parseFloat(actuel.replace(',', '.'));
    const [openCollapse, setOpenCollapse] = useState(false);
    const [idMere, setIdMere] = useState(0);
    const taux = (decActuel - decInitial) * 100 / decInitial;
    const row:BdcOperationPerClient = props.row;
    const urlApi = process.env.REACT_APP_BACKEND_URL;
    const logger = useSelector<Reducers>((state) => state.logger);
    const [showResults, setShowResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [valideShow, setValideShow] = useState(true);
    useEffect(() => {
        axios.get(`${urlApi}get/idbdcMere/by/idBdcOP/${row.ligne[0].idBdcO}`, {
            headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
            }
        }).then((res) => {
            setIdMere(res.data);
        });
        setCouleur(row?.valide);
        axios.get(`${urlApi}customers/ancien/customer/${row.idCustomer}`, {
            headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
            }
        }).then((res) => {
            if (res.data.length > 0) {
                setHausseClientByBack(res.data[0]);
                // console.log(res.data[0].initial);
                setInitial(res.data[0].initial);
                setActuel(res.data[0].actuel);
            }
        });
    }, []);
    // console.log(hausseClientByBack);
     const handleChangeClause = (event) => {
        setClauseTab(event.target.value);
      };
      const handleViewBDC = () => {
        setVoirBdc(1);
         window.open(
            `${urlViewDoc}/bdc/bdc_avenant_Hausse_${idMere}.pdf`,
            '_blank'
          )
          ;
      };
      const handleViewContrat = () => {
        setVoirContrat(1);
        window.open(
            `${urlViewDoc}/bdc/${row.raisonSocial} Avenant Revision taifaire 2022.pdf`,
            '_blank'
          );
      };
      const handleChangeDateContrat = event => {
        setDateContrat(event.target.value);
    };
    const handleClickValider = () => {
        setValideShow(false);
        setOpenCollapse(!openCollapse);
        contartbdc === '' ? setOpen(true) : console.log(contartbdc);
        axios.get(`${urlApi}validate/bdc/hausse/${idMere}/${contartbdc}`, {
            headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
            }
        }).then((res) => {
            setCouleur(2);
            // console.log(res.data);
        });
      };
    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        setOpenErrer(false);
      };

      const action = (
            <Alert severity="error">This is an error message!</Alert>
      );
    // console.log(idMere);
    const handleUpdateTarif = (row: BdcOperationPerClient) => {
        setIsLoading(true);
        // const lgafter : LigneClass = new LigneClass('', '', '');
        const Update1 : BdcOperationPerClientClass = new BdcOperationPerClientClass(row?.raisonSocial, 1, row?.ligne, taux, dateContrat, clauseTab, radioVal, initial, actuel, row?.idCustomer, props.dateApp);
        (isNaN(taux)) ? setOpenErrer(true) : axios.post(`${urlApi}bondeComande/update/hausse`, Update1, {
            headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
            }
        }).then((res) => {
            setShowResults(true);
            setIsLoading(false);
            setCouleur(1);
            // console.log(res.data);
        }).catch(() => {
            setIsLoading(false);
            }
        );
    };
    console.log(row);
    // console.log(props.row);
   // console.log(idMere);
  return (
    <Fragment>
        <TableRow
            sx={{
                '&:last-child td, &:last-child th': { border: 1 },
             backgroundColor: couleur === 1 ? '' : ''
        }}
            >
            <TableCell sx={{
                '&:last-child td, &:last-child th': { border: 3 },
             backgroundColor: couleur === 2 ? '#4AAB4A' : couleur === 1 ? '#e75113' : ''
        }}>
                <Stack direction="row">
                    <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenCollapse(!openCollapse)}
                    >
                    {openCollapse ? (
                        <KeyboardArrowUpIcon />
                    ) : (
                        <KeyboardArrowDownIcon />
                    )}
                    </IconButton>
                    <b>{row?.raisonSocial}</b>
                </Stack>
            </TableCell>
            <TableCell >
                <input
                    name="dateCreate"
                    type="date"
                    defaultValue=""
                    color="warning"
                    style={{ borderRadius: '4px', border: '1px solid #AAAAAA', height: '38px', color: '#757575', fontSize: '14px' }}
                    value={dateContrat}
                    onChange={handleChangeDateContrat}
                />
            </TableCell>
            <TableCell >
            <FormControl fullWidth>
                    <Select
                    name= {row?.raisonSocial}
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={clauseTab}
                    label="clause"
                    onChange={handleChangeClause}
                    defaultValue='oui'
                    color="warning"
                    >
                    <MenuItem value={'oui'}>OUI</MenuItem>
                    <MenuItem value={'non'}>NON</MenuItem>
                </Select>
            </FormControl>
            </TableCell>
            <TableCell >
                <Radio
                    checked={radioVal === 'Syntec' && true}
                    onChange={(e) => setRadioVal(e.target.value)}
                    color="warning"
                    value="Syntec"
                    name="radio-buttons1"
                    size="small"
                    inputProps={{ 'aria-label': 'A' }}
                />
            </TableCell>
            <TableCell >
                <Radio
                    checked={radioVal === 'Smic' && true}
                    onChange={(e) => setRadioVal(e.target.value)}
                    value="Smic"
                    color="warning"
                    name="radio-buttons2"
                    inputProps={{ 'aria-label': 'A' }}
                    size="small"
                />
            </TableCell>
            <TableCell >
                <Radio
                    checked={radioVal === 'IHCT-TS' && true}
                    onChange={(e) => setRadioVal(e.target.value)}
                    value="IHCT-TS"
                    color="warning"
                    name="radio-buttons3"
                    inputProps={{ 'aria-label': 'A' }}
                    size="small"
                />
            </TableCell>
            <TableCell >
                <TextField id="outlined-basic" label={radioVal + ' Initial'} variant="standard"
                value={initial}
                onChange={(event) => {
                    setInitial(event.target.value as string);
                    console.log(hausseClientByBack);
                    // setInitial(hausseClientByBack ? hausseClientByBack.initial : event.target.value as string);
                    // setInitial(event.target.value as string === '' ? hausseClientByBack ? hausseClientByBack.initial : '' : event.target.value as string);
                }}
                size="small"
                color="warning"/>
            </TableCell>
            <TableCell >
                <TextField id="standard-start-adornment" label={radioVal + ' Actuel'}
                 variant="standard"
                value={actuel}
                onChange={(event) => {
                    setActuel(event.target.value as string);
                }}
                size="small"
                color="warning"/>
            </TableCell>
            <TableCell >
                <TextField
                id="outlined-read-only-input"
                value={(isNaN(taux)) ? 'inserer les données' : taux.toFixed(2) + ' %'}
                InputProps={{
                    readOnly: true,
                }}
                size="small"
                color="warning"
                />
            </TableCell>
        </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        <TableContainer>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Ancien Tarif</TableCell>
                                        <TableCell align="center">Nouveau Tarif</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.ligne.map((BdcO, index) => (
                                        <TableRow key={index}>
                                            <TableCell> {BdcO.operationLabel} <b>{BdcO.PrixUnitaire}</b></TableCell>
                                            <TableCell>{BdcO.operationLabel} <b>{((isNaN(taux)) ? 0 : (((parseFloat(taux.toString()) + 100) * ((BdcO.PrixUnitaire !== null) && parseFloat(BdcO.PrixUnitaire.toString().replace(',', '.'))))) / 100).toFixed(2)}</b></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </Collapse>
                    </TableCell>
                </TableRow>
            {isLoading ? (
                  <TableRow >
                    <StyledTableCell align="center" colSpan={9}><Box my={2}><LinearProgress color="warning" /></Box></StyledTableCell>
                  </TableRow>
                ) : (<TableRow>
                    <TableCell colSpan={1}>
                        { showResults || row?.valide === 1 || row?.valide === 2 ? <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                                <Button sx= {{ width: 150, height: 50, padding: 1, margin: 1 }} color="warning" endIcon={<RemoveRedEyeIcon />} onClick={() => handleViewContrat()}>
                                    voir contrat
                                </Button>
                            </Collapse> : <></>}
                        </TableCell>
                        <TableCell colSpan={1}>
                        {showResults || row?.valide === 1 || row?.valide === 2 ? <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                                <Button sx={{ width: 150, height: 50, padding: 1, margin: 1 }} variant="contained" color="warning" endIcon={<RemoveRedEyeIcon />} onClick={() => handleViewBDC()}>
                                    voir bon de commande
                                </Button>
                            </Collapse>
                        : <></> }
                    </TableCell>
                    <TableCell colSpan={4}>
                        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                            {showResults || row?.valide === 1 ? <FormControl>
                                {
                                    voirBdc === 1 && voirContrat === 1 ? <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    >
                                    <FormControlLabel value="Contrat" color="warning" control={
                                        <Radio size="small" color="warning" value="Contrat" checked={contartbdc === 'Contrat' && true} onChange={(e) => setContartbdc(e.target.value)}/> }
                                        label="Contrat" />
                                    <FormControlLabel value="BDC" color="warning" control={<Radio size="small" color="warning" value="Bdc" checked={contartbdc === 'Bdc' && true} onChange={(e) => setContartbdc(e.target.value)}/>} label="Bon De Commande" />
                                </RadioGroup>
                                : <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    >
                                    <FormControlLabel value="Contrat" color="warning" control={
                                        <Radio size="small" color="warning" value="Contrat" checked={contartbdc === 'Contrat' && true} onChange={(e) => setContartbdc(e.target.value)}/> }
                                        label="Contrat" disabled/>
                                    <FormControlLabel value="BDC" color="warning" control={<Radio size="small" color="warning" value="Bdc" checked={contartbdc === 'Bdc' && true} onChange={(e) => setContartbdc(e.target.value)}/>} label="Bon De Commande" disabled/>
                                </RadioGroup>
                                }
                            </FormControl>
                            : <></> }
                        </Collapse>
                    </TableCell>
                    <TableCell colSpan={2}>
                        { row?.valide !== 2 ? <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                            <Button sx={{ width: 150, height: 50, padding: 1, margin: 1 }} variant="outlined" color="secondary" endIcon={<BrowserUpdatedIcon />} onClick={() => handleUpdateTarif(row)}>
                                mettre à jour le tarif
                            </Button>
                        </Collapse> : <></>
                        }
                    </TableCell>
                    <TableCell colSpan={2}>
                        {showResults || row?.valide === 1 ? valideShow ? <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                            <Button sx={{ width: 150, height: 50, padding: 1, margin: 1 }} variant="outlined" color="success" endIcon={<SendIcon />} onClick={() => handleClickValider()}>
                                Valider
                            </Button>
                        </Collapse> : <></> : <></>}
                    </TableCell>
                </TableRow>) }
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
            >
                 <Alert severity="error">vous devez choisir entre contrat ou bon de commande!</Alert>
            </Snackbar>
            <Snackbar
                open={openErrer}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
            >
                 <Alert severity="error">vous devez entrer le prix initial et le prix actuel!</Alert>
            </Snackbar>
        </Fragment>
  );
};

export default HausseIndiceSyntecRow;
