/* eslint-disable camelcase */
import { MenuItemProps } from '../menu-item';
import contactImg from '../../images/contacts.png';
import activite from '../../images/activite.png';
import stat from '../../images/statistique.png';
import bdc_icon from '../../images/listing2.png';
import fq_icon from '../../images/fq_icon.png';
import import_icon from '../../images/icon_excel.png';
// import pourcent_icon from '../../images/icon_Hausse3.png';

const fq_list = process.env.REACT_APP_FQ_URL as string;
const bdc_list = process.env.REACT_APP_BDC_URL as string;
const contact_list = process.env.REACT_APP_CONTACT_URL as string;
const activite_url = process.env.REACT_APP_ACTIVITE_URL as string;
const stat_url = process.env.REACT_APP_STATISTIQUE_DIRECTION as string;
const hausse_indic_url = process.env.REACT_APP_HAUSSE_INDICE_URL as string;
const cout_agent_url = '/import_cout_agent';

export const menuItems: MenuItemProps[] = [
  {
    info: {
      url: contact_list,
      name: 'CONTACT',
    },
    icon: contactImg,
    background: '#828181',
  },
  {
    info: {
      url: fq_list,
      name: 'FICHE QUALIFICATION'
    },
    icon: fq_icon,
    background: '#F0C300',
  },
  {
    info: {
      url: bdc_list,
      name: 'BON DE COMMANDE'
    },
    icon: bdc_icon,
    background: '#3F000F',
  },
  {
    info: {
      url: activite_url,
      name: 'ACTIVITE',
    },
    icon: activite,
    background: '#e75113',
  },
  {
    info: {
      url: stat_url,
      name: 'STATISTIQUE',
    },
    icon: stat,
    background: '#0088c1',
  },
  {
    info: {
      url: '/#',
      name: 'OUTILS',
    },
    icon: contactImg,
    background: '#121212',
  },
  {
    info: {
      url: cout_agent_url,
      name: 'IMPORT COUT AGENT',
    },
    icon: import_icon,
    background: '#4AAB4A',
  },
  {
    info: {
      url: hausse_indic_url,
      name: 'GESTION HAUSSE INDICE SYNTEC'
    },
    // icon: pourcent_icon,
    background: '#92a8d1',
  }
];
