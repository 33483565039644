import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Chip,
  MenuItem,
} from '@material-ui/core';
import { useCallback, useState, FC, useEffect } from 'react';
import { useStyles } from './styles';
import { Delete, Edit, Add, RemoveOutlined } from '@material-ui/icons';
import CustomModal from '../../commons/custom-modal/CustomModal';
import axios from 'axios';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../redux/reducers';
import DetailOperationForm, {
  DataRef,
  DonnePaysProd
} from '../detail-operation-form/DetailOperationForm';
import Operation from '../../../Interface/Operation';
import LeadDetailOperation from '../../../Interface/LeadDetailOperation';
import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';

export const generateId = () => Math.floor(Math.random() * 100) + Date.now();

const urlApi = process.env.REACT_APP_BACKEND_URL;

export interface ResumeLead {
  id?: number;
  dateDebut: Date;
  typeOffre?: string;
  resumePrestation?: string;
  potentielCA?: number;
  sepContactClient?: string;
  niveauUrgence: string;
  delaiRemiseOffre?: Date;
  dateDemarrage?: Date;
  isOutilFournis?: string;
  percisionClient?: string;
  pointVigilance?: string;
  piecesJointes?: [];
  originLead: number;
  dureeTrt: number;
  potentielTransformation: number;
  customer: number;
  leadDetailOperations: LeadDetailOperation[];
  interlocuteur: number[];
}

export interface Profil {
  id: number;
  libelle: string;
}
export interface Contact {
  id: number;
  nom: string;
  prenom: string;
  fonction: string;
  contactHasProfilContacts: Profil[];
}

export interface Client {
  id?: number;
  contacts: Contact[];
}

export interface DataFiles {
  name: string;
  base64: string;
}

export interface Ref {
  id?: number;
  libelle: string;
}

export interface customer {
  idClient: number;
}

export interface ResumeLeadFormProps {
  resumeLead?: ResumeLead;
  mode: 'EDIT' | 'ADD';
  onSave: (data: ResumeLead) => void;
  client?: customer;
  idOfEditFq: number;
  prevStep: boolean;
  callbackOne: number[];
}

const notDisplayedFamilleOperation = [1, 2, 3, 4, 5, 6, 7, 63];

const ResumeLeadForm: FC<ResumeLeadFormProps> = ({
  resumeLead,
  mode = 'ADD',
  onSave,
  client,
  idOfEditFq,
  prevStep,
  callbackOne,
}) => {
  const classes = useStyles();
  const logger = useSelector<Reducers>((store) => store.logger);

  const [errorInput, setErrorInput] = useState<any>({
    interlocuteurs: false,
    originLead: false,
    potentielTransformation: false,
    dureeTrt: false,
    niveauUrgence: false,
    dateDebut: false,
  });

  const [resumeLeadToSave, setResumeLeadToSave] = useState<ResumeLead>();
  const [open, setOpen] = useState<boolean>(false);
  const [detailOperationToEdit, setDetailOperationToEdit] = useState<
    LeadDetailOperation | undefined
  >();
  const [originLead, setOriginLead] = useState<Ref[]>([]);
  const [dureeTraitement, setDureeTraitement] = useState<Ref[]>([]);
  const [potentielTransformation, setPotentielTransformation] = useState<Ref[]>(
    []
  );
  const [dataPaysProduction, setDataPaysProduction] = useState<DonnePaysProd[]>(
    []
  );
  const [dataPaysFacturation, setDataPaysFacturation] = useState<DataRef[]>([]);
  const [dataOperation, setDataOperation] = useState<Operation[]>([]);
  const [dataBu, setDataBu] = useState<DataRef[]>([]);
  const [dataFamilleOperation, setDataFamilleOperation] = useState<DataRef[]>(
    []
  );
  const [dataLangueTraitement, setDataLangueTraitement] = useState<DataRef[]>(
    []
  );
  const [dataHoraireProduction, setDataHoraireProduction] = useState<DataRef[]>(
    []
  );
  const [dataTypeFacturation, setDataTypeFacturation] = useState<DataRef[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [dataFile, setDataFile] = useState<DataFiles>();
  const [loading, setLoading] = useState(true);
  const [isClickSave, setIsClickSave] = useState(false);
  const [recharge, setRecharge] = useState(false);
  const [inputFiles, SetInputFiles] = useState([{ file: '' }]);
  const [fq, setFq] = useState<ResumeLead[]>([]);
  const [customerContacts, setCustomerContacts] = useState<Client[]>([]);
  const [openSelectMultiple, setOpenSelectMultiple] = useState<boolean>(false);
  const [customerInEditedFq, setCustomerInEditedFq] = useState(0);

  const custom =
    typeof client !== 'undefined'
      ? client
      : typeof callbackOne !== 'undefined'
        ? callbackOne[0]
        : 0;

  const id = +fq?.map((x) => x?.id);
  const showData = useCallback(() => {
    setResumeLeadToSave(fq?.find((item) => item.id === id));
  }, [fq]);

  // Get referential datas
  useEffect(() => {
    // get customer with contacts
    if (client !== undefined) {
      axios
        .get(`${urlApi}customer/${+client}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          Array.isArray(res.data) && setCustomerContacts(res.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }

    // get origine lead
    axios.get(`${urlApi}ref/fiche/qualification/OriginLead`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setOriginLead(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get duree traitement
    axios.get(`${urlApi}ref/fiche/qualification/DureeTrt`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDureeTraitement(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get pontentiel transformation
    axios.get(`${urlApi}ref/fiche/qualification/PotentielTransformation`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setPotentielTransformation(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get pays prod
    axios.get(`${urlApi}bon/de/commande/ref/PaysProduction`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataPaysProduction(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get pays de facturation
    axios.get(`${urlApi}bon/de/commande/ref/PaysFacturation`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataPaysFacturation(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get all operation.....
    axios.get(`${urlApi}bon/de/commande/ref/Operation`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataOperation(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get fammille operation
    axios.get(`${urlApi}ref/fiche/qualification/FamilleOperation`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      const tab = [];
      for (const index in res.data) {
        if (!notDisplayedFamilleOperation.includes(Number(res.data[index].id))) {
          tab.push(res.data[index]);
        }
      }
      Array.isArray(tab) && setDataFamilleOperation(tab);
    }).catch((e) => {
      console.log(e.message);
    });

    // get BU
    axios.get(`${urlApi}ref/fiche/qualification/Bu`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataBu(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get langue de traitement
    axios.get(`${urlApi}ref/fiche/qualification/LangueTrt`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataLangueTraitement(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get horaire production
    axios.get(`${urlApi}ref/fiche/qualification/HoraireProduction`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataHoraireProduction(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get type facturation
    axios.get(`${urlApi}ref/fiche/qualification/TypeFacturation`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataTypeFacturation(res.data);
      setLoading(false);
    }).catch((e) => {
      console.log(e.message);
    });
  }, []);

  useEffect(() => {
    // get id fiche qualification
    if (Number(idOfEditFq) !== 0) {
      axios
        .get(`${urlApi}resume/lead/${Number(idOfEditFq)}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setCustomerInEditedFq(Number(res.data[0].customer.id));
          // console.log(receivedCustomer);
          setFq(
            (Array.isArray(res.data) && res.data).map((item) => {
              return {
                ...item,
                originLead: item?.originLead?.id
                  ? item?.originLead?.id
                  : undefined,
                dureeTrt: item?.dureeTrt?.id ? item?.dureeTrt?.id : undefined,
                potentielTransformation: item?.potentielTransformation?.id
                  ? item.potentielTransformation.id
                  : undefined,
                customer: item?.customer?.id
                  ? Number(item?.customer?.id)
                  : undefined,
                leadDetailOperations: (item?.leadDetailOperations || []).map(
                  (operation) => {
                    return {
                      ...operation,
                      typeFacturation: operation?.typeFacturation?.id
                        ? operation?.typeFacturation?.id
                        : undefined,
                      bu: operation?.bu.id ? operation?.bu.id : undefined,
                      langueTrt: operation?.langueTrt?.id
                        ? operation?.langueTrt?.id
                        : undefined,
                      operation: operation?.operation?.id
                        ? operation?.operation?.id
                        : undefined,
                      familleOperation: operation?.familleOperation?.id
                        ? operation?.familleOperation?.id
                        : undefined,
                      horaireProduction: operation?.horaireProduction?.id
                        ? operation?.horaireProduction?.id
                        : undefined,
                      paysFacturation: operation?.paysFacturation?.id
                        ? operation?.paysFacturation?.id
                        : undefined,
                      paysProduction: operation?.paysProduction?.id
                        ? operation?.paysProduction?.id
                        : undefined,
                      coutHoraire: operation?.coutHoraire?.id
                        ? operation?.coutHoraire?.id
                        : undefined,
                      objectifQualitatif: operation?.objectifQualitatif?.map(objQual => {
                        return objQual?.id;
                      }),
                      objectifQuantitatif: operation?.objectifQuantitatif?.map(objQuant => {
                        return objQuant?.id;
                      }),
                    } as LeadDetailOperation;
                  }
                ),
              } as ResumeLead;
            })
          );
          setLoading(false);
          // get customer with contacts
          axios
            .get(`${urlApi}customer/${Number(res.data[0].customer.id)}`, {
              headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
              },
            })
            .then((res) => {
              Array.isArray(res.data) && setCustomerContacts(res.data);
              if (prevStep === false) {
                setLoading(false);
              }
            })
            .catch((e) => {
              console.log(e.message);
            });
        }).catch((e) => {
          console.log(e.message);
        });
    } else if (prevStep === true && custom === 0) {
      axios
        .get(`${urlApi}resume/lead/${localStorage.getItem('id_resume_lead')}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setFq(
            (Array.isArray(res.data) && res.data).map((item) => {
              return {
                ...item,
                originLead: item?.originLead?.id
                  ? item?.originLead?.id
                  : undefined,
                dureeTrt: item?.dureeTrt?.id ? item?.dureeTrt?.id : undefined,
                potentielTransformation: item?.potentielTransformation?.id
                  ? item.potentielTransformation.id
                  : undefined,
                customer: item?.customer?.id
                  ? Number(item?.customer?.id)
                  : undefined,
                leadDetailOperations: (item?.leadDetailOperations || []).map(
                  (operation) => {
                    return {
                      ...operation,
                      typeFacturation: operation?.typeFacturation?.id
                        ? operation?.typeFacturation?.id
                        : undefined,
                      bu: operation?.bu.id ? operation?.bu.id : undefined,
                      langueTrt: operation?.langueTrt?.id
                        ? operation?.langueTrt?.id
                        : undefined,
                      operation: operation?.operation?.id
                        ? operation?.operation?.id
                        : undefined,
                      familleOperation: operation?.familleOperation?.id
                        ? operation?.familleOperation?.id
                        : undefined,
                      horaireProduction: operation?.horaireProduction?.id
                        ? operation?.horaireProduction?.id
                        : undefined,
                      paysFacturation: operation?.paysFacturation?.id
                        ? operation?.paysFacturation?.id
                        : undefined,
                      paysProduction: operation?.paysProduction?.id
                        ? operation?.paysProduction?.id
                        : undefined,
                      coutHoraire: operation?.coutHoraire?.id
                        ? operation?.coutHoraire?.id
                        : undefined
                    } as LeadDetailOperation;
                  }
                ),
              } as ResumeLead;
            })
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else if (custom !== 0) {
      axios
        .get(`${urlApi}get/resumelead/by/${custom}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setFq(
            (Array.isArray(res.data) && res.data).map((item) => {
              return {
                ...item,
                originLead: item?.originLead?.id
                  ? item?.originLead?.id
                  : undefined,
                dureeTrt: item?.dureeTrt?.id ? item?.dureeTrt?.id : undefined,
                potentielTransformation: item?.potentielTransformation?.id
                  ? item.potentielTransformation.id
                  : undefined,
                customer: item?.customer?.id
                  ? Number(item?.customer?.id)
                  : undefined,
                leadDetailOperations: (item?.leadDetailOperations || []).map(
                  (operation) => {
                    return {
                      ...operation,
                      typeFacturation: operation?.typeFacturation?.id
                        ? operation?.typeFacturation?.id
                        : undefined,
                      bu: operation?.bu.id ? operation?.bu.id : undefined,
                      langueTrt: operation?.langueTrt?.id
                        ? operation?.langueTrt?.id
                        : undefined,
                      operation: operation?.operation?.id
                        ? operation?.operation?.id
                        : undefined,
                      familleOperation: operation?.familleOperation?.id
                        ? operation?.familleOperation?.id
                        : undefined,
                      horaireProduction: operation?.horaireProduction?.id
                        ? operation?.horaireProduction?.id
                        : undefined,
                      paysFacturation: operation?.paysFacturation?.id
                        ? operation?.paysFacturation?.id
                        : undefined,
                      paysProduction: operation?.paysProduction?.id
                        ? operation?.paysProduction?.id
                        : undefined,
                      coutHoraire: operation?.coutHoraire?.id
                        ? operation?.coutHoraire?.id
                        : undefined
                    } as LeadDetailOperation;
                  }
                ),
              } as ResumeLead;
            })
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [logger, recharge]);

  useEffect(() => {
    if (prevStep === true) {
      showData();
    }
    /* if (prevStep === true || callbackOne.length !== 0) {
      showData();
    } */
    if (idOfEditFq !== 0) {
      showData();
    }
  }, [showData]);

  // File converti to base64
  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64Front = await convertBase64(file);
    const tempPieceJointes = [
      {
        ...dataFile,
        name: 'file',
        base64: base64Front,
      },
    ];
    setResumeLeadToSave({
      ...resumeLeadToSave,
      piecesJointes: tempPieceJointes as any,
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // eslint-disable-next-line no-unused-vars
  const checkAttribut = (attr) => {
    if (attr === undefined || attr === null || String(attr).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleChangeForm = useCallback(
    (event: any) => {
      setResumeLeadToSave({
        ...resumeLeadToSave,
        [event.target.name]: event.target.value,
      });
    },
    [resumeLeadToSave]
  );

  const handleCloseSelectMulti = () => {
    setOpenSelectMultiple((prev) => !prev);
  };

  const handleChageSelectMulti = (e) => {
    e.stopPropagation();
    handleCloseSelectMulti();
    setResumeLeadToSave((prev) => ({
      ...prev,
      interlocuteur: e.target.value,
    }));
  };

  const checkAllRequiredInput = () => {
    if (
      resumeLeadToSave?.interlocuteur?.length === 0 ||
      !checkAttribut(resumeLeadToSave?.originLead) ||
      !checkAttribut(resumeLeadToSave?.potentielTransformation) ||
      !checkAttribut(resumeLeadToSave?.dureeTrt) ||
      !checkAttribut(resumeLeadToSave?.niveauUrgence) ||
      !checkAttribut(resumeLeadToSave?.dateDebut)
    ) {
      return 'Not OK';
    } else {
      return 'Ok';
    }
  };

  const resetValueOfAllRequiredInput = () => {
    setErrorInput((prev: any) => ({
      ...prev,
      interlocuteurs: false,
      originLead: false,
      potentielTransformation: false,
      dureeTrt: false,
      niveauUrgence: false,
      dateDebut: false,
    }));
  };

  const handleSave = useCallback(() => {
    resetValueOfAllRequiredInput();
    // Check tout les champs obligatoire
    if (
      !checkAttribut(resumeLeadToSave?.interlocuteur) ||
      resumeLeadToSave?.interlocuteur?.length === 0
    ) {
      setErrorInput((prev: any) => ({ ...prev, interlocuteurs: true }));
    }
    if (!checkAttribut(resumeLeadToSave?.originLead)) {
      setErrorInput((prev: any) => ({ ...prev, originLead: true }));
    }
    if (!checkAttribut(resumeLeadToSave?.potentielTransformation)) {
      setErrorInput((prev: any) => ({
        ...prev,
        potentielTransformation: true,
      }));
    }
    if (!checkAttribut(resumeLeadToSave?.dureeTrt)) {
      setErrorInput((prev: any) => ({ ...prev, dureeTrt: true }));
    }
    if (!checkAttribut(resumeLeadToSave?.niveauUrgence)) {
      setErrorInput((prev: any) => ({ ...prev, niveauUrgence: true }));
    }
    if (!checkAttribut(resumeLeadToSave?.dateDebut)) {
      setErrorInput((prev: any) => ({ ...prev, dateDebut: true }));
    }

    if (checkAllRequiredInput() === 'Ok') {
      if (resumeLeadToSave?.typeOffre === 'Appel d’offre' &&
        !checkAttribut(resumeLeadToSave?.piecesJointes)) {
        /**
         * Si type d'offre est égal ' Appel d'offre '
         * Alors, on verifie si le FQ possède au moins une pièces jointes
         */
        toast.warning('Désolé ! Le champ pièce jointe est obligatoire !', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      } else if (!checkAttribut(resumeLeadToSave?.leadDetailOperations)) {
        // Verifie si le FQ possède au moins une operation
        toast.warning('Désolé ! veuillez ajouter au moins une opération !', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      } else {
        setIsClickSave(true);
        // Si tout les champs obligatoire sont rempli alors, on enregistrer le FQ
        if (prevStep === true) {
          return onSave({
            ...resumeLeadToSave,
            customer: resumeLeadToSave?.customer,
          });
        } else if (idOfEditFq !== 0) {
          return onSave({
            ...resumeLeadToSave,
            customer: customerInEditedFq,
          });
        } else {
          return onSave({
            ...resumeLeadToSave,
            customer: client?.idClient ? client?.idClient : +client,
          });
        }
      }
    } else {
      toast.error('SVP, veuillez remplir tous les champs en rouge !', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      });
    }
  }, [resumeLeadToSave, onSave, logger]);

  const handleClickAddDetailOperation = () => {
    setOpen(true);
    setDetailOperationToEdit(undefined);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveDetailOperation = useCallback(
    (data: LeadDetailOperation) => {
      if (data.id) {
        const tempDetailOperations =
          resumeLeadToSave?.leadDetailOperations?.map((item) => {
            if (item.id === data.id) {
              return data;
            }
            return item;
          });
        setResumeLeadToSave((prev) => ({
          ...prev,
          leadDetailOperations: tempDetailOperations,
        }));
      } else {
        const tempData = { ...data, id: generateId(), uniq: generateId() };
        let leadDetailOperations = resumeLeadToSave?.leadDetailOperations;
        if (!leadDetailOperations) leadDetailOperations = [];
        leadDetailOperations?.push(tempData);
        setResumeLeadToSave((prev) => ({ ...prev, leadDetailOperations }));
      }
      setOpen(false);
    },
    [resumeLeadToSave]
  );

  const handleEditClick = useCallback((item: LeadDetailOperation) => {
      setDetailOperationToEdit(item);
      setOpen(true);
    },
    [resumeLeadToSave]
  );

  const handleDeleteClick = (id) => {
    if (idOfEditFq !== 0) {
      if (window.confirm('Etes-vous sûr de vouloir supprimer ?')) {
        axios
          .delete(`${urlApi}delete/lead/detail/operation/${id}`, {
            headers: {
              Authorization: `Bearer ${(logger as any)?.token}`,
            },
          })
          .then((res) => {
            setRecharge(!recharge);
            toast.success(
              "Suppression d'une opération effectuée avec succès",
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
              }
            );
          })
          .catch((e) => {
            toast.error("Une erreure s'est produite, merci de ressayer.", {
              position: 'top-right',
              autoClose: 60000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          });
      }
    } else {
      setResumeLeadToSave({
        ...resumeLeadToSave,
        leadDetailOperations: resumeLeadToSave?.leadDetailOperations?.filter(
          (item) => item.id !== id
        ),
      });
    }
  };

  const handleAddInputFiles = () => {
    SetInputFiles([...inputFiles, { file: '' }]);
  };

  const handleRemoveInputFiles = (index) => {
    const values = [...inputFiles];
    values.splice(index, 1);
    SetInputFiles(values);
  };

  const messageValidation =
    resumeLeadToSave?.originLead == null ||
      resumeLeadToSave?.potentielTransformation == null ||
      resumeLeadToSave?.dureeTrt == null ||
      resumeLeadToSave?.dateDebut == null ||
      !resumeLeadToSave?.interlocuteur?.length ? (
      <Alert variant="filled" severity="error">
        Attention tous les champs en <b>*</b> rouge sont obligatoire, merci de
        remplir SVP !
      </Alert>
    ) : null;

  /**
   * Style loader............................................
   */
  const progress = (
    <div className={classes.loading}>
      <LinearProgress />
      <LinearProgress color="secondary" />
    </div>
  );

  /**
   * Logique champ date debut obligatoire
   */
  const dateDebutOblig = (
    <span style={{ color: 'red', fontSize: 20 }}>
      <span style={{ color: 'black', fontSize: 16 }}>Date de debut</span> *
    </span>
  );

  const pieceJointLabel =
    resumeLeadToSave?.typeOffre === 'Appel d’offre' ? (
      <span style={{ color: 'red', fontSize: 16 }}>
        <span style={{ color: 'black', fontSize: 12 }}>
          Pièce jointe (Max 10Mo)
        </span>{' '}
        *
      </span>
    ) : (
      'Pièce jointe (Max 10Mo)'
    );

  /**
   * Variable pour stocker les forms......................................................................
   */
  const formResumeLead = (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12} sm={12} lg={12}>
          <Typography
            variant="h3"
            style={{
              color: '#e75113',
              textShadow: '#828181 0.05em 0.05em 0.05em',
            }}
          >
            Fiche qualification
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="interLoc">
              Interlocuteur{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              open={openSelectMultiple}
              onClick={() => handleCloseSelectMulti()}
              error={errorInput?.interlocuteurs}
              multiple
              displayEmpty
              value={resumeLeadToSave?.interlocuteur || []}
              onChange={handleChageSelectMulti}
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {((selected as string[]) || []).map((value) => (
                      <Chip
                        key={value}
                        label={customerContacts?.map(
                          (a) =>
                            a?.contacts?.find(
                              (item) => item.id === parseInt(value)
                            )?.prenom
                        )}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                );
              }}
              label="Interlocuteur"
              inputProps={{
                name: 'interLoc',
              }}
            >
              <MenuItem disabled value="">
                <em>Interlocuteur</em>
              </MenuItem>
              {customerContacts.map((a) =>
                a?.contacts?.map((row, key) => (
                  <MenuItem key={key} value={row.id}>
                    {row.prenom} - {row.nom} - {row.fonction}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.TextField}
              type="number"
              value={resumeLeadToSave?.potentielCA || ''}
              name="potentielCA"
              onChange={handleChangeForm}
              label="Potentiel CA Annuel (Valeur en €)"
              variant="outlined"
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple" shrink={true}>
              Origine du lead{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              native
              value={resumeLeadToSave?.originLead}
              onChange={handleChangeForm}
              error={errorInput?.originLead}
              label="Origine du lead"
              inputProps={{
                name: 'originLead',
              }}
            >
              <option value=""></option>
              {originLead.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.libelle}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-always-notched-2" shrink={true}>
              Pontentiel de transformation{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              value={resumeLeadToSave?.potentielTransformation}
              onChange={handleChangeForm}
              label="Pontentiel de transformation"
              error={errorInput?.potentielTransformation}
              inputProps={{
                name: 'potentielTransformation',
              }}
              native
            >
              <option value=""></option>
              {potentielTransformation.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.libelle}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-always-notched">SEP</InputLabel>
            <Select
              value={resumeLeadToSave?.sepContactClient}
              onChange={handleChangeForm}
              label="SEP"
              inputProps={{
                name: 'sepContactClient',
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Tooltip title="Envoie vers Service Etude">
                    <HelpOutlineIcon />
                  </Tooltip>
                </InputAdornment>
              }
              defaultValue="0"
              native
            >
              <option value=""></option>
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-always-notched-1" shrink={true}>
              Durée du traitement{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              value={resumeLeadToSave?.dureeTrt}
              onChange={handleChangeForm}
              label="Durée du traitement"
              error={errorInput?.dureeTrt}
              inputProps={{
                name: 'dureeTrt',
              }}
              native
            >
              <option value=""></option>
              {dureeTraitement.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.libelle}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="civilite" shrink={true}>
              Niveau d&#39;urgence{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              native
              required
              label="Niveau d'urgence"
              error={errorInput?.niveauUrgence}
              value={resumeLeadToSave?.niveauUrgence}
              onChange={handleChangeForm}
              inputProps={{
                name: 'niveauUrgence',
              }}
            >
              <option aria-label="None" value="" />
              <option value="Urgent">Urgent</option>
              <option value="Normal">Normal</option>
              <option value="Pas urgent">Pas urgent</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="civilite" shrink={true}>
              Type d&#39;offre
            </InputLabel>
            <Select
              native
              required
              label="Type d'offre"
              value={resumeLeadToSave?.typeOffre}
              onChange={handleChangeForm}
              inputProps={{
                name: 'typeOffre',
              }}
            >
              <option aria-label="None" value="" />
              <option value="BDC simple">BDC simple</option>
              <option value="BDC + offre commerciale">
                BDC + offre commerciale
              </option>
              <option value="Appel d’offre">Appel d’offre</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-always-notched" shrink={true}>
              Outils fournis
            </InputLabel>
            <Select
              value={resumeLeadToSave?.isOutilFournis}
              onChange={handleChangeForm}
              label="Outils fournis"
              inputProps={{
                name: 'isOutilFournis',
              }}
              native
            >
              <option value=""></option>
              <option value="Outils clients">Outils clients</option>
              <option value="Outil outsourcia">Outil outsourcia</option>
              <option value="Outil client et outsourcia">
                Outil client et outsourcia
              </option>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl className={classes.container}>
            <TextField
              name="dateDebut"
              label={dateDebutOblig}
              type="date"
              defaultValue=""
              error={errorInput?.dateDebut}
              value={
                resumeLeadToSave?.dateDebut &&
                dateFormat(resumeLeadToSave?.dateDebut, 'yyyy-mm-dd')
              }
              onChange={handleChangeForm}
              className={classes.date}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.container}>
            <TextField
              name="delaiRemiseOffre"
              label="Délai souhaité pour la remise de l'offre"
              type="date"
              defaultValue=""
              // value={resumeLeadToSave?.delaiRemiseOffre}
              value={
                resumeLeadToSave?.delaiRemiseOffre &&
                dateFormat(resumeLeadToSave?.delaiRemiseOffre, 'yyyy-mm-dd')
              }
              onChange={handleChangeForm}
              className={classes.date}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.container}>
            <TextField
              name="dateDemarrage"
              label="Date de démarrage prévue"
              type="date"
              defaultValue=""
              value={
                resumeLeadToSave?.dateDemarrage &&
                dateFormat(resumeLeadToSave?.dateDemarrage, 'yyyy-mm-dd')
              }
              onChange={handleChangeForm}
              className={classes.date}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <label>
            <textarea
              name="resumePrestation"
              className={classes.textArea}
              rows={4}
              placeholder="Résumé de la prestation"
              value={resumeLeadToSave?.resumePrestation}
              onChange={handleChangeForm}
            />
          </label>
        </Grid>

        <Grid item xs={6}>
          <label>
            <textarea
              name="percisionClient"
              className={classes.textArea}
              rows={4}
              placeholder="Précisions éventuellement apportées par le client"
              value={resumeLeadToSave?.percisionClient}
              onChange={handleChangeForm}
              style={{ minHeight: 50 }}
            />
          </label>
        </Grid>

        <Grid item xs={12}>
          <label>
            <textarea
              name="pointVigilance"
              className={classes.textArea}
              rows={4}
              placeholder="Points de vigilance"
              value={resumeLeadToSave?.pointVigilance}
              onChange={handleChangeForm}
            />
          </label>
        </Grid>

        <Grid item xs={12} spacing={2}>
          <label htmlFor="file">{pieceJointLabel}</label> <br></br>
          {inputFiles.map((inputFile, index) => (
            <div key={index}>
              <Typography display="initial">
                <input
                  accept="*"
                  className={classes.formControl}
                  id="raised-button-file"
                  name="piecesJointes"
                  onChange={handleFileRead}
                  multiple
                  type="file"
                />
              </Typography>
              <Typography display="inline">
                <IconButton>
                  <RemoveOutlined
                    onClick={() => handleRemoveInputFiles(index)}
                  />
                </IconButton>
              </Typography>
            </div>
          ))}
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleAddInputFiles()}
          >
            Ajouter plus de pièce jointe
          </Button>
          {/* <label htmlFor="raised-button-file">
            <Button
              color="primary"
              variant="contained"
              component="span"
              className={classes.actionBtn}
            >
              Ajouter une pièce jointe
            </Button>
          </label> */}
          {/* <div>
            <label htmlFor="file">Pièce jointe (Max 10Mo)</label>
            <br />
            <input
              id="piecesJointes"
              type="file"
              value={resumeLeadToSave?.piecesJointes || null}
              required
              name="piecesJointes"
              onChange={handleChangeForm}
            />
            </div>
            <div>
            <br /> <br />
            <Button color="primary" variant="contained">
              Ajouter une pièce jointe
            </Button>
          </div> */}
        </Grid>

        <Grid item md={12} xs={12} lg={12} sm={12}>
          <div>
            <Typography
              variant="h4"
              style={{
                textAlign: 'center',
                color: '#e75113',
                textShadow: '#828181 0.05em 0.05em 0.05em',
              }}
            >
              Détail de l&#39;opération
            </Typography>
            <br />
            <Button
              startIcon={<Add />}
              color="primary"
              variant="outlined"
              onClick={handleClickAddDetailOperation}
            >
              Ajouter une opération
            </Button>
          </div>
        </Grid>

        <Grid item md={12} xs={12} lg={12} sm={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Pays de production</TableCell>
                  <TableCell>Pays de facturation</TableCell>
                  <TableCell>Business unit</TableCell>
                  <TableCell>Famille opération</TableCell>
                  <TableCell>Opération</TableCell>
                  <TableCell>Catégorie lead</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(resumeLeadToSave?.leadDetailOperations || []).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {
                        dataPaysProduction.find(
                          (x) => x?.id === +item?.paysProduction
                        )?.libelle
                      }
                    </TableCell>
                    <TableCell>
                      {
                        dataPaysFacturation.find(
                          (x) => x.id === +item.paysFacturation
                        )?.libelle
                      }
                    </TableCell>
                    <TableCell>
                      {dataBu.find((x) => x.id === +item.bu)?.libelle}
                    </TableCell>
                    <TableCell>
                      {
                        dataFamilleOperation.find(
                          (x) => x.id === +item.familleOperation
                        )?.libelle
                      }
                    </TableCell>
                    <TableCell>
                      {
                        dataOperation.find((x) => x.id === +item.operation)
                          ?.libelle
                      }
                    </TableCell>
                    <TableCell>{item.categorieLead}</TableCell>

                    <TableCell>
                      <Box display="flex" justifyContent="space-between">
                        <Typography
                          title="Modifier"
                          className={classes.actionBtn}
                          onClick={() => handleEditClick(item)}
                        >
                          <Edit />
                        </Typography>
                        <Typography
                          title="Suprimer"
                          className={classes.actionBtn}
                          onClick={() => handleDeleteClick(item.id)}
                        >
                          <Delete />
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSave}
        >
          {!isClickSave ? (
            'Enregistrer'
          ) : (
            <CircularProgress color="inherit" size={18} />
          )}
        </Button>
      </Box>{' '}
      <br />
      <div>{messageValidation}</div>
      <CustomModal
        title="Ajouter une opération"
        onClose={handleClose}
        open={open}
      >
        <DetailOperationForm
          onSave={handleSaveDetailOperation}
          paysProduction={dataPaysProduction}
          paysFacturation={dataPaysFacturation}
          businessUnit={dataBu}
          familleOperation={dataFamilleOperation}
          dataOperation={dataOperation}
          langueTraitement={dataLangueTraitement}
          horaireProduction={dataHoraireProduction}
          typeFacturation={dataTypeFacturation}
          value={detailOperationToEdit}
        />
      </CustomModal>
    </Box>
  );

  /**
   * Logique page loading............................................
   */
  const loader = !loading ? formResumeLead : progress;

  return <div>{loader}</div>;
};

export default ResumeLeadForm;
