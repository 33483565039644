// import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dateFormat from 'dateformat';

const TableNumeroBdc = (props) => {
  const urlViewDoc = process.env.REACT_APP_VIEW_DOC_URL;

  const tab = props.numBdc;

  const dateJ = new Date();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">N°BDC</TableCell>
            <TableCell align="left">Date signature</TableCell>
            <TableCell align="left">Pays de production</TableCell>
            <TableCell align="left">BU</TableCell>
            <TableCell align="left">Statuts</TableCell>
            <TableCell align="left">Ancienneté</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tab?.resumeLeads?.map((x) =>
            x.bdcs?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                  <a
                    style={{ color: 'red' }}
                    href={`${urlViewDoc}/bdc/bdc_${row.id}.pdf`}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    {row.id}
                  </a>
                </TableCell>
                <TableCell align="left">
                  {dateFormat(row.dateSignature, 'dd-mm-yyyy')}
                </TableCell>
                <TableCell align="left">{row.paysProduction.libelle}</TableCell>
                <TableCell align="left">
                  {row?.bdcOperations
                    ?.map((index) => index?.bu?.libelle)
                    .toLocaleString()}
                </TableCell>
                <TableCell align="left">
                  {row.statutLead === 1
                    ? 'Fiche société créer'
                    : row.statutLead === 2
                      ? 'Fiche qualification créer'
                      : row.statutLead === 3
                        ? 'En attente de validation N+1'
                        : row.statutLead === 4
                          ? 'Validé'
                          : row.statutLead === 5
                            ? 'A valider par le Service financier'
                            : row.statutLead === 6
                              ? 'Client et opération injecté dans IRM'
                              : row.statutLead === 7
                                ? 'Rejeté par le N+1'
                                : row.statutLead === 9 &&
                                'Bon de Commande envoyé au client'}
                </TableCell>
                <TableCell align="left">
                  {(
                    (dateJ.getTime() - new Date(row.dateSignature).getTime()) /
                    (1000 * 3600 * 24)
                  ).toFixed(0)}{' '}
                  Jour(s)
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableNumeroBdc;
