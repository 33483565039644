import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
      },
      zIndex: 1300,
    },
    textFieldContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    seachBarContainer: {
      width: 200,
      height: 50,
      marginRight: 16
    }
  })
);
