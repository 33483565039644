// import React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Button, FormControlLabel, Typography, Box } from '@material-ui/core';
import Switch from '@mui/material/Switch';
import InableDisabledModal from '../../forms/fiche-societe-form/InableDisabledModal';
import CircularProgress from '@mui/material/CircularProgress';
import Contact from '../../../Interface/Contact';

const ButtonSwitch = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { contact, openStatusModal, handleChangeStatutContact } = props;
    const [contactToSave, setContactToSave] = useState<Contact | undefined>();
    const [openModal, setOpenModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isBtnClicked, setIsBtnClicked] = useState(false);

    useEffect(() => {
        setContactToSave(contact);
    }, [contact]);

    const showModal = e => {
        e.preventDefault();
        setOpenModal(true);
    };
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleChange = (val: boolean) => {
        handleChangeStatutContact({ ...contact, status: !val });
        setOpenModal(openStatusModal);
    };

    return (
        <Fragment>
            <Box>
                <FormControlLabel
                    label={contactToSave?.status ? 'Activer' : 'Désactiver'}
                    control={
                    <Switch
                        size="small"
                        color="warning"
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={contactToSave?.status}
                    />}
                    onClick={showModal}
                />
            </Box>
            <InableDisabledModal onClose={handleClose} open={openModal}>
                <Typography>Vous-êtes sur le point {!contactToSave?.status ? 'd\'activer' : 'de désactiver'} ce contact.</Typography>
                <Typography style={{ marginTop: '10px' }}>Ëtes-vous sûr de vouloir continuer cette action ?</Typography>
                <Box display="flex" justifyContent="center" mt={3} >
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => handleChange(contactToSave?.status)}
                    >
                        {isBtnClicked ? (<CircularProgress color="inherit" size={16} />) : ('Oui')}
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginLeft: '50px' }}
                    >
                        Non
                    </Button>
                </Box>
            </InableDisabledModal>
        </Fragment>
    );
};

export default ButtonSwitch;
