import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    formControl: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    TextField: {
      width: '100%',
    },
    selectInter: {
      width: '100%'
    },
    labelInter: {
      width: '100%',
      marginBottom: '1px'
    },
    textArea: {
      width: '100%'
    },
    warning: {
      color: 'red',
      display: 'flex',
      alignItems: 'center',
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    titleContactsContainer: {
      display: 'flex',
      alignItems: 'center',
      '& h3': {
        marginRight: theme.spacing(3),
      },
      flexWrap: 'wrap',
    },
    table: {},
    actionBtn: {
      cursor: 'pointer',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    date: {
      /* marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1), */
      width: '100%',
    },
    loading: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      }
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  })
);
