import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    bodyStyle: {
      paddingTop: '1px',
      paddingRight: '20px',
      paddingLeft: '0px',
      width: '100%'
    },
    formControl: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    TextField: {
      width: '100%',
    },
    textArea: {
        width: '100%'
    },
    titleBackGround: {
        color: '#e75113',
        textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: '19px'
    },
    listeItemBackGround: {
        backgroundColor: '#e75113'
    },
    lienRapportActivite: {
      color: '#265301'
    },
    simpleTitle: {
        color: '#77777B',
        fontWeight: 'bold',
        textDecoration: 'underline',
        fontSize: '17px'
    },
    titleKpi: {
      fontWeight: 'bold',
      fontSize: '14px'
    },
    button: {
        width: '100%'
    },
    warning: {
      color: 'red',
      display: 'flex',
      alignItems: 'center',
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    titleContactsContainer: {
      display: 'flex',
      alignItems: 'center',
      '& h3': {
        marginRight: theme.spacing(3),
      },
      flexWrap: 'wrap',
    },
    table: {},
    actionBtn: {
      cursor: 'pointer',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    date: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '60%',
    },
    loading: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      }
    },
  })
);
