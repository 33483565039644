import { FC } from 'react';
import Routes from './router';
import Navigation from './components/navigation';
import Menu from './components/menu';
import './assets/css/w3.css';

require('dotenv').config();

const App: FC = () => {
  return (
    <>
      <header>
        <Navigation />
      </header>
      <main className="main">
        <Menu />
        <Routes />
      </main>
    </>
  );
};

export default App;
