import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { useCallback, useRef, useState, FC, useEffect } from 'react';

import { useStyles } from './styles';
import pays from '../../../constants/countries';
import paysFrCodeJson from '../../../constants/countriesFr';
import PhoneInput from 'react-phone-input-2';
import { Add, Check, Clear, Delete, Edit, Warning } from '@material-ui/icons';
import CustomModal from '../../commons/custom-modal/CustomModal';
import ContactForm, { ContactProfile } from '../contact-form';
import ButtonSwitch from '../../Contact/ButtonSwitch/ButtonSwitch';
import CircularProgress from '@mui/material/CircularProgress';
import Contact from '../../../Interface/Contact';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../redux/reducers';
import { useParams, useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';

toast.configure();
const idProfilSignataire = 4;
const copieFacture = 1;

const payFrCode: any = paysFrCodeJson;
const paysFr = pays.map((item) => ({
  ...item,
  name: payFrCode[item.code] ? payFrCode[item.code] : item.name,
}));
export const generateId = () => Math.floor(Math.random() * 100) + Date.now();

const urlApi = process.env.REACT_APP_BACKEND_URL;
export interface FicheSociete {
  id?: number;
  raisonSocial?: string;
  marqueCommercial?: string;
  adresse?: string;
  cp?: string;
  ville?: string;
  siteWeb?: string;
  tel?: string;
  isAdressFactDiff?: number;
  categorieClientId?: number;
  mappingClientId?: number;
  pays?: string;
  userId?: number;
  contacts?: Contact[];
  adresseFacturation?: {
    id?: number;
    cp?: string;
    adresse?: string;
    ville?: string;
    pays?: string;
  };
  sageCompteTiers?: string;
  sageCompteCollectif?: string;
  sageCategorieComptable?: string;
}

export interface Ref {
  id?: number;
  libelle: string;
}

export interface FicheSocieteFormProps {
  ficheSociete?: FicheSociete;
  mode: 'EDIT' | 'ADD';
  valeur: boolean;
  parentCallback: (creat: []) => void;
  onSave: (data: FicheSociete) => void;
  cust: number;
}

interface ErrorForm {
  raisonSociale?: boolean;
  marqueSociale?: boolean;
  mappingClientId?: boolean;
  categorieClientId?: boolean;
  contacts?: boolean;
}

// Component
const FicheSocieteForm: FC<FicheSocieteFormProps> = ({
  ficheSociete,
  mode = 'ADD',
  valeur,
  parentCallback,
  onSave,
  cust,
}) => {
  const classes = useStyles();

  const logger = useSelector<Reducers>((store) => store.logger);
  const history = useHistory();
  // states
  const [ficheSocieteToSave, setFicheSocieteToSave] = useState<FicheSociete>();
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [isDeleteContact, setIsDeleteContact] = useState<boolean>(false);
  const [idForContactToRemove, setIdForContactToRemove] = useState<number>(null);
  const [contactToEdit, setContactToEdit] = useState<Contact | undefined>();
  const [showAddressFact, setShowAddressFact] = useState<boolean>(false);
  const [categoriesClient, setCategoriesClient] = useState<Ref[]>([]);
  const [mappingClients, setMappingClients] = useState<Ref[]>([]);
  const [contactprofiles, setContactprofiles] = useState<ContactProfile[]>([]);
  const [formError, setFormError] = useState<ErrorForm>({
    raisonSociale: false,
    marqueSociale: false,
    mappingClientId: false,
    categorieClientId: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [customers, setCustomers] = useState<FicheSociete[]>([]);
  const [customer, setCustomer] = useState<FicheSociete[]>([]);
  const [client, setClient] = useState<FicheSociete[]>([]);
  const [countryCode, setCountryCode] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [isClickSave, setIsClickSave] = useState(false);
  const [isChooseExistCustomer, setIsChooseExistCustomer] = useState(false);
  const [createdCustomer, setCreatedCustomer] = useState([]);

  const inputLabel = useRef(null);
  const inputLabel2 = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [labelWidth2, setLabelWidth2] = useState(0);
  const { id } = useParams<{ id: string }>();

  const openStatusModal: boolean = false;

  // Get referential datas
  useEffect(() => {
    // get all customers
    axios
      .get(`${urlApi}customer/for/input/list`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        setCustomers(
          (Array.isArray(res.data) && res.data).map((item) => {
            return {
              ...item,
              mappingClientId: item?.mappingClient?.id
                ? item.mappingClient.id
                : undefined,
              categorieClientId: item?.categorieClient?.id
                ? item.categorieClient.id
                : undefined,
              contacts: (item?.contacts || []).map((contact) => {
                return {
                  ...contact,
                  profilContactIds: (
                    contact?.contactHasProfilContacts || []
                  ).map((profil) => {
                    return profil?.profilContact?.id;
                  }) as number[],
                } as Contact;
              }),
            } as FicheSociete;
          })
        );
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get categorie
    axios.get(`${urlApi}categorie/client`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setCategoriesClient(res.data);
      /* if (valeur === false) {
        setLoading(false);
      } */
    }).catch((e) => {
      console.log(e.message);
    });

    // get mapping client
    axios.get(`${urlApi}mapping/client`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setMappingClients(res.data);
      /* if (valeur === false) {
        setLoading(false);
      } */
    }).catch((e) => {
      console.log(e.message);
    });

    // get profil contact
    axios.get(`${urlApi}profil/contact`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setContactprofiles(res.data);
      /* if (valeur === false) {
        setLoading(false);
      } */
      setLoading(false);
    }).catch((e) => {
      console.log(e.message);
    });

    setLabelWidth(inputLabel.current.offsetWidth);
    setLabelWidth2(inputLabel2.current.offsetWidth);
  }, []);

  const ContactStatusValue = (valueViaBack: number) => {
    switch (valueViaBack) {
      case 0:
        return false;
      case 1:
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    // get customer by id
    if (valeur === true && typeof cust === 'undefined' && cust !== null) {
      axios
        .get(`${urlApi}customer/${localStorage.getItem('id_customer')}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setCustomer(
            (Array.isArray(res.data) && res.data).map((item) => {
              return {
                ...item,
                mappingClientId: item?.mappingClient?.id
                  ? item.mappingClient.id
                  : undefined,
                categorieClientId: item?.categorieClient?.id
                  ? item.categorieClient.id
                  : undefined,
                contacts: (item?.contacts || []).map((contact) => {
                  return {
                    ...contact,
                    profilContactIds: (
                      contact?.contactHasProfilContacts || []
                    ).map((profil) => {
                      return profil?.profilContact?.id;
                    }) as number[],
                    status: ContactStatusValue(contact?.status),
                  } as Contact;
                }),
              } as FicheSociete;
            })
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }

    // get client by id via edit
    if (typeof id !== 'undefined' && id !== null) {
      axios
        .get(`${urlApi}customer/${id}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setClient(
            (Array.isArray(res.data) && res.data).map((item) => {
              return {
                ...item,
                mappingClientId: item?.mappingClient?.id
                  ? item.mappingClient.id
                  : undefined,
                categorieClientId: item?.categorieClient?.id
                  ? item.categorieClient.id
                  : undefined,
                contacts: (item?.contacts || []).map((contact) => {
                  return {
                    ...contact,
                    profilContactIds: (
                      contact?.contactHasProfilContacts || []
                    ).map((profil) => {
                      return profil?.profilContact?.id;
                    }) as number[],
                    status: ContactStatusValue(contact?.status),
                  } as Contact;
                }),
              } as FicheSociete;
            })
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else if (cust && typeof cust === 'number') {
      axios
        .get(`${urlApi}customer/${cust}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setCustomer(
            (Array.isArray(res.data) && res.data).map((item) => {
              return {
                ...item,
                mappingClientId: item?.mappingClient?.id
                  ? item.mappingClient.id
                  : undefined,
                categorieClientId: item?.categorieClient?.id
                  ? item.categorieClient.id
                  : undefined,
                contacts: (item?.contacts || []).map((contact) => {
                  return {
                    ...contact,
                    profilContactIds: (
                      contact?.contactHasProfilContacts || []
                    ).map((profil) => {
                      return profil?.profilContact?.id;
                    }) as number[],
                    status: ContactStatusValue(contact?.status),
                  } as Contact;
                }),
              } as FicheSociete;
            })
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [logger]);

  // Methode pour afficher les datas revenir par step
  const idCustomer = +customer?.map((y) => y?.id);
  const showData = useCallback(() => {
    setFicheSocieteToSave(customer?.find((item) => item.id === idCustomer));
  }, [customer]);

  useEffect(() => {
    if (valeur) {
      showData();
    } else if (cust) {
      showData();
    }
  }, [showData]);

  // Methode pour mettre à jour le donnée client
  const idClient = +client?.map((y) => y?.id);
  const showDataEditClient = useCallback(() => {
    setFicheSocieteToSave(client?.find((item) => item.id === idClient));
  }, [client]);

  useEffect(() => {
    if (id !== undefined) {
      showDataEditClient();
    }
  }, [showDataEditClient]);

  const showMessageSuccess = (param: string) => {
    toast.success(param, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const showMessageWarning = (param) => {
    toast.warning(`Merci d’ajouter au moins un contact qui a comme ${param}.`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const handleChangeSelectRaisonSociale = (
    _event: any,
    value: FicheSociete | null
  ) => {
    setIsChooseExistCustomer(true);
    if (value) {
      if (value?.isAdressFactDiff === 1) {
        setShowAddressFact(true);
      } else {
        setShowAddressFact(false);
      }

      axios
        .get(`${urlApi}get/statutlead/for/customer/${value?.id}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          if (res.data !== 0) {
            if (
              res.data === 1 ||
              res.data === 2 ||
              res.data === 3 ||
              res.data === 7
            ) {
              setCreatedCustomer([value?.id, res.data]);
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
      setFicheSocieteToSave(value);
      setFormError({});
    }
  };

  const handleChangeRaisonSocialeTextField = useCallback(
    (event: any) => {
      if (event) {
        if (ficheSocieteToSave?.id) {
          setFicheSocieteToSave({ raisonSocial: event.target?.value });
          setShowAddressFact(false);
        } else {
          setFicheSocieteToSave({
            ...ficheSocieteToSave,
            id: undefined,
            raisonSocial: event.target?.value,
          });
        }
      }
      setFormError({});
    },
    [ficheSocieteToSave]
  );

  const handleChangeForm = useCallback(
    (event: any) => {
      if (
        event.target.name === 'isAdressFactDiff' &&
        parseInt(event.target.value) === 1
      ) {
        setShowAddressFact(true);
      } else if (
        event.target.name === 'isAdressFactDiff' &&
        (parseInt(event.target.value) === 0 || event.target.value === '')
      ) {
        setShowAddressFact(false);
      }
      setFicheSocieteToSave({
        ...ficheSocieteToSave,
        [event.target.name]: event.target.value,
      });
    },
    [ficheSocieteToSave]
  );

  const handleChangePays = useCallback(
    (_e: any, option: any) => {
      setFicheSocieteToSave({
        ...ficheSocieteToSave,
        pays: option?.name || '',
      });
      setCountryCode((option?.code as string)?.toLowerCase());
    },
    [ficheSocieteToSave]
  );

  const isExistCustomer = (raisonSocial) => {
    const societe = raisonSocial?.toLowerCase()?.replace(/ /gi, '');

    const result = customers.filter(client => client?.raisonSocial?.toLowerCase()?.replace(/ /g, '') === societe);

    if (result?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // Logique copie facture et profil contatct signataire
  const tabValueCopieFact = [];
  const tabValueProfil = [];
  for (const k in ficheSocieteToSave?.contacts) {
    tabValueCopieFact.push(ficheSocieteToSave?.contacts[k]?.isCopieFacture);
    for (const m in ficheSocieteToSave?.contacts[k]?.profilContactIds) {
      tabValueProfil.push(ficheSocieteToSave?.contacts[k]?.profilContactIds[m]);
    }
  }

  const data = { ...ficheSocieteToSave, userId: (logger as any)?.id };

  const handleSave = useCallback(() => {
    setIsClickSave(true);

    if (
      tabValueCopieFact.includes(copieFacture) &&
      tabValueProfil.includes(idProfilSignataire)
    ) {
      if (
        ficheSocieteToSave &&
        ficheSocieteToSave.marqueCommercial &&
        ficheSocieteToSave.raisonSocial &&
        ficheSocieteToSave.categorieClientId &&
        // ficheSocieteToSave.mappingClientId &&
        ficheSocieteToSave.contacts?.length > 0
      ) {
        if (id !== undefined) {
          // Cas modification client ou client existant
          return axios
            .put(`${urlApi}update-customer/${id}`, data, {
              headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
              },
            })
            .then((res) => {
              setIsClickSave(false);
              history.push('/contact_list');
            })
            .catch((e) => {
              console.log(e.message);
              setIsClickSave(false);
              showMessageToastError();
            });
        } else {
          /**
           * Cas nouvelle d'un client
           * On teste d'abord si le client existe déjà
           */
          if (isExistCustomer(ficheSocieteToSave?.raisonSocial as string) && !isChooseExistCustomer) {
            toast.warning('Désolé ! Ce Client existe déjà.', {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
            setIsClickSave(false);
          } else {
            setIsClickSave(true);
            parentCallback(createdCustomer as any);
            return onSave({
              ...ficheSocieteToSave,
              userId: (logger as any)?.id,
            });
          }
        }
      }
    } else {
      if (
        !tabValueCopieFact.includes(copieFacture) &&
        !tabValueProfil.includes(idProfilSignataire)
      ) {
        setIsClickSave(false);
        showMessageWarning('DESTINATAIRE FACTURE et PROFIL SIGNATAIRE');
      } else if (!tabValueCopieFact.includes(copieFacture)) {
        setIsClickSave(false);
        showMessageWarning('DESTINATAIRE FACTURE');
      } else if (!tabValueProfil.includes(idProfilSignataire)) {
        setIsClickSave(false);
        showMessageWarning('PROFIL SIGNATAIRE');
      } else {
        return null;
      }
    }

    if (!ficheSocieteToSave?.raisonSocial) {
      setError('raisonSociale');
    }

    if (!ficheSocieteToSave?.marqueCommercial) {
      setError('marqueSociale');
    }

    if (!ficheSocieteToSave?.categorieClientId) {
      setError('categorieClientId');
    }

    if (!ficheSocieteToSave?.mappingClientId) {
      setError('mappingClientId');
    }

    if (!ficheSocieteToSave?.contacts?.length) {
      setError('contacts');
    } else {
      setFormError((prev) => ({ ...prev, contacts: false }));
    }
  }, [ficheSocieteToSave, onSave, logger, createdCustomer]);

  const setError = (key) => {
    setFormError((prev) => ({ ...prev, [key]: true }));
  };

  const handleClickAddContact = () => {
    setOpen(true);
    setContactToEdit(undefined);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleSaveContact = useCallback(
    (data: Contact) => {
      if (data.id) {
        const tempContacts = ficheSocieteToSave?.contacts?.map((item) => {
          if (item.id === data.id) {
            return data;
          }
          return item;
        });
        setFicheSocieteToSave((prev) => ({ ...prev, contacts: tempContacts }));
      } else {
        const tempData = { ...data, id: generateId() };
        let contacts = ficheSocieteToSave?.contacts;
        if (!contacts) contacts = [];
        contacts?.push(tempData);
        setFicheSocieteToSave((prev) => ({ ...prev, contacts }));
      }
      setOpen(false);
    },
    [ficheSocieteToSave]
  );

  const handleEditClick = useCallback(
    (id: any) => {
      setContactToEdit(
        ficheSocieteToSave?.contacts?.find((item) => item.id === id)
      );
      setOpen(true);
    },
    [ficheSocieteToSave]
  );

  const handleDeleteClick = (id: number) => {
    setIdForContactToRemove(id);
    setOpenDeleteDialog(true);
  };

  const deleteContact = useCallback(
    (id: number) => {
      setIsDeleteContact(true);

      // Suppression du societe dans crm actuel
      axios.get(`${urlApi}remove-contact/${id}`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      }).then((res) => {
        console.log(res.data);
        setIdForContactToRemove(null);
        setIsDeleteContact(false);
        setOpenDeleteDialog(false);

        setFicheSocieteToSave({
          ...ficheSocieteToSave,
          contacts: ficheSocieteToSave?.contacts?.filter(
            (item) => item.id !== id
          ),
        });

        showMessageSuccess('Contact supprimé !');
      }).catch((e) => {
        console.log(e.message);
        setIsDeleteContact(false);
        showMessageToastError();
      });
    },
    [ficheSocieteToSave]
  );

  const handleChangeFormAddrFact = useCallback(
    (e: any) => {
      setFicheSocieteToSave({
        ...ficheSocieteToSave,
        adresseFacturation: {
          ...ficheSocieteToSave?.adresseFacturation,
          [e.target.name]: e.target.value,
        },
      });
    },
    [ficheSocieteToSave]
  );

  const handleChangePaysAddr = useCallback(
    (_e: any, pays: any) => {
      setFicheSocieteToSave({
        ...ficheSocieteToSave,
        adresseFacturation: {
          ...ficheSocieteToSave?.adresseFacturation,
          pays: pays?.name,
        },
      });
    },
    [ficheSocieteToSave]
  );

  const handleTelChange = (val: string) => {
    setFicheSocieteToSave((prev) => ({ ...prev, tel: val }));
  };

  const handleChangeStatutContact = useCallback((contact: Contact) => {
    const tempContacts = ficheSocieteToSave?.contacts?.map(item => {
      if ((item?.nom === contact?.nom) && (item?.prenom === contact?.prenom) && (item?.email === contact?.email)) {
        return contact;
      }
      return item;
    });

    setFicheSocieteToSave((prev) => ({
      ...prev,
      contacts: tempContacts
    }));
  }, [ficheSocieteToSave]);

  const progress = (
    <div className={classes.loading}>
      <LinearProgress />
      <LinearProgress color="secondary" />
    </div>
  );

  const loader = loading && progress;
  const title = id !== undefined ? 'Mise à jour fiche client' : 'Fiche Société';

  return (
    <Box className={id === undefined ? classes.root : classes.root2}>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12} sm={12} lg={12}>
          {loader} <br />
          <Typography
            variant="h3"
            style={{
              color: '#e75113',
              textShadow: '#828181 0.05em 0.05em 0.05em',
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12} sm={12} lg={6}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              id="raisonSocialAutoComplete"
              options={customers}
              autoHighlight
              onChange={handleChangeSelectRaisonSociale}
              getOptionLabel={(option) =>
                option.id ? (option.raisonSocial as string) : ''
              }
              value={ficheSocieteToSave?.id ? ficheSocieteToSave : null}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => <span>{option.raisonSocial}</span>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Société"
                  variant="outlined"
                  error={formError.raisonSociale}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            {!valeur && (
              <FormHelperText>
                Si société existante, un choix mettra automatiquement à jour
                tous les champs et contacts
              </FormHelperText>
            )}
          </FormControl>
          {mode === 'ADD' && (
            <FormControl className={classes.formControl}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                error={formError.raisonSociale}
                className={classes.TextField}
                value={
                  ficheSocieteToSave?.id ? '' : ficheSocieteToSave?.raisonSocial
                }
                onChange={handleChangeRaisonSocialeTextField}
                label="Si nouvelle société"
                autoComplete="new-password"
                variant="outlined"
              />
              <FormHelperText classes={{ root: classes.warning }}>
                <Warning />
                Attention la raison sociale renseignée doit correspondre à celle
                qui sera facturée
              </FormHelperText>
            </FormControl>
          )}
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.TextField}
              value={ficheSocieteToSave?.marqueCommercial || ''}
              name="marqueCommercial"
              onChange={handleChangeForm}
              label="Marque commerciale"
              autoComplete="off"
              variant="outlined"
              required
              error={formError.marqueSociale}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              shrink
              ref={inputLabel2}
              htmlFor="outlined-always-notched-1"
            >
              Catégorie client <b style={{ color: 'red', fontSize: 15 }}>*</b>
            </InputLabel>
            <Select
              value={ficheSocieteToSave?.categorieClientId}
              onChange={handleChangeForm}
              input={
                <OutlinedInput
                  notched
                  labelWidth={labelWidth2}
                  name="categorieClientId"
                  id="outlined-always-notched-1"
                />
              }
              native
            >
              <option value=""></option>
              {categoriesClient.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.libelle}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              shrink
              ref={inputLabel2}
              htmlFor="outlined-always-notched-2"
            >
              Mapping client
            </InputLabel>
            <Select
              value={ficheSocieteToSave?.mappingClientId || ''}
              onChange={handleChangeForm}
              input={
                <OutlinedInput
                  notched
                  labelWidth={labelWidth2}
                  name="mappingClientId"
                  id="outlined-always-notched-2"
                />
              }
              native
            >
              <option value=""></option>
              {mappingClients.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.libelle}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.TextField}
              value={ficheSocieteToSave?.adresse || ''}
              name="adresse"
              onChange={handleChangeForm}
              label="Adresse"
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12} sm={12} lg={6}>
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.TextField}
              value={ficheSocieteToSave?.cp || ''}
              name="cp"
              onChange={handleChangeForm}
              label="Code Postal"
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              className={classes.TextField}
              value={ficheSocieteToSave?.ville || ''}
              name="ville"
              onChange={handleChangeForm}
              label="Ville"
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Autocomplete
              id="country-select-demo"
              options={paysFr}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) =>
                Array.isArray(option) ? '' : option.name
              }
              renderOption={(option) => {
                if (!Array.isArray(option)) {
                  return <>{option?.name}</>;
                }
                return <></>;
              }}
              onChange={handleChangePays}
              value={paysFr.find(
                (item) => item?.name === ficheSocieteToSave?.pays
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pays"
                  variant="outlined"
                  defaultValue={ficheSocieteToSave?.pays || ''}
                  multiline
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </FormControl>
          <PhoneInput
            country={countryCode}
            placeholder="Tél"
            inputStyle={{ width: '100%', height: 54 }}
            onChange={handleTelChange}
            value={ficheSocieteToSave?.tel || ''}
          />
          <FormControl
            style={{ marginTop: 16 }}
            className={classes.formControl}
          >
            <TextField
              className={classes.TextField}
              value={ficheSocieteToSave?.siteWeb || ''}
              name="siteWeb"
              onChange={handleChangeForm}
              label="site Web"
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              shrink
              ref={inputLabel}
              htmlFor="outlined-always-notched"
            >
              Adresse de facturation différente ?
            </InputLabel>
            <Select
              value={ficheSocieteToSave?.isAdressFactDiff}
              onChange={handleChangeForm}
              input={
                <OutlinedInput
                  notched
                  labelWidth={labelWidth}
                  name="isAdressFactDiff"
                  id="outlined-age-always-notched"
                />
              }
              native
            >
              <option value=""></option>
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </Select>
          </FormControl>
        </Grid>
        {showAddressFact && (
          <>
            <Grid item md={12} xs={12} lg={12} sm={12}>
              <Typography variant="h6">Adresse de facturation</Typography>
            </Grid>
            <Grid item md={6} xs={12} lg={6} sm={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.TextField}
                  value={ficheSocieteToSave?.adresseFacturation?.adresse || ''}
                  name="adresse"
                  onChange={handleChangeFormAddrFact}
                  label="Adresse"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.TextField}
                  value={ficheSocieteToSave?.adresseFacturation?.cp || ''}
                  name="cp"
                  onChange={handleChangeFormAddrFact}
                  label="Code Postal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.TextField}
                  value={ficheSocieteToSave?.adresseFacturation?.ville || ''}
                  name="ville"
                  onChange={handleChangeFormAddrFact}
                  label="Ville"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  id="country-select-demo"
                  options={paysFr}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => <>{option.name}</>}
                  value={
                    paysFr.find(
                      (item) =>
                        item.name ===
                        ficheSocieteToSave?.adresseFacturation?.pays
                    ) || []
                  }
                  onChange={handleChangePaysAddr}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pays"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid container spacing={3}>
          <Grid item md={4} xs={4} lg={4} sm={4}>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Sage compte tiers"
                variant="outlined"
                value={ficheSocieteToSave?.sageCompteTiers || ''}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={4} lg={4} sm={4}>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Sage compte collectif"
                variant="outlined"
                value={ficheSocieteToSave?.sageCompteCollectif || ''}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={4} lg={4} sm={4}>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Sage catégorie comptable"
                variant="outlined"
                value={ficheSocieteToSave?.sageCategorieComptable || ''}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} lg={12} sm={12}>
          <Box>
            <Typography
              variant="h4"
              style={{
                textAlign: 'center',
                color: '#e75113',
                textShadow: '#828181 0.05em 0.05em 0.05em',
              }}
            >
              Ses Contacts
            </Typography>{' '}
            <br />
            <Button
              startIcon={<Add />}
              color="primary"
              variant="outlined"
              onClick={handleClickAddContact}
            >
              Ajouter un contact
            </Button>
          </Box>
        </Grid>
        <Grid item md={12} xs={12} lg={12} sm={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align={'center'}>Prénom et Nom</TableCell>
                  <TableCell align={'center'}>Profil contact</TableCell>
                  <TableCell align={'center'}>Fonction</TableCell>
                  <TableCell align={'center'}>Tél</TableCell>
                  <TableCell align={'center'}>Email</TableCell>
                  <TableCell align={'center'}>Skype</TableCell>
                  <TableCell align={'center'}>Copie Facture</TableCell>
                  <TableCell align={'center'}>Principal</TableCell>
                  <TableCell align={'center'}>Status</TableCell>
                  <TableCell align={'center'}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(ficheSocieteToSave?.contacts || [])?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align={'center'}>
                      {item.prenom} {item.nom}
                    </TableCell>
                    <TableCell align={'center'}>
                      {(item.profilContactIds || [])
                        .map((idContactprofile) => {
                          return contactprofiles.find(
                            (item) => item.id === idContactprofile
                          )?.libelle;
                        })
                        .join('-')}
                    </TableCell>
                    <TableCell align={'center'}>{item.fonction}</TableCell>
                    <TableCell align={'center'}>+{item.tel}</TableCell>
                    <TableCell align={'center'}>{item.email}</TableCell>
                    <TableCell align={'center'}>{item.skype}</TableCell>
                    <TableCell align={'center'}>
                      {item.isCopieFacture === 1 ? (
                        <Typography style={{ color: '#1efa5c' }}>
                          <Check />
                        </Typography>
                      ) : (
                        <Clear color="error" />
                      )}
                    </TableCell>
                    <TableCell align={'center'}>
                      {item.isFacturationPrincipal === 1 ? (
                        <Typography style={{ color: '#1efa5c' }}>
                          <Check />
                        </Typography>
                      ) : (
                        <Clear color="error" />
                      )}
                    </TableCell>
                    <TableCell align={'center'}>
                      <ButtonSwitch
                        contact={item}
                        openStatusModal={openStatusModal}
                        handleChangeStatutContact={handleChangeStatutContact}
                      />
                    </TableCell>
                    <TableCell align={'center'}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography
                          title="Modifier ce contact"
                          className={classes.actionBtn}
                          onClick={() => handleEditClick(item.id)}
                        >
                          <Edit />
                        </Typography>
                        <Typography
                          title="Suprimer ce contact"
                          className={classes.actionBtn}
                          onClick={() => handleDeleteClick(item.id)}
                        >
                          <Delete />
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {formError?.contacts && (
          <Grid item md={12} xs={12} lg={12} sm={12}>
            <Alert severity="error">Merci d’ajouter au moins un contact.</Alert>
          </Grid>
        )}
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSave}
        >
          {!isClickSave ? (
            'Enregistrer'
          ) : (
            <CircularProgress color="inherit" size={18} />
          )}
        </Button>
      </Box>{' '}
      <br />
      <CustomModal title="Ajouter un contact" onClose={handleClose} open={open}>
        <ContactForm
          onSave={handleSaveContact}
          contactProfiles={contactprofiles}
          value={contactToEdit}
        />
      </CustomModal>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Suppression d'un contact"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous-êtes sur le point de supprimer ce contact.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Etes-vous sûr de poursuivre cette action ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Non</Button>
          <Button onClick={() => deleteContact(idForContactToRemove)} autoFocus>
            {isDeleteContact ? <CircularProgress color="warning" size={16} /> : 'Oui'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FicheSocieteForm;
