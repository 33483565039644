import { Box } from '@material-ui/core';
import { useEffect, useState, FC } from 'react';
import { useStyles } from './styles copy';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

export interface CustomModalProps {
  title?: string;
  width?: string
  open: boolean;
  onClose: () => void;
  showCloseBtn?: boolean;
}

const InableDisabledModal: FC<CustomModalProps> = ({
  open,
  onClose,
  children,
  width
}) => {
  const classes = useStyles()();
  const [openLocal, setOpenLocal] = useState<boolean>(false);

  useEffect(() => {
    setOpenLocal(open);
  }, [open]);

  return (
      <Modal open={openLocal} onClose={onClose}>
        <Box className={classes.bodyContainer}>{children}</Box>
      </Modal>
  );
};

export default InableDisabledModal;
