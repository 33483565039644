import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
import HausseIndiceSyntecRow from './HausseIndiceSyntecRow';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Reducers } from '../../redux/reducers';
import { BdcOperationPerClient } from '../../Interface/BdcOperationPerClient';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import InputLabel from '@mui/material/InputLabel';
import { Box } from '@mui/material';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#848484',
    color: theme.palette.common.white,
    fontSize: 11,
    borderStyle: 'none',
    paddingBottom: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));
/* function createData (
    Client: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
  ) {
    return { Client, calories, fat, carbs, protein };
  }
  /* const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ]; */
const HausseIndiceSyntec = () => {
  const urlApi = process.env.REACT_APP_BACKEND_URL;
  const logger = useSelector<Reducers>((state) => state.logger);
  const [rowBdcO, setRowBdcO] = useState<BdcOperationPerClient[]>([]);
  const today = new Date();
  const [dateApplicatif, setDateApplicatif] = useState(`${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`);
  const [isLoading, setIsLoading] = useState(false);
  const handleChangeDateApplicatif = event => {
    setDateApplicatif(event.target.value);
};
  useEffect(() => {
    setIsLoading(true);
    axios.get(`${urlApi}customers/get/cust/bdco`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      }).then((res) => {
        setRowBdcO(res.data);
        setIsLoading(false);
      });
}, []);
// console.log(rowBdcO);
  return (
    <div>
      <Container >
        <table>
          <tr>
            <td>
              <InputLabel htmlFor="input-with-icon-adornment">
                Date Applicative
              </InputLabel>
                  <input
                              name="dateCreate"
                              type="date"
                              defaultValue=""
                              color="warning"
                              style={{ borderRadius: '4px', border: '1px solid #AAAAAA', height: '31px', color: '#757575', fontSize: '14px' }}
                              value={dateApplicatif}
                              onChange={handleChangeDateApplicatif}
                          />
            </td>
            <th style={{ width: '40px' }}>
              <Stack direction="row">
                  <Chip label="Mise A Jour hausse Indice Syntec Valider" sx={{
                        '&:last-child td, &:last-child th': { border: 3 },
                    backgroundColor: '#4AAB4A'
                }} />
                </Stack>
              </th>
              <th>
                <Stack direction="row">
                  <Chip label="Mise A Jour hausse Indice Syntec Fait" sx={{
                        '&:last-child td, &:last-child th': { border: 3 },
                    backgroundColor: '#e75113'
                }} />
              </Stack>
            </th>
          </tr>
        </table>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                  <TableCell>Client</TableCell>
                  <TableCell>Date de Contrat</TableCell>
                  <TableCell>Clause de Revision</TableCell>
                  <TableCell>Is Syntec</TableCell>
                  <TableCell>Is Smic</TableCell>
                  <TableCell>Is IHCT-TS</TableCell>
                  <TableCell>Initial</TableCell>
                  <TableCell>Actuel</TableCell>
                  <TableCell>Taux evolution</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading ? (
                  <TableRow >
                    <StyledTableCell align="center" colSpan={9}><Box my={2}><LinearProgress color="warning" /></Box></StyledTableCell>
                  </TableRow>
                ) : (
                rowBdcO.map((row, index) => (
                  <HausseIndiceSyntecRow key={index} row={row} dateApp={dateApplicatif} />
                ))
              )
           }
            </TableBody>
        </Table>
        </TableContainer>
        </Container>
    </div>
  );
};

export default HausseIndiceSyntec;
