// eslint-disable-next-line no-unused-vars
import { Box, Container, InputAdornment, TextField } from '@material-ui/core';
import { FC } from 'react';
// eslint-disable-next-line no-unused-vars
import { Search } from '@material-ui/icons';
import { useStyles } from './styles';
import MenuItem, { InfoMenuItem } from '../menu-item';
import { menuItems } from './menus';
import { useSelector } from 'react-redux';
import { Reducers } from '../../redux/reducers';

// eslint-disable-next-line no-unused-vars
const searchUrl = process.env.REACT_APP_SEARCH_URL;
const Menu: FC = () => {
  const classes = useStyles();
  const userRole = useSelector<Reducers>(
    (store) => store?.logger?.role
  );
  const handleClick = (item: InfoMenuItem) => {
    window.location.assign(item.url);
  };

  return (
    <Container>
      <Box className={classes.root}>
        {menuItems.map((item, index) => (
            userRole === 'ROLE_JURISTE' ? index === 7 ? <MenuItem key={index} {...item} onClick={handleClick} /> : <></>
           : index !== 7 ? <MenuItem key={index} {...item} onClick={handleClick} />
           : <></>
        ))}
      </Box>
      {/* <Box className={classes.textFieldContainer}>
        <Box className={classes.seachBarContainer}>
          <form action={searchUrl} method="POST">
            <TextField
              size="small"
              id="outlined-basic"
              label="Recherche ..."
              variant="outlined"
              name="n_devis_rapide"
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Box>
      </Box> */}
    </Container>
  );
};

export default Menu;
