// import React from 'react';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import axios from 'axios';
import { Fragment, useState, useEffect } from 'react';
import { Reducers } from '../../../redux/reducers';
import TableNumeroBdc from '../ficheClient/TableNumeroBdc';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useStyles } from './styles';
import { List, ListItem, ListItemText } from '@mui/material';
import TableCummulAnnuel from '../ficheClient/TableCummulAnnuel';
import TableEvolution from '../ficheClient/TableEvolution';

const urlApi = process.env.REACT_APP_BACKEND_URL;

export interface TabObjectifQualitatif {
  id: number;
  libelle: string;
}

export interface TabOjectifQuantitatif {
  id: number;
  libelle: string;
}
export interface TabBdcOperation {
  id: number;
  quantite: string;
  prixUnit: string;
  tarifHoraireCible: string;
  tempsProductifs: string;
  dmt: string;
  operation: {
    id: number;
    libelle: string;
  };
  coutHoraire: {
    id: number;
    coutHoraire: string;
  };
  objectifQualitatif: TabObjectifQualitatif[];
  objectifQuantitatif: TabOjectifQuantitatif[];
  bu: {
    id: number;
    libelle: string;
  };
  typeFacturation: {
    id: number;
    libelle: string;
  };
}

export interface TabBdc {
  id: number;
  dateModification: Date;
  dateSignature: Date;
  delaisPaiment: string;
  modeReglement: string;
  bdcOperations: TabBdcOperation[];
  paysProduction: {
    id: number;
    libelle: string;
  };
  devise: {
    id: number;
    libelle: string;
  };
}

export interface TabContact {
  id?: number;
  civilite: string;
  nom: string;
  prenom: string;
  fonction: string;
  tel: string;
  email: string;
  skype: string;
}

export interface TabLeadDetailOperation {
  id: number;
  typeFacturation: {
    id: number;
    libelle: string;
  };
  langueTrt: {
    id: number;
    libelle: string;
  };
  paysProduction: {
    id: number;
    libelle: string;
  };
  operation: {
    id: number;
    libelle: string;
  };
  bu: {
    id: number;
    libelle: string;
  };
}

export interface Data {
  id: number;
  pays: string;
  caAnnuelNplusUn: string;
}

export interface DataBudgetAnnuel {
  sommeCaAnnuelNplusUn: number;
  data: Data[];
}

export interface DataBudgetMmoinsUn {
  caOctobre: string;
  pays: string;
}

export interface FormDataFicheClient {
  id?: number;
  rc: string;
  naturePrestation: number;
  activiteContexte: string;
  gestionnaireDeCompte: string;
  rapportActivite: string;
  statutContrat: string;
  taciteReconduction: string;
  versementAcompte: string;
  typeProfil: string;
  niveau: string;
  langueTrt: string;
  formation: string;
  dateDemarragePartenariat: string;
  dateContrat: Date;
  dimensionnement: string;
  dureeAnciennete: string;
  budgetDeMiseEnPlace: string;
  budgetFormation: string;
  budgetAnnuel: string;
  budgetMoyenMensuel: string;
  budgetM1: string; // Budget M-1....
  moyenneQualite: string;
  moyenneSatcli: string;
  confSpecifique: string;
  specificiteContractuelles: string;
  customer?: number; // champ obligatoire.....
  forfaitPilotage: string;
  chiffreAffaireRealise: string;
  caM1: string; // chiffre d'affaire du M-1.....
  raisonSocial: string;
  marqueCommercial: string;
  adresse: string;
  tel: string;
  outils: string;
  dureeContrat: string;
  mappingOutsourcia: string;
  modalitePaiement: string;
  detailPrixVente: string;
  registreTraitement: string;
  annexeContrat: string;
  commercial: string;
  mappingClient: {
    id: number;
    libelle: string;
  };
  contacts: TabContact[];
  user: {
    id: number;
    email: string;
    username: string;
  };
  resumeLeads: [
    {
      id: number;
      potentielCA: string;
      dureeTrt: {
        id: number;
        libelle: string;
      };
      leadDetailOperations: TabLeadDetailOperation[];
      bdcs: TabBdc[];
    }
  ];
}

const ContentPage = ({ idClient }) => {
  const logger = useSelector<Reducers>((state) => state.logger);
  const location = useLocation();
  const classes = useStyles();
  const [ficheClient, setFicheClient] = useState<FormDataFicheClient>();
  const [customer, setCustomer] = useState<FormDataFicheClient>();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [usernameConnecte, setUsernameConnecte] = useState();

  const loaderStyle = (
    <div className={classes.loading}>
      <LinearProgress />
      {/*  <LinearProgress color="secondary" /> */}
    </div>
  );

  useEffect(() => {
    if (idClient !== undefined) {
      axios
        .get(`${urlApi}fiche/client/${idClient}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          if (res.data[0] === null) {
            setCustomer(res.data[1]);
            setFicheClient({
              gestionnaireDeCompte: res.data[1]?.user?.email
                ? String(res.data[1]?.user?.email)
                : null,
            } as FormDataFicheClient);
            setLoading(false);
          } else {
            setFicheClient({
              ...res.data[0],
              naturePrestation: res.data[0]?.naturePrestation?.id
                ? res.data[0]?.naturePrestation?.id
                : null,
              /* dateDemarragePartenariat: res.data[0]?.dateDemarragePartenariat
                ? dateFormat(
                    res.data[0]?.dateDemarragePartenariat,
                    'yyyy-mm-dd'
                  )
                : null, */
            } as FormDataFicheClient);
            setCustomer(res.data[1]);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else if (location.state !== undefined) {
      axios
      .get(`${urlApi}fiche/client/${location.state}`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        if (res.data[0] === null) {
          setCustomer(res.data[1]);
          setFicheClient({
            gestionnaireDeCompte: res.data[1]?.user?.email
              ? String(res.data[1]?.user?.email)
              : null,
          } as FormDataFicheClient);
          setLoading(false);
        } else {
          setFicheClient({
            ...res.data[0],
            naturePrestation: res.data[0]?.naturePrestation?.id
              ? res.data[0]?.naturePrestation?.id
              : null,
            /* dateDemarragePartenariat: res.data[0]?.dateDemarragePartenariat
              ? dateFormat(
                  res.data[0]?.dateDemarragePartenariat,
                  'yyyy-mm-dd'
                )
              : null, */
          } as FormDataFicheClient);
          setCustomer(res.data[1]);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
    }
  }, [logger]);

  useEffect(() => {
    axios
      .get(`${urlApi}get/username`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        setUsernameConnecte(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, [usernameConnecte]);

  return (
    <Box className={classes.bodyStyle}>
      {loading ? (
        loaderStyle
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box my={2} ml={0} style={{ color: '#e75113' }}>
                <Typography
                  variant="h5"
                  style={{
                    textShadow: '#828181 0.05em 0.05em 0.05em',
                    textAlign: 'center',
                    fontSize: '30px',
                  }}
                >
                  Fiche client : {customer?.raisonSocial} <br />
                </Typography>{' '}
                <br />
                <Typography variant="h5">
                  <div
                    style={{
                      color: '#77777B',
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                      fontSize: '17px',
                    }}
                  >
                    Recap Bon(s) de Commande :
                  </div>
                  <TableNumeroBdc numBdc={customer} />
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        COORDONNEES CLIENT DO
                      </h5>
                      <h6 className={classes.simpleTitle}>Société :</h6>
                      <div>Raison sociale : {customer?.raisonSocial || ''}</div>
                      <div>
                        Nom commercial :{' '}
                        {customer?.marqueCommercial
                          ? customer?.marqueCommercial
                          : customer?.raisonSocial}
                      </div>
                      <div>Activité : {''}</div>
                      <div>
                        Numéro de téléphone (Standard) : {customer?.tel || ''}
                      </div>
                      <div>Adresse : {customer?.adresse || ''}</div> <br />
                      <Fragment>
                        <Grid item xs={12} md={12}>
                          <Typography
                            style={{
                              color: '#77777B',
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              fontSize: '17px',
                            }}
                            component="div"
                          >
                            Contacts :
                          </Typography>
                          <List>
                            {customer?.contacts?.map((row) => (
                              <ListItem key={row.id}>
                                <ListItemText>
                                  {' '}
                                  - {row.prenom} {row.nom} {row.fonction}{' '}
                                  {row.email} {row.tel}
                                </ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      </Fragment>
                    </Fragment>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        CONTACTS OUTSOURCIA
                      </h5>
                      <div>Commercial : {ficheClient?.commercial || ''}</div>
                      <div>
                        Gestionnaire de compte :{' '}
                        {ficheClient?.gestionnaireDeCompte || ''}
                      </div>
                    </Fragment>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        ELEMENTS CONTRACTUELS
                      </h5>
                      <h6 className={classes.simpleTitle}>Contrats :</h6>
                      <div>Contrat : {ficheClient?.statutContrat || ''}</div>
                      <div>
                        Annexe contrat RGPD : {ficheClient?.annexeContrat || ''}
                      </div>
                      <div>
                        Registre de traitement RGPD :{' '}
                        {ficheClient?.registreTraitement || ''}
                      </div>
                      <div>
                        Durée du contrat :{' '}
                        {customer?.resumeLeads?.map(
                          (x) => x?.dureeTrt?.libelle
                        ) || null}
                      </div>
                      <div>
                        Tacite reconduction :{' '}
                        {String(
                          customer?.resumeLeads?.map(
                            (x) => x?.dureeTrt?.libelle
                          )
                        ) === 'récurrente'
                          ? 'OUI'
                          : 'NON' || ''}
                      </div>
                      <div>
                        Modalités de paiement :{' '}
                        {String(
                          customer?.resumeLeads?.map((a) =>
                            a?.bdcs?.map((b) => b?.modeReglement)
                          )
                        )}
                      </div>
                      <div>
                        Versement d&#39;un acompte :{' '}
                        {ficheClient?.versementAcompte || ''}
                      </div>{' '}
                      <h6 className={classes.simpleTitle}>
                        KPI&#39;s Contractuels :
                      </h6>
                      <Fragment>
                        <Grid item xs={12} md={12}>
                          <div>
                            {customer?.resumeLeads?.map((a) =>
                              a?.bdcs?.map((b) =>
                                b?.bdcOperations?.map((row) => (
                                  <div key={row.id}>
                                    <strong>
                                      {b?.paysProduction?.libelle} /{' '}
                                      {row?.bu?.libelle} /{' '}
                                      {row?.operation?.libelle}
                                    </strong>{' '}
                                    {row?.objectifQuantitatif?.map((x) => (
                                      <div key={x.id}>
                                        <h6>Objectifs quantitatif :</h6>
                                        <p style={{ marginLeft: '15px' }}>
                                          - {x.libelle} : 70%
                                        </p>
                                        <p style={{ marginLeft: '15px' }}>
                                          - DMT : {row.dmt}
                                        </p>
                                      </div>
                                    ))}
                                    {row?.objectifQualitatif?.map((x) => (
                                      <div key={x.id}>
                                        <h6>Objectifs qualitatif :</h6>
                                        <p style={{ marginLeft: '15px' }}>
                                          - {x.libelle} : 55%
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                ))
                              )
                            )}
                          </div>
                        </Grid>
                      </Fragment>
                    </Fragment>
                    <Fragment>
                        <h5 className={classes.titleBackGround}>
                          DIMENSIONNEMENT ET OFFRE FINANCIERE
                        </h5>
                        <Grid item xs={12} md={12}>
                          <Typography
                            style={{
                              color: '#77777B',
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              fontSize: '17px',
                            }}
                            component="div"
                          >
                            Offre financière :
                          </Typography>
                          <List>
                            {customer?.resumeLeads?.map((a) =>
                              a?.bdcs?.map((b) =>
                                b?.bdcOperations?.map((row) => (
                                  <ListItem key={row.id}>
                                    <ListItemText>
                                      - {b?.paysProduction?.libelle} /{' '}
                                      {row?.bu?.libelle} /{' '}
                                      {row?.operation?.libelle} /{' '}
                                      {row?.typeFacturation?.libelle} /{' '}
                                      {row?.coutHoraire?.coutHoraire}{' '}
                                      {b?.devise?.libelle} /
                                    </ListItemText>
                                  </ListItem>
                                ))
                              )
                            )}
                          </List>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Typography
                            style={{
                              color: '#77777B',
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              fontSize: '17px',
                            }}
                            component="div"
                          >
                            Détails prix de vente :
                          </Typography>
                          <List>
                            {customer?.resumeLeads?.map((a) =>
                              a?.bdcs?.map((b) =>
                                b?.bdcOperations?.map((row) => (
                                  <ListItem key={row.id}>
                                    <ListItemText>
                                      - {b?.paysProduction?.libelle} /{' '}
                                      {row?.bu?.libelle} /{' '}
                                      {row?.operation?.libelle} /{' '}
                                      Tarif horaire clible : {row?.tarifHoraireCible}{' '}
                                      {b?.devise?.libelle} /{' '}
                                      {row?.tempsProductifs} / DMT : {row?.dmt} /
                                    </ListItemText>
                                  </ListItem>
                                ))
                              )
                            )}
                          </List>
                        </Grid>
                      </Fragment>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        RECRUTEMENT ET FORMATION
                      </h5>
                      <h6 className={classes.simpleTitle}>Profil:</h6>
                      <div>Profil : {ficheClient?.typeProfil}</div>
                      <br />
                      <Grid item xs={12} md={12}>
                        <Typography
                          style={{
                            color: '#77777B',
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            fontSize: '17px',
                          }}
                          component="div"
                        >
                          Formation :
                        </Typography>
                        <div>
                            {customer?.resumeLeads?.map((a) =>
                              a?.bdcs?.map((b) =>
                                b?.bdcOperations?.map((row) => (
                                  <div key={row.id}>
                                    {row?.operation?.libelle ===
                                      'FORMATION' && (
                                      <h6 style={{ marginLeft: '15px' }}>
                                        - {b?.paysProduction?.libelle} /{' '}
                                        {row?.bu?.libelle} /{' '}
                                        {row?.operation?.libelle} / ? jours
                                      </h6>
                                    )}
                                  </div>
                                ))
                              )
                            )}
                          </div>
                      </Grid>
                    </Fragment>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        RAPPORTS D’ACTIVITE
                      </h5>
                      <Grid item xs={12} md={12}>
                        <List>
                          {customer?.resumeLeads?.map((a) =>
                            a?.bdcs?.map((b) =>
                              b?.bdcOperations?.map((row) => (
                                <ListItem key={row.id}>
                                  <ListItemText>
                                    - {b?.paysProduction?.libelle} /{' '}
                                    {row?.bu?.libelle}
                                  </ListItemText>
                                </ListItem>
                              ))
                            )
                          )}
                        </List>
                      </Grid>
                    </Fragment>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        CONFIGURATION IT
                      </h5>
                      <h6 className={classes.simpleTitle}>Outils :</h6>
                      <div>Outils : {ficheClient?.outils}</div>
                      <h6 className={classes.simpleTitle}>
                        Configurations spécifiques :
                      </h6>
                      <div>
                        Configurations spécifiques :{' '}
                        {ficheClient?.confSpecifique}
                      </div>
                    </Fragment>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        CUMUL ANNUEL (année en cours)
                      </h5>
                      <TableCummulAnnuel data={customer} />
                    </Fragment>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        Depuis le début du partenariat
                      </h5>
                      <TableEvolution data={customer} /> <br />
                    </Fragment>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ContentPage;
