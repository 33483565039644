import { useState, useCallback, useEffect, FC, Fragment } from 'react';
import {
  Box,
  Button,
  Collapse,
  Grid,
  Typography,
  Chip,
  MenuItem,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Add } from '@material-ui/icons';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../redux/reducers';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';
import BdcForm1 from './BdcForm1';
import CustomModal from '../../commons/custom-modal';
import LigneFacturationForm, { RefData } from '../ligne-facturation-form/LigneFacturationForm';
import LigneFacturation from '../../../Interface/LigneFacturation';
import Bdc from '../../../Interface/Bdc';
import { useHistory, useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import PaysFacturation from '../../../Interface/PaysFacturation';
import Operation from '../../../Interface/Operation';
import RowLigneFact from './RowLigneFact';

toast.configure();

export const generateId = () => Math.floor(Math.random() * 100) + Date.now();
const urlApi = process.env.REACT_APP_BACKEND_URL;
const urlViewDoc = process.env.REACT_APP_VIEW_DOC_URL;

const tabOpAuto = [1, 13, 12, 16, 17, 774, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 15, 14];

const tabStatutLeadDraftAndReject = [3, 7];

export interface ProfilContact {
  id: number;
  libelle: string;
}

export interface ContactHasProfilContact {
  id: number;
  profilContact: ProfilContact;
}

export interface Ref {
  id: number;
  libelle: string;
}

export interface RefCoutHoraire {
  id: number;
  dateDebut: Date;
  dateFin: Date;
  pays: string;
  niveau: string;
  langueSpecialite: string;
  coutHoraire: number;
  coutFormation: number;
  bu: string;
}

export interface Contact {
  id: number;
  nom: string;
  prenom: string;
  fonction: string;
  isCopieFacture: number;
  contactHasProfilContacts: ContactHasProfilContact[];
}
export interface Client {
  id?: number;
  contacts: Contact[];
}

export interface RetourResumeLead {
  nombreBdc: number;
  uniqId: string[];
  oneBdc: string;
}

export interface BdcFormProps {
  bdc?: Bdc;
  mode: 'EDIT' | 'ADD';
  onSave: (data: Bdc) => void;
  dataFicheQualification: RetourResumeLead;
  nextPage;
  isSave;
  prevStep: boolean;
  customer: number;
  idBdc: number;
  callbackTwo: number[];
}

const BdcForm: FC<BdcFormProps> = ({
  onSave,
  dataFicheQualification,
  nextPage,
  prevStep,
  customer,
  idBdc,
  isSave,
  callbackTwo,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const logger = useSelector<Reducers>((store) => store.logger);
  const [bonDeCommande, setBonDeCommande] = useState<Bdc>();
  const [dataBdc, setDataBdc] = useState<Bdc[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isClickSave, setIsClickSave] = useState(false);
  const [dataPaysFacturation, setDataPaysFacturation] = useState<PaysFacturation[]>([]);
  const [dataSocieteFacturation, setDataSocieteFacturation] = useState<Ref[]>([]);
  const [dataDevise, setDataDevise] = useState<Ref[]>([]);
  const [ligneFacturationSave, setligneFacturationSave] = useState<
    LigneFacturation | undefined
  >();
  const [dataHoraireProduction, setDataHoraireProduction] = useState<Ref[]>(
    []
  );
  const [idBdcs, setIdBdc] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  // Variable d'etat pour tout le ref
  const [operation, setOperation] = useState<Operation[]>([]);
  const [familleOperation, setFamilleOperation] = useState<RefData[]>([]);
  const [bu, setBu] = useState<RefData[]>([]);
  const [typeFacturation, setTypeFacturation] = useState<RefData[]>([]);
  const [dataLangueTraitement, setDataLangueTraitement] = useState<RefData[]>(
    []
  );
  const [dataPaysProduction, setDataPaysProduction] = useState<RefData[]>([]);
  const [paysProd, setPaysProd] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [a, b, c, d] = dataFicheQualification?.uniqId
    ? dataFicheQualification?.uniqId
    : [];
  const [openSelectMultiple, setOpenSelectMultiple] = useState<boolean>(false);
  const [openSelectMultiple2, setOpenSelectMultiple2] =
    useState<boolean>(false);
  const [customerContacts, setCustomerContacts] = useState<Client[]>([]);
  const [objectifQuantitatif, setObjectQuantitatif] = useState<Ref[]>([]);
  const [objectifQual, setObjectifQual] = useState<Ref[]>([]);
  const [coutHoraire, setCoutHoraire] = useState<RefCoutHoraire[]>([]);
  const [errorInput, setErrorInput] = useState({
    paysFact: false,
    societeFacturation: false,
    tva: false,
    devise: false,
    modeReglement: false,
    delaisPaiment: false,
    destinataireSignataire: false,
    destinataireFacture: false,
    descriptionGlobale: false
  });

  /**
   * Logique destinataire facture
   * Si le contact is copie facture est égal oui, alors on a la valeur dans le champ destinataire facture
   */
  const tabDestinataireFacture = [];
  for (const i in customerContacts) {
    for (const j in customerContacts[i]?.contacts) {
      if (Number(customerContacts[i]?.contacts[j]?.isCopieFacture) === 1) {
        tabDestinataireFacture.push(customerContacts[i]?.contacts[j]);
      }
    }
  }

  /**
   * Logique destinataire signataire
   * Afficher uniquement la liste dans le select destinataire signataire si le contact ayant
   * de profil signatire
   */
  const tabDestinataireSignataire = [];
  for (const i in customerContacts) {
    for (const j in customerContacts[i]?.contacts) {
      for (const k in customerContacts[i]?.contacts[j]
        ?.contactHasProfilContacts) {
        if (
          Number(
            customerContacts[i]?.contacts[j]?.contactHasProfilContacts[k]
              ?.profilContact?.id
          ) === 4
        ) {
          tabDestinataireSignataire.push(customerContacts[i]?.contacts[j]);
        }
      }
    }
  }

  // Supprimer les doublons dans le tableau destinataire signataire
  const uniqTabDestinataireSignataire = [
    ...(new Set(tabDestinataireSignataire) as any),
  ];

  const tabIdTva = [];
  for (const i in dataBdc) {
    for (const j in dataBdc[i]?.paysFacturation?.tvas) {
      tabIdTva.push(dataBdc[i]?.paysFacturation?.tvas[j]?.id);
    }
  }

  const checkAttribut = (attr) => {
    if (attr === undefined || attr === null || String(attr).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const affichAutoPaysFact = () => {
    if (dataPaysFacturation?.length === 1) {
      return Number(dataPaysFacturation[0]?.id);
    } else {
      return bonDeCommande?.paysFact;
    }
  };

  const affichAutoSocieteFact = () => {
    if (dataSocieteFacturation?.length === 1) {
      return Number(dataSocieteFacturation[0]?.id);
    } else {
      return bonDeCommande?.societeFacturation;
    }
  };

  const affichAutoDevise = () => {
    if (dataDevise?.length === 1) {
      return Number(dataDevise[0]?.id);
    } else {
      return bonDeCommande?.devise;
    }
  };

  const affichAutoTva = () => {
    if (tabIdTva?.length === 1) {
      return Number(tabIdTva[0]);
    } else {
      return bonDeCommande?.tva;
    }
  };

  const { idpar } = useParams<{ idpar: string }>();

  /**
   * Logique affichage liste opération du bon de commande (ligne de facturation)
   * avec quelque opérations ajoutés automatiques ne sont pas afficher
   */
  const tabOperation: LigneFacturation[] = [];

  const statutLeadDeletableOfLignaFact = [null, -1, 3, 5, 7, 9, 10, 14, 16, 18, 19];

  const tabIdOperationNonAffiche = [12, 1004];
  const idOperationDeletableOfLignaFact = [15];
  const idFamilleOperationNotEditableOnLignFactList = [9, 10];

  for (const k in bonDeCommande?.bdcOperations) {
    if (
      !tabIdOperationNonAffiche.includes(
        bonDeCommande?.bdcOperations[k]?.operation
      )
    ) {
      tabOperation.push(bonDeCommande?.bdcOperations[k]);
    }
  }

  // console.log(bonDeCommande?.bdcOperations);

  /**
   * get id bu pour les opérations ajouté manuels and
   * id langue de traitement pour les opérations ajouté manuel
   */
  const idBuManuel = [];
  const idLangTrtManuel = [];
  for (const k in bonDeCommande?.bdcOperations) {
    if (!tabOpAuto.includes(bonDeCommande?.bdcOperations[k]?.operation)) {
      idBuManuel.push(bonDeCommande?.bdcOperations[k]?.bu);
      idLangTrtManuel.push(bonDeCommande?.bdcOperations[k]?.langueTrt);
    }
  }

  const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
      position: 'top-right',
      autoClose: 60000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };
  const showMessageWarningValidation = (param) => {
    toast.warning(`Merci de remplir le champ ${param} SVP ?`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const id = +dataBdc?.map((item) => item?.id);
  const showData = useCallback(() => {
    setBonDeCommande(dataBdc?.find((indice) => indice?.id === id));
  }, [dataBdc]);

  useEffect(() => {
    if (prevStep) {
      showData();
    } else if (callbackTwo?.length !== 0) {
      showData();
    } else {
      showData();
    }
  }, [showData]);

  const handleChange = (event) => {
    setBonDeCommande({
      ...bonDeCommande,
      [event.target.name]: event.target.value,
    });
  };

  const handleCloseSelectMulti = () => {
    setOpenSelectMultiple((prev) => !prev);
  };

  const handleChageSelectMulti = (e) => {
    e.stopPropagation();
    handleCloseSelectMulti();
    setBonDeCommande((prev) => ({
      ...prev,
      destinataireSignataire: e.target.value,
    }));
  };

  const handleCloseSelectMulti2 = () => {
    setOpenSelectMultiple2((prev) => !prev);
  };

  const handleChageSelectMulti2 = (e) => {
    e.stopPropagation();
    handleCloseSelectMulti2();
    setBonDeCommande((prev) => ({
      ...prev,
      destinataireFacture: e.target.value,
    }));
  };

  const onCloseAlert = () => {
    setOpen(false);
  };

  const handleAddLigneFacturation = () => {
    setOpenModal(true);
    setligneFacturationSave(undefined);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleUpdateTarifOfLignFact = useCallback((ligneFact) => {
    const tempLigneFact = bonDeCommande?.bdcOperations?.map(item => {
      if (item?.id === ligneFact?.id) {
        return ligneFact;
      }

      return item;
    });

    setBonDeCommande({
      ...bonDeCommande,
      bdcOperations: tempLigneFact,
    });
  }, [bonDeCommande]);

  const handleDeleteClick = useCallback(
    (id) => {
      if (id.toString().length === 13) {
        setBonDeCommande({
          ...bonDeCommande,
          bdcOperations: bonDeCommande?.bdcOperations?.filter(
            (item) => item.id !== id
          ),
        });
      } else {
        if (window.confirm('Etes-vous sûr de vouloir supprimer ?')) {
          axios
            .delete(`${urlApi}delete/operation/${id}`, {
              headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
              },
            })
            .then((res) => {
              setBonDeCommande({
                ...bonDeCommande,
                bdcOperations: bonDeCommande?.bdcOperations?.filter(
                  (item) => item.id !== id
                ),
              });
              toast.success(
                "Suppression d'une ligne de facturation avec succès",
                {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                }
              );
            })
            .catch((e) => {
              showMessageToastError();
            });
        }
      }
    },
    [bonDeCommande]
  );

  const handleSaveBdc = useCallback(() => {
    setIsClickSave(true);

    // Validation form avant l'ajout data bdc
    if (!affichAutoPaysFact()) {
      setErrorInput((prev) => ({ ...prev, paysFact: true }));
      showMessageWarningValidation('Pays de facturation');
      setIsClickSave(false);
    }
    if (!affichAutoSocieteFact()) {
      setErrorInput((prev) => ({ ...prev, societeFacturation: true }));
      showMessageWarningValidation('Société de facturation');
      setIsClickSave(false);
    }
    if (!affichAutoTva()) {
      setErrorInput((prev) => ({ ...prev, tva: true }));
      showMessageWarningValidation('TVA de facturation');
      setIsClickSave(false);
    }
    if (!affichAutoDevise()) {
      setErrorInput((prev) => ({ ...prev, devise: true }));
      showMessageWarningValidation('Devise de facturation');
      setIsClickSave(false);
    }
    if (!bonDeCommande?.modeReglement?.length) {
      setErrorInput((prev) => ({ ...prev, modeReglement: true }));
      showMessageWarningValidation('Mode de règlement');
      setIsClickSave(false);
    }
    if (!bonDeCommande?.delaisPaiment?.length) {
      setErrorInput((prev) => ({ ...prev, delaisPaiment: true }));
      showMessageWarningValidation('Delais de paiement');
      setIsClickSave(false);
    }
    if (!bonDeCommande?.destinataireSignataire?.length) {
      setErrorInput((prev) => ({ ...prev, destinataireSignataire: true }));
      showMessageWarningValidation('Destinataire signataire');
      setIsClickSave(false);
    }
    if (!bonDeCommande?.destinataireFacture?.length) {
      setErrorInput((prev) => ({ ...prev, destinataireFacture: true }));
      showMessageWarningValidation('Destinataire facture');
      setIsClickSave(false);
    }

    if (!bonDeCommande?.descriptionGlobale) {
      setErrorInput((prev) => ({ ...prev, descriptionGlobale: true }));
      showMessageWarningValidation('Description globale');
      setIsClickSave(false);
    }

    if (
      bonDeCommande &&
      affichAutoPaysFact() &&
      affichAutoSocieteFact() &&
      affichAutoDevise() &&
      affichAutoTva() &&
      bonDeCommande?.modeReglement?.length &&
      bonDeCommande?.delaisPaiment?.length &&
      bonDeCommande?.destinataireFacture?.length &&
      bonDeCommande?.destinataireSignataire?.length &&
      bonDeCommande?.descriptionGlobale?.length
    ) {
      // On vérifie d'abord si la marge cible est inférieur au seuil
      axios.get(`${urlApi}check/marge/cible/${bonDeCommande?.id}`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        }
      }).then((res) => {
        if (res.data === 'Merci de paramétrés SVP tous les lignes de facturations !') {
          /**
           * Dans le cas s'il y a une ligne de facturation non paramétré
           */
          setIsClickSave(false);
          toast.warning(res.data, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        } else {
          /**
           * Si res.data === 1, alors la marge cible est supérieur au seuil et on passe à l'enregistrement.
           * Sinon, on alerte l'utilisateur pour qu'il modifier les prix horaires
           */
          if (res.data === 1) {
            // Mis à jour bon de commande
            if (idpar !== undefined) {
              // Enregistrement mise à jour cas bdc signé......
              return axios
                .put(
                  `${urlApi}bon/de/commande/${bonDeCommande?.id}`,
                  {
                    ...bonDeCommande,
                    paysFact: affichAutoPaysFact(),
                    societeFacturation: affichAutoSocieteFact(),
                    devise: affichAutoDevise(),
                    tva: affichAutoTva(),
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${(logger as any)?.token}`,
                    },
                  }
                ).then((res) => {
                  if (res.data.error) {
                    toast.warning(res.data.message, {
                      position: 'top-right',
                      autoClose: 60000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                    });
                  } else {
                    if (Number(bonDeCommande?.statutLead) === 11) { // Parametrage des nouvelles bdcs
                      if (res.status === 202) {
                        const [nombreBdc, uniqId] = res.data;

                        history.push({
                          pathname: '/creation_vente',
                          state: ['BDC', nombreBdc, uniqId],
                        });
                      } else {
                        history.push('/bdc_list');
                      }
                    } else {
                      toast.success('Mise à jour a été effectué avec succès .', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                      });
                      if (tabStatutLeadDraftAndReject.includes(bonDeCommande?.statutLead)) {
                        window.open(
                          `${urlViewDoc}/bdc/bdc_${bonDeCommande?.idMere}.pdf`,
                          '_blank'
                        );
                      }

                      if (checkAttribut(res.data[1])) {
                        if (Number(bonDeCommande?.statutLead) === 19) {
                          window.open(
                            `${urlViewDoc}/bdc/bdc_avenant_${bonDeCommande?.idMere}.pdf`,
                            '_blank'
                          );
                        } else {
                          for (let index = 0; index < res.data[1].length; index++) {
                            window.open(
                              `${urlViewDoc}/bdc/bdc_${res.data[1][index]}.pdf`,
                              '_blank'
                            );
                          }
                        }
                      }
                      history.push('/bdc_list');
                    }
                  }
                  setIsClickSave(false);
                }).catch((e) => {
                  console.log(e);
                  showMessageToastError();
                  setIsClickSave(false);
                });
            } else {
              // Enregistrement bdc cas création vente...
              if (
                (dataFicheQualification?.nombreBdc === 1 && !idBdcs[1]) ||
                idBdc !== 0
              ) {
                return onSave({
                  ...bonDeCommande,
                  paysFact: affichAutoPaysFact(),
                  societeFacturation: affichAutoSocieteFact(),
                  devise: affichAutoDevise(),
                  tva: affichAutoTva(),
                });
              } else {
                return axios
                  .put(
                    `${urlApi}bon/de/commande/${bonDeCommande?.id}`,
                    {
                      ...bonDeCommande,
                      paysFact: affichAutoPaysFact(),
                      societeFacturation: affichAutoSocieteFact(),
                      devise: affichAutoDevise(),
                      tva: affichAutoTva(),
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${(logger as any)?.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    setIsClickSave(false);
                    if (res.data.error) {
                      toast.warning(res.data.message, {
                        position: 'top-right',
                        autoClose: 60000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                      });
                    } else {
                      setIsClickSave(false);
                      const idMere = res?.data?.idMere;
                      setBonDeCommande({
                        ...bonDeCommande,
                        paysFact: affichAutoPaysFact(),
                        societeFacturation: affichAutoSocieteFact(),
                        devise: affichAutoDevise(),
                        tva: affichAutoTva(),
                        activeForm: 'ok',
                      });
                      window.open(
                        `${urlViewDoc}/bdc/bdc_${idMere}.pdf`,
                        '_blank'
                      );
                    }
                  })
                  .catch((e) => {
                    alert(e.message);
                    showMessageToastError();
                    setIsClickSave(false);
                  });
              }
            }
          } else {
            setIsClickSave(false);
            toast.warning(`Merci de modifier les prix horaires car la marge cible est inférieur à ${res.data}% !`, {
              position: 'top-right',
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
        }
      }).catch(error => {
        console.log(error.message);
      });
    }
  }, [bonDeCommande, onSave]);

  const addNewLigneFacturation = (data: LigneFacturation) => {
    const tempData = {
      ...data,
      id: generateId(),
      newOperation: 'ok',
      uniq: generateId(),
    };
    let ligneFacturations = bonDeCommande?.bdcOperations;
    if (!ligneFacturations) ligneFacturations = [];
    ligneFacturations?.push(tempData);
    setBonDeCommande((prev) => ({ ...prev, ligneFacturations }));
  };

  const handleSaveLigneFacturation = useCallback(
    (data: LigneFacturation) => {
      if (idpar !== undefined) {
        if (data.id) {
          const tempBdcOperation = bonDeCommande?.bdcOperations?.map((item) => {
            if (item.id === data.id) {
              return data;
            }
            return item;
          });
          setBonDeCommande((prev) => ({
            ...prev,
            bdcOperations: tempBdcOperation,
          }));
        } else {
          addNewLigneFacturation(data);
        }
      } else {
        addNewLigneFacturation(data);
      }
      setOpenModal(false);
    },
    [bonDeCommande]
  );

  useEffect(() => {
    // get ref cout horaire......................
    axios
      .get(`${urlApi}cout/horaire`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setCoutHoraire(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get pays de facturation
    axios.get(`${urlApi}bon/de/commande/ref/PaysFacturation`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataPaysFacturation(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get horaire production
    axios.get(`${urlApi}ref/fiche/qualification/HoraireProduction`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataHoraireProduction(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get ref operation...........................
    axios
      .get(`${urlApi}bon/de/commande/ref/Operation`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setOperation(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get ref famille operation..........................
    axios
      .get(`${urlApi}bon/de/commande/ref/FamilleOperation`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setFamilleOperation(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get ref business unit................
    axios
      .get(`${urlApi}bon/de/commande/ref/Bu`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setBu(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get ref type facturation..........
    axios
      .get(`${urlApi}bon/de/commande/ref/TypeFacturation`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setTypeFacturation(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get langue de traitement
    axios
      .get(`${urlApi}ref/fiche/qualification/LangueTrt`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setDataLangueTraitement(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get pays prod
    axios.get(`${urlApi}bon/de/commande/ref/PaysProduction`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      Array.isArray(res.data) && setDataPaysProduction(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get ref objectif qualitatif
    axios
      .get(`${urlApi}bon/de/commande/ref/ObjectifQualitatif`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setObjectifQual(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get ref objectif quantitatif
    axios
      .get(`${urlApi}bon/de/commande/ref/ObjectifQuantitatif`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setObjectQuantitatif(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  useEffect(() => {
    if (idpar) {
      axios
        .get(`${urlApi}bonDeCommande/via/id/${parseInt(idpar)}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setDataBdc(
            (Array.isArray(res.data) && res.data).map((item) => {
              // Debut logique adresse de facturation................
              const adresseCustomer = item.resumeLead.customer.adresse;
              const isAdresseFactDiff =
                item.resumeLead.customer.isAdressFactDiff;
              const adrFacturation =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.adresse
                  : null;
              const cp =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.cp
                  : null;
              const ville =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.ville
                  : null;
              const pays =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.pays
                  : null;

              const adrCpVillePays =
                'adresse: ' +
                adrFacturation +
                ' - ' +
                'cp: ' +
                cp +
                ' - ' +
                'ville: ' +
                ville +
                ' - ' +
                'pays: ' +
                pays;

              const adresse =
                isAdresseFactDiff[0] > 0 ? adrCpVillePays : adresseCustomer;
              // Fin logique adresse de facturation.......................................

              return {
                ...item,
                raisonSocial: item?.resumeLead?.customer?.raisonSocial
                  ? item?.resumeLead?.customer?.raisonSocial
                  : undefined,
                marqueCommercial: item?.resumeLead?.customer?.marqueCommercial
                  ? item?.resumeLead?.customer?.marqueCommercial
                  : undefined,
                statutClient: item?.resumeLead?.customer?.categorieClient
                  ?.libelle
                  ? item?.resumeLead?.customer?.categorieClient?.libelle
                  : undefined,
                adresseFacturation: adresse,
                resumeLead: item?.resumeLead?.id
                  ? item?.resumeLead?.id
                  : undefined,
                paysProduction: item?.paysProduction?.libelle
                  ? item?.paysProduction?.libelle
                  : undefined,
                paysProduct: item?.paysProduction?.id
                  ? item?.paysProduction?.id
                  : undefined,
                paysFact: item?.paysFacturation?.id,
                societeFacturation: item?.societeFacturation?.id
                  ? item?.societeFacturation?.id
                  : undefined,
                devise: item?.devise?.id ? item?.devise?.id : undefined,
                tva: item?.tva?.id ? item?.tva?.id : undefined,
                customerId: item?.resumeLead?.customer?.id
                  ? Number(item?.resumeLead?.customer?.id)
                  : undefined,
                bdcOperations: (item?.bdcOperations || []).map((index) => {
                  return {
                    ...index,
                    operation: index?.operation?.id
                      ? index?.operation?.id
                      : undefined,
                    designationActe: index?.designationActe?.id
                      ? index?.designationActe?.id
                      : undefined,
                    langueTrt: index?.langueTrt?.id
                      ? index?.langueTrt?.id
                      : undefined,
                    typeFacturation: index?.typeFacturation?.id
                      ? index?.typeFacturation?.id
                      : undefined,
                    familleOperation: index?.operation?.familleOperation?.id
                      ? index?.operation?.familleOperation?.id
                      : undefined,
                    bu: index?.bu?.id ? index?.bu?.id : undefined,
                    coutHoraire: index?.coutHoraire?.id
                      ? index?.coutHoraire?.id
                      : undefined,
                    objectifQualitatif: index?.objectifQualitatif?.map(obj => {
                      return obj?.id;
                    }),
                    objectifQuantitatif: index?.objectifQuantitatif?.map(obj => {
                      return obj?.id;
                    }),
                    indicateurQt: index?.indicatorQuantitatifs?.map(indicQt => {
                      return {
                        indicator: indicQt?.indicator,
                        objectifQt: String(indicQt?.objectifQuantitatif?.id),
                      };
                    }),
                    indicateurQl: index?.indicatorQualitatifs?.map(indicQl => {
                      return {
                        indicator: indicQl?.indicator,
                        objectifQl: String(indicQl?.objectifQualitatif?.id),
                      };
                    }),
                  } as LigneFacturation;
                }),
              } as Bdc;
            })
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else if (idBdc !== 0) {
      axios
        .get(`${urlApi}bonDeCommande/via/id/${idBdc}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setDataBdc(
            (Array.isArray(res.data) && res.data).map((item) => {
              // Debut logique adresse de facturation................
              const adresseCustomer = item.resumeLead.customer.adresse;
              const isAdresseFactDiff =
                item.resumeLead.customer.isAdressFactDiff;
              const adrFacturation =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.adresse
                  : null;
              const cp =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.cp
                  : null;
              const ville =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.ville
                  : null;
              const pays =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.pays
                  : null;

              const adrCpVillePays =
                'adresse: ' +
                adrFacturation +
                ' - ' +
                'cp: ' +
                cp +
                ' - ' +
                'ville: ' +
                ville +
                ' - ' +
                'pays: ' +
                pays;

              const adresse =
                isAdresseFactDiff[0] > 0 ? adrCpVillePays : adresseCustomer;
              // Fin logique adresse de facturation.......................................

              return {
                ...item,
                raisonSocial: item?.resumeLead?.customer?.raisonSocial
                  ? item?.resumeLead?.customer?.raisonSocial
                  : undefined,
                marqueCommercial: item?.resumeLead?.customer?.marqueCommercial
                  ? item?.resumeLead?.customer?.marqueCommercial
                  : undefined,
                statutClient: item?.resumeLead?.customer?.categorieClient
                  ?.libelle
                  ? item?.resumeLead?.customer?.categorieClient?.libelle
                  : undefined,
                adresseFacturation: adresse,
                resumeLead: item?.resumeLead?.id
                  ? item?.resumeLead?.id
                  : undefined,
                paysProduction: item?.paysProduction?.libelle
                  ? item?.paysProduction?.libelle
                  : undefined,
                paysProduct: item?.paysProduction?.id
                  ? item?.paysProduction?.id
                  : undefined,
                paysFact: item?.paysFacturation?.id,
                societeFacturation: item?.societeFacturation?.id
                  ? item?.societeFacturation?.id
                  : undefined,
                devise: item?.devise?.id ? item?.devise?.id : undefined,
                tva: item?.tva?.id ? item?.tva?.id : undefined,
                customerId: item?.resumeLead?.customer?.id
                  ? Number(item?.resumeLead?.customer?.id)
                  : undefined,
                bdcOperations: (item?.bdcOperations || []).map((index) => {
                  return {
                    ...index,
                    operation: index?.operation?.id
                      ? index?.operation?.id
                      : undefined,
                    designationActe: index?.designationActe?.id
                      ? index?.designationActe?.id
                      : undefined,
                    langueTrt: index?.langueTrt?.id
                      ? index?.langueTrt?.id
                      : undefined,
                    typeFacturation: index?.typeFacturation?.id
                      ? index?.typeFacturation?.id
                      : undefined,
                    familleOperation: index?.operation?.familleOperation?.id
                      ? index?.operation?.familleOperation?.id
                      : undefined,
                    bu: index?.bu?.id ? index?.bu?.id : undefined,
                    coutHoraire: index?.coutHoraire?.id
                      ? index?.coutHoraire?.id
                      : undefined,
                    objectifQualitatif: index?.objectifQualitatif?.map(obj => {
                      return obj?.id;
                    }),
                    objectifQuantitatif: index?.objectifQuantitatif?.map(obj => {
                      return obj?.id;
                    }),
                    indicateurQt: index?.indicatorQuantitatifs?.map(indicQt => {
                      return {
                        indicator: indicQt?.indicator,
                        objectifQt: String(indicQt?.objectifQuantitatif?.id),
                      };
                    }),
                    indicateurQl: index?.indicatorQualitatifs?.map(indicQl => {
                      return {
                        indicator: indicQl?.indicator,
                        objectifQl: String(indicQl?.objectifQualitatif?.id),
                      };
                    }),
                  } as LigneFacturation;
                }),
              } as Bdc;
            })
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else if (customer !== undefined && customer !== 0) {
      // Si cutomer different de zero, alors on cherche le BDC via l'id du customer
      axios
        .get(`${urlApi}get/idbdc/via/idcustomer/${customer}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setIdBdc(res.data);
          axios
            .get(`${urlApi}bonDeCommande/via/id/${parseInt(res.data)}`, {
              headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
              },
            })
            .then((res) => {
              console.log(res.data);
              setDataBdc(
                (Array.isArray(res.data) && res.data).map((item) => {
                  // Debut logique adresse de facturation................
                  const adresseCustomer = item.resumeLead.customer.adresse;
                  const isAdresseFactDiff =
                    item.resumeLead.customer.isAdressFactDiff;
                  const adrFacturation =
                    isAdresseFactDiff[0] > 0
                      ? item.resumeLead.customer.adresseFacturation.adresse
                      : null;
                  const cp =
                    isAdresseFactDiff[0] > 0
                      ? item.resumeLead.customer.adresseFacturation.cp
                      : null;
                  const ville =
                    isAdresseFactDiff[0] > 0
                      ? item.resumeLead.customer.adresseFacturation.ville
                      : null;
                  const pays =
                    isAdresseFactDiff[0] > 0
                      ? item.resumeLead.customer.adresseFacturation.pays
                      : null;

                  const adrCpVillePays =
                    'adresse: ' +
                    adrFacturation +
                    ' - ' +
                    'cp: ' +
                    cp +
                    ' - ' +
                    'ville: ' +
                    ville +
                    ' - ' +
                    'pays: ' +
                    pays;

                  const adresse =
                    isAdresseFactDiff[0] > 0 ? adrCpVillePays : adresseCustomer;
                  // Fin logique adresse de facturation.......................................

                  return {
                    ...item,
                    raisonSocial: item?.resumeLead?.customer?.raisonSocial
                      ? item?.resumeLead?.customer?.raisonSocial
                      : undefined,
                    marqueCommercial: item?.resumeLead?.customer
                      ?.marqueCommercial
                      ? item?.resumeLead?.customer?.marqueCommercial
                      : undefined,
                    statutClient: item?.resumeLead?.customer?.categorieClient
                      ?.libelle
                      ? item?.resumeLead?.customer?.categorieClient?.libelle
                      : undefined,
                    adresseFacturation: adresse,
                    resumeLead: item?.resumeLead?.id
                      ? item?.resumeLead?.id
                      : undefined,
                    paysProduction: item?.paysProduction?.libelle
                      ? item?.paysProduction?.libelle
                      : undefined,
                    paysProduct: item?.paysProduction?.id
                      ? item?.paysProduction?.id
                      : undefined,
                    paysFact: item?.paysFacturation?.id,
                    societeFacturation: item?.societeFacturation?.id
                      ? item?.societeFacturation?.id
                      : undefined,
                    devise: item?.devise?.id ? item?.devise?.id : undefined,
                    tva: item?.tva?.id ? item?.tva?.id : undefined,
                    customerId: item?.resumeLead?.customer?.id
                      ? Number(item?.resumeLead?.customer?.id)
                      : undefined,
                    bdcOperations: (item?.bdcOperations || []).map((index) => {
                      return {
                        ...index,
                        operation: index?.operation?.id
                          ? index?.operation?.id
                          : undefined,
                        designationActe: index?.designationActe?.id
                          ? index?.designationActe?.id
                          : undefined,
                        langueTrt: index?.langueTrt?.id
                          ? index?.langueTrt?.id
                          : undefined,
                        typeFacturation: index?.typeFacturation?.id
                          ? index?.typeFacturation?.id
                          : undefined,
                        familleOperation: index?.operation?.familleOperation?.id
                          ? index?.operation?.familleOperation?.id
                          : undefined,
                        bu: index?.bu?.id ? index?.bu?.id : undefined,
                        coutHoraire: index?.coutHoraire?.id
                          ? index?.coutHoraire?.id
                          : undefined,
                        objectifQualitatif: index?.objectifQualitatif?.map(obj => {
                          return obj?.id;
                        }),
                        objectifQuantitatif: index?.objectifQuantitatif?.map(obj => {
                          return obj?.id;
                        }),
                        indicateurQt: index?.indicatorQuantitatifs?.map(indicQt => {
                          return {
                            indicator: indicQt?.indicator,
                            objectifQt: String(indicQt?.objectifQuantitatif?.id),
                          };
                        }),
                        indicateurQl: index?.indicatorQualitatifs?.map(indicQl => {
                          return {
                            indicator: indicQl?.indicator,
                            objectifQl: String(indicQl?.objectifQualitatif?.id),
                          };
                        }),
                      } as LigneFacturation;
                    }),
                  } as Bdc;
                })
              );
              setLoading(false);
            })
            .catch((e) => {
              console.log(e.message);
            });
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else {
      if (dataFicheQualification?.nombreBdc === 1) {
        axios
          .get(
            `${urlApi}bdc/${dataFicheQualification?.oneBdc
              ? dataFicheQualification?.oneBdc
              : a
            }`,
            {
              headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            setDataBdc(
              (Array.isArray(res.data) && res.data).map((item) => {
                // Debut logique adresse de facturation................
                const adresseCustomer = item.resumeLead.customer.adresse;
                const isAdresseFactDiff =
                  item.resumeLead.customer.isAdressFactDiff;
                const adrFacturation =
                  isAdresseFactDiff[0] > 0
                    ? item.resumeLead.customer.adresseFacturation.adresse
                    : null;
                const cp =
                  isAdresseFactDiff[0] > 0
                    ? item.resumeLead.customer.adresseFacturation.cp
                    : null;
                const ville =
                  isAdresseFactDiff[0] > 0
                    ? item.resumeLead.customer.adresseFacturation.ville
                    : null;
                const pays =
                  isAdresseFactDiff[0] > 0
                    ? item.resumeLead.customer.adresseFacturation.pays
                    : null;

                const adrCpVillePays =
                  'adresse: ' +
                  adrFacturation +
                  ' - ' +
                  'cp: ' +
                  cp +
                  ' - ' +
                  'ville: ' +
                  ville +
                  ' - ' +
                  'pays: ' +
                  pays;

                const adresse =
                  isAdresseFactDiff[0] > 0 ? adrCpVillePays : adresseCustomer;
                // Fin logique adresse de facturation.......................................

                return {
                  ...item,
                  raisonSocial: item?.resumeLead?.customer?.raisonSocial
                    ? item?.resumeLead?.customer?.raisonSocial
                    : undefined,
                  marqueCommercial: item?.resumeLead?.customer?.marqueCommercial
                    ? item?.resumeLead?.customer?.marqueCommercial
                    : undefined,
                  statutClient: item?.resumeLead?.customer?.categorieClient
                    ?.libelle
                    ? item?.resumeLead?.customer?.categorieClient?.libelle
                    : undefined,
                  adresseFacturation: adresse,
                  resumeLead: item?.resumeLead?.id
                    ? item?.resumeLead?.id
                    : undefined,
                  paysProduction: item?.paysProduction?.libelle
                    ? item?.paysProduction?.libelle
                    : undefined,
                  paysProduct: item?.paysProduction?.id
                    ? item?.paysProduction?.id
                    : undefined,
                  paysFact: item?.paysFacturation?.id,
                  societeFacturation: item?.societeFacturation?.id
                    ? item?.societeFacturation?.id
                    : undefined,
                  devise: item?.devise?.id ? item?.devise?.id : undefined,
                  tva: item?.tva?.id ? item?.tva?.id : undefined,
                  customerId: item?.resumeLead?.customer?.id
                    ? Number(item?.resumeLead?.customer?.id)
                    : undefined,
                  bdcOperations: (item?.bdcOperations || []).map((index) => {
                    return {
                      ...index,
                      operation: index?.operation?.id
                        ? index?.operation?.id
                        : undefined,
                      designationActe: index?.designationActe?.id
                        ? index?.designationActe?.id
                        : undefined,
                      langueTrt: index?.langueTrt?.id
                        ? index?.langueTrt?.id
                        : undefined,
                      typeFacturation: index?.typeFacturation?.id
                        ? index?.typeFacturation?.id
                        : undefined,
                      familleOperation: index?.operation?.familleOperation?.id
                        ? index?.operation?.familleOperation?.id
                        : undefined,
                      bu: index?.bu?.id ? index?.bu?.id : undefined,
                      coutHoraire: index?.coutHoraire?.id
                        ? index?.coutHoraire?.id
                        : undefined,
                      objectifQualitatif: index?.objectifQualitatif?.map(obj => {
                        return obj?.id;
                      }),
                      objectifQuantitatif: index?.objectifQuantitatif?.map(obj => {
                        return obj?.id;
                      }),
                      indicateurQt: index?.indicatorQuantitatifs?.map(indicQt => {
                        return {
                          indicator: indicQt?.indicator,
                          objectifQt: String(indicQt?.objectifQuantitatif?.id),
                        };
                      }),
                      indicateurQl: index?.indicatorQualitatifs?.map(indicQl => {
                        return {
                          indicator: indicQl?.indicator,
                          objectifQl: String(indicQl?.objectifQualitatif?.id),
                        };
                      }),
                    } as LigneFacturation;
                  }),
                } as Bdc;
              })
            );
            setLoading(false);
          })
          .catch((e) => {
            console.log(e.message);
          });
      } else {
        axios
          .get(`${urlApi}bdc/${a}`, {
            headers: {
              Authorization: `Bearer ${(logger as any)?.token}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            setDataBdc(
              (Array.isArray(res.data) && res.data).map((item) => {
                // Debut logique adresse de facturation................
                const adresseCustomer = item.resumeLead.customer.adresse;
                const isAdresseFactDiff =
                  item.resumeLead.customer.isAdressFactDiff;
                const adrFacturation =
                  isAdresseFactDiff[0] > 0
                    ? item.resumeLead.customer.adresseFacturation.adresse
                    : null;
                const cp =
                  isAdresseFactDiff[0] > 0
                    ? item.resumeLead.customer.adresseFacturation.cp
                    : null;
                const ville =
                  isAdresseFactDiff[0] > 0
                    ? item.resumeLead.customer.adresseFacturation.ville
                    : null;
                const pays =
                  isAdresseFactDiff[0] > 0
                    ? item.resumeLead.customer.adresseFacturation.pays
                    : null;

                const adrCpVillePays =
                  'adresse: ' +
                  adrFacturation +
                  ' - ' +
                  'cp: ' +
                  cp +
                  ' - ' +
                  'ville: ' +
                  ville +
                  ' - ' +
                  'pays: ' +
                  pays;

                const adresse =
                  isAdresseFactDiff[0] > 0 ? adrCpVillePays : adresseCustomer;
                // Fin logique adresse de facturation.......................................

                return {
                  ...item,
                  raisonSocial: item?.resumeLead?.customer?.raisonSocial
                    ? item?.resumeLead?.customer?.raisonSocial
                    : undefined,
                  marqueCommercial: item?.resumeLead?.customer?.marqueCommercial
                    ? item?.resumeLead?.customer?.marqueCommercial
                    : undefined,
                  statutClient: item?.resumeLead?.customer?.categorieClient
                    ?.libelle
                    ? item?.resumeLead?.customer?.categorieClient?.libelle
                    : undefined,
                  adresseFacturation: adresse,
                  resumeLead: item?.resumeLead?.id
                    ? item?.resumeLead?.id
                    : undefined,
                  paysProduction: item?.paysProduction?.libelle
                    ? item?.paysProduction?.libelle
                    : undefined,
                  paysProduct: item?.paysProduction?.id
                    ? item?.paysProduction?.id
                    : undefined,
                  paysFact: item?.paysFacturation?.id,
                  societeFacturation: item?.societeFacturation?.id
                    ? item?.societeFacturation?.id
                    : undefined,
                  devise: item?.devise?.id ? item?.devise?.id : undefined,
                  tva: item?.tva?.id ? item?.tva?.id : undefined,
                  customerId: item?.resumeLead?.customer?.id
                    ? Number(item?.resumeLead?.customer?.id)
                    : undefined,
                  bdcOperations: (item?.bdcOperations || []).map((index) => {
                    return {
                      ...index,
                      operation: index?.operation?.id
                        ? index?.operation?.id
                        : undefined,
                      designationActe: index?.designationActe?.id
                        ? index?.designationActe?.id
                        : undefined,
                      langueTrt: index?.langueTrt?.id
                        ? index?.langueTrt?.id
                        : undefined,
                      typeFacturation: index?.typeFacturation?.id
                        ? index?.typeFacturation?.id
                        : undefined,
                      familleOperation: index?.operation?.familleOperation?.id
                        ? index?.operation?.familleOperation?.id
                        : undefined,
                      bu: index?.bu?.id ? index?.bu?.id : undefined,
                      coutHoraire: index?.coutHoraire?.id
                        ? index?.coutHoraire?.id
                        : undefined,
                      objectifQualitatif: index?.objectifQualitatif?.map(obj => {
                        return obj?.id;
                      }),
                      objectifQuantitatif: index?.objectifQuantitatif?.map(obj => {
                        return obj?.id;
                      }),
                      indicateurQt: index?.indicatorQuantitatifs?.map(indicQt => {
                        return {
                          indicator: indicQt?.indicator,
                          objectifQt: String(indicQt?.objectifQuantitatif?.id),
                        };
                      }),
                      indicateurQl: index?.indicatorQualitatifs?.map(indicQl => {
                        return {
                          indicator: indicQl?.indicator,
                          objectifQl: String(indicQl?.objectifQualitatif?.id),
                        };
                      }),
                    } as LigneFacturation;
                  }),
                } as Bdc;
              })
            );
            setLoading(false);
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    }

    // get bon de commande by unique id
    if (prevStep === true) {
      axios
        .get(`${urlApi}bdc/${localStorage.getItem('uniq_id_bdc')}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setDataBdc(
            (Array.isArray(res.data) && res.data).map((item) => {
              // Debut logique adresse de facturation................
              const adresseCustomer = item.resumeLead.customer.adresse;
              const isAdresseFactDiff =
                item.resumeLead.customer.isAdressFactDiff;
              const adrFacturation =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.adresse
                  : null;
              const cp =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.cp
                  : null;
              const ville =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.ville
                  : null;
              const pays =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.pays
                  : null;

              const adrCpVillePays =
                'adresse: ' +
                adrFacturation +
                ' - ' +
                'cp: ' +
                cp +
                ' - ' +
                'ville: ' +
                ville +
                ' - ' +
                'pays: ' +
                pays;

              const adresse =
                isAdresseFactDiff[0] > 0 ? adrCpVillePays : adresseCustomer;
              // Fin logique adresse de facturation.......................................

              return {
                ...item,
                raisonSocial: item?.resumeLead?.customer?.raisonSocial
                  ? item?.resumeLead?.customer?.raisonSocial
                  : undefined,
                marqueCommercial: item?.resumeLead?.customer?.marqueCommercial
                  ? item?.resumeLead?.customer?.marqueCommercial
                  : undefined,
                statutClient: item?.resumeLead?.customer?.categorieClient
                  ?.libelle
                  ? item?.resumeLead?.customer?.categorieClient?.libelle
                  : undefined,
                adresseFacturation: adresse,
                resumeLead: item?.resumeLead?.id
                  ? item?.resumeLead?.id
                  : undefined,
                paysProduction: item?.paysProduction?.libelle
                  ? item?.paysProduction?.libelle
                  : undefined,
                paysProduct: item?.paysProduction?.id
                  ? item?.paysProduction?.id
                  : undefined,
                paysFact: item?.paysFacturation?.id,
                customerId: item?.resumeLead?.customer?.id
                  ? Number(item?.resumeLead?.customer?.id)
                  : undefined,
                bdcOperations: (item?.bdcOperations || []).map((index) => {
                  return {
                    ...index,
                    operation: index?.operation?.id
                      ? index?.operation?.id
                      : undefined,
                    designationActe: index?.designationActe?.id
                      ? index?.designationActe?.id
                      : undefined,
                    langueTrt: index?.langueTrt?.id
                      ? index?.langueTrt?.id
                      : undefined,
                    typeFacturation: index?.typeFacturation?.id
                      ? index?.typeFacturation?.id
                      : undefined,
                    familleOperation: index?.operation?.familleOperation?.id
                      ? index?.operation?.familleOperation?.id
                      : undefined,
                    bu: index?.bu?.id ? index?.bu?.id : undefined,
                    coutHoraire: index?.coutHoraire?.id
                      ? index?.coutHoraire?.id
                      : undefined,
                    objectifQualitatif: index?.objectifQualitatif?.map(obj => {
                      return obj?.id;
                    }),
                    objectifQuantitatif: index?.objectifQuantitatif?.map(obj => {
                      return obj?.id;
                    }),
                    indicateurQt: index?.indicatorQuantitatifs?.map(indicQt => {
                      return {
                        indicator: indicQt?.indicator,
                        objectifQt: String(indicQt?.objectifQuantitatif?.id),
                      };
                    }),
                    indicateurQl: index?.indicatorQualitatifs?.map(indicQl => {
                      return {
                        indicator: indicQl?.indicator,
                        objectifQl: String(indicQl?.objectifQualitatif?.id),
                      };
                    }),
                  } as LigneFacturation;
                }),
              } as Bdc;
            })
          );
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [logger]);

  useEffect(() => {
    // get customer with contacts
    if (bonDeCommande?.customerId) {
      axios
        .get(`${urlApi}customer/${bonDeCommande?.customerId}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          Array.isArray(res.data) && setCustomerContacts(res.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
    if (bonDeCommande?.paysProduction !== undefined) {
      setPaysProd(String(bonDeCommande?.paysProduction));
    }
    if (isSave) {
      setIsClickSave(false);
    }
  }, [bonDeCommande, isSave]);

  useEffect(() => {
    if (bonDeCommande?.paysFact) {
      const data = dataPaysFacturation?.find(paysFact => Number(paysFact?.id) === Number(bonDeCommande?.paysFact));
      setDataSocieteFacturation(data?.societeFacturations);
      setDataDevise(data?.devises);
    }
  }, [bonDeCommande?.paysFact]);

  /**
   * Loader style...........................
   */
  const progress = (
    <div className={classes.loading}>
      <LinearProgress />
      <LinearProgress color="secondary" />
    </div>
  );

  const delaisPaiementLabel = (
    <span style={{ color: 'red', fontSize: 20 }}>
      <span style={{ color: '#AAAAAA', fontSize: 16 }}>Délais de paiement</span>{' '}
      *
    </span>
  );

  const globalDescLabel = (
    <span style={{ color: 'red', fontSize: 20 }}>
      <span style={{ color: '#AAAAAA', fontSize: 16 }}>Description globale</span>{' '}
      *
    </span>
  );

  /**
   * Logique show message validarion forms........................
   */
  const messageValidation =
    affichAutoPaysFact() === undefined ||
      affichAutoSocieteFact() === undefined ||
      affichAutoDevise() === undefined ||
      !bonDeCommande?.modeReglement?.length ||
      !bonDeCommande?.delaisPaiment?.length ||
      affichAutoTva() === undefined ||
      !bonDeCommande?.destinataireSignataire?.length ||
      !bonDeCommande?.destinataireFacture?.length ? (
      <Alert variant="filled" severity="error">
        Attention tous les champs en <b>*</b> rouge sont obligatoire, merci de
        remplir SVP !
      </Alert>
    ) : null;

  /**
   * Autre formulaire bon de commande envoyé par props...............................
   */
  const bdc1 = (
    <BdcForm1
      dataFicheQualification={dataFicheQualification}
      nextPage={nextPage}
      bdcid={idBdcs[1] ? idBdcs : 0}
      dataPaysProduction={dataPaysProduction}
      dataHoraireProduction={dataHoraireProduction}
      dataLangueTraitement={dataLangueTraitement}
      operation={operation}
      familleOperation={familleOperation}
      typeFacturation={typeFacturation}
      bu={bu}
      langueTrt={dataLangueTraitement}
    />
  );

  /**
   * Formulaire bdc assigné dans une variable "form".......................
   */
  const title =
    idpar === undefined ? 'Bon de commande' : 'Mise à jour bon de commande';
  const form = (
    <Box className={idpar === undefined ? classes.root : classes.bodyStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography
            variant="h3"
            style={{
              color: '#e75113',
              textShadow: '#828181 0.05em 0.05em 0.05em',
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} xl={6}>
          <TextField
            name="raisonSocial"
            label="Raison sociale"
            value={bonDeCommande?.raisonSocial}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="marqueCommercial"
            label="Marque commerciale"
            value={bonDeCommande?.marqueCommercial}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="paysProduction"
            label="Pays de production"
            value={bonDeCommande?.paysProduction}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="outlined-age-native-simple"
              shrink={true}
            >
              Pays de facturation{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              native
              error={errorInput?.paysFact}
              value={affichAutoPaysFact()}
              onChange={handleChange}
              style={{
                backgroundColor: dataPaysFacturation.length === 1 && '#EAECEE',
              }}
              label="Pays de facturation"
              inputProps={{
                name: 'paysFact',
              }}
            >
              <option value=""></option>
              {dataPaysFacturation?.map((paysFact) =>
                <option key={paysFact?.id} value={paysFact?.id}>
                  {paysFact?.libelle}
                </option>
              )}
            </Select>
          </FormControl>
          {idpar !== undefined ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={(idpar || prevStep) && true}
              >
                Société de facturation{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.societeFacturation}
                value={affichAutoSocieteFact()}
                onChange={handleChange}
                style={{
                  backgroundColor: dataSocieteFacturation.length === 1 && '#EAECEE',
                }}
                label="Société de facturation"
                inputProps={{
                  name: 'societeFacturation',
                }}
              >
                <option value=""></option>
                {dataSocieteFacturation?.map((socifact) =>
                  <option key={socifact?.id} value={socifact?.id}>
                    {socifact.libelle}
                  </option>
                )}
              </Select>
            </FormControl>
          ) : prevStep ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={(idpar || prevStep) && true}
              >
                Société de facturation{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.societeFacturation}
                value={affichAutoSocieteFact()}
                onChange={handleChange}
                style={{
                  backgroundColor: dataSocieteFacturation.length === 1 && '#EAECEE',
                }}
                label="Société de facturation"
                inputProps={{
                  name: 'societeFacturation',
                }}
              >
                <option value=""></option>
                {dataSocieteFacturation?.map((socifact) =>
                  <option key={socifact?.id} value={socifact?.id}>
                    {socifact.libelle}
                  </option>
                )}
              </Select>
            </FormControl>
          ) : (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple" shrink={true}>
                Société de facturation{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.societeFacturation}
                value={affichAutoSocieteFact()}
                onChange={handleChange}
                style={{
                  backgroundColor: dataSocieteFacturation.length === 1 && '#EAECEE',
                }}
                label="Société de facturation"
                inputProps={{
                  name: 'societeFacturation',
                }}
              >
                <option value=""></option>
                {dataSocieteFacturation?.map((socifact) =>
                  <option key={socifact?.id} value={socifact?.id}>
                    {socifact.libelle}
                  </option>
                )}
              </Select>
            </FormControl>
          )}

          {idpar !== undefined ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={
                  (idpar ||
                    prevStep ||
                    bonDeCommande?.paysFacturation?.devises?.length === 1) &&
                  true
                }
              >
                Devise de facturation{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.devise}
                value={affichAutoDevise()}
                onChange={handleChange}
                style={{
                  backgroundColor: dataDevise.length === 1 && '#EAECEE',
                }}
                label="Devise de facturation"
                inputProps={{
                  name: 'devise',
                }}
              >
                <option value=""></option>
                {dataDevise?.map((devise) =>
                  <option key={devise?.id} value={devise?.id}>
                    {devise?.libelle}
                  </option>
                )}
              </Select>
            </FormControl>
          ) : prevStep ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={
                  (idpar ||
                    prevStep ||
                    bonDeCommande?.paysFacturation?.devises?.length === 1) &&
                  true
                }
              >
                Devise de facturation{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.devise}
                value={affichAutoDevise()}
                style={{
                  backgroundColor: dataDevise.length === 1 && '#EAECEE',
                }}
                onChange={handleChange}
                label="Devise de facturation"
                inputProps={{
                  name: 'devise',
                }}
              >
                <option value=""></option>
                {dataDevise?.map((devise) =>
                  <option key={devise?.id} value={devise?.id}>
                    {devise?.libelle}
                  </option>
                )}
              </Select>
            </FormControl>
          ) : (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={
                  bonDeCommande?.paysFacturation?.devises?.length === 1 && true
                }
              >
                Devise de facturation{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.devise}
                value={affichAutoDevise()}
                onChange={handleChange}
                label="Devise de facturation"
                style={{
                  backgroundColor: dataDevise.length === 1 && '#EAECEE',
                }}
                inputProps={{
                  name: 'devise',
                }}
              >
                <option value=""></option>
                {dataDevise?.map((devise) =>
                  <option key={devise?.id} value={devise?.id}>
                    {devise?.libelle}
                  </option>
                )}
              </Select>
            </FormControl>
          )}

          {idpar !== undefined ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={
                  (idpar ||
                    prevStep ||
                    dataBdc[0]?.paysFacturation?.tvas?.length === 1) &&
                  true
                }
              >
                TVA de facturation{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.tva}
                value={affichAutoTva()}
                onChange={handleChange}
                style={{ backgroundColor: tabIdTva.length === 1 && '#EAECEE' }}
                label="TVA de facturation"
                inputProps={{
                  name: 'tva',
                }}
              >
                <option value=""></option>
                {dataBdc?.map((x) =>
                  x?.paysFacturation?.tvas?.map((y) => (
                    <option key={y.id} value={y.id}>
                      {y.libelle}
                    </option>
                  ))
                )}
              </Select>
            </FormControl>
          ) : prevStep ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={
                  (idpar ||
                    prevStep ||
                    dataBdc[0]?.paysFacturation?.tvas?.length === 1) &&
                  true
                }
              >
                TVA de facturation{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.tva}
                value={affichAutoTva()}
                onChange={handleChange}
                style={{ backgroundColor: tabIdTva.length === 1 && '#EAECEE' }}
                label="TVA de facturation"
                inputProps={{
                  name: 'tva',
                }}
              >
                <option value=""></option>
                {dataBdc?.map((x) =>
                  x?.paysFacturation?.tvas?.map((y) => (
                    <option key={y.id} value={y.id}>
                      {y.libelle}
                    </option>
                  ))
                )}
              </Select>
            </FormControl>
          ) : (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={dataBdc[0]?.paysFacturation?.tvas?.length === 1 && true}
              >
                TVA de facturation{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.tva}
                value={affichAutoTva()}
                onChange={handleChange}
                style={{ backgroundColor: tabIdTva.length === 1 && '#EAECEE' }}
                label="TVA de facturation"
                inputProps={{
                  name: 'tva',
                }}
              >
                <option value=""></option>
                {dataBdc?.map((x) =>
                  x?.paysFacturation?.tvas?.map((y) => (
                    <option key={y.id} value={y.id}>
                      {y.libelle}
                    </option>
                  ))
                )}
              </Select>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12} sm={6} lg={6} xl={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="destinataire">
              Destinataire signataire{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              open={openSelectMultiple}
              onClick={() => handleCloseSelectMulti()}
              multiple
              displayEmpty
              error={errorInput?.destinataireSignataire}
              value={bonDeCommande?.destinataireSignataire || []}
              onChange={handleChageSelectMulti}
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {((selected as string[]) || []).map((value) => (
                      <Chip
                        key={value}
                        label={
                          uniqTabDestinataireSignataire?.find(
                            (item) => item.id === parseInt(value)
                          )?.prenom
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                );
              }}
              label="Destinataire signataire"
              inputProps={{
                name: 'destinataireSignataire',
              }}
            >
              <MenuItem disabled value="">
                <em>Destinataire signataire</em>
              </MenuItem>
              {uniqTabDestinataireSignataire?.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.prenom} - {row.nom} - {row.fonction}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="destinataire">
              Destinataire facture{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              open={openSelectMultiple2}
              onClick={() => handleCloseSelectMulti2()}
              multiple
              displayEmpty
              error={errorInput?.destinataireFacture}
              value={bonDeCommande?.destinataireFacture || []}
              onChange={handleChageSelectMulti2}
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {((selected as string[]) || []).map((value) => (
                      <Chip
                        key={value}
                        label={
                          tabDestinataireFacture?.find(
                            (item) => item.id === parseInt(value)
                          )?.prenom
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                );
              }}
              label="Destinataire facture"
              inputProps={{
                name: 'destinataireFacture',
              }}
            >
              <MenuItem disabled value="">
                <em>Destinataire facture</em>
              </MenuItem>
              {tabDestinataireFacture?.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.prenom} - {row.nom} - {row.fonction}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            name="statutClient"
            label="Statut client"
            value={bonDeCommande?.statutClient}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />

          {idpar !== undefined ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={(idpar || prevStep) && true}
              >
                Mode de règlement{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.modeReglement}
                value={bonDeCommande?.modeReglement}
                onChange={handleChange}
                label="Mode de règlement"
                inputProps={{
                  name: 'modeReglement',
                }}
              >
                <option aria-label="None" value="" />
                <option value="Virement">Virement</option>
                <option value="Prélèvement">Prélèvement</option>
              </Select>
            </FormControl>
          ) : prevStep ? (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                htmlFor="outlined-age-native-simple"
                shrink={(idpar || prevStep) && true}
              >
                Mode de règlement{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.modeReglement}
                value={bonDeCommande?.modeReglement}
                onChange={handleChange}
                label="Mode de règlement"
                inputProps={{
                  name: 'modeReglement',
                }}
              >
                <option aria-label="None" value="" />
                <option value="Virement">Virement</option>
                <option value="Prélèvement">Prélèvement</option>
              </Select>
            </FormControl>
          ) : (
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Mode de règlement{' '}
                <span style={{ color: 'red', fontSize: 20 }}>*</span>
              </InputLabel>
              <Select
                native
                error={errorInput?.modeReglement}
                value={bonDeCommande?.modeReglement}
                onChange={handleChange}
                label="Mode de règlement"
                inputProps={{
                  name: 'modeReglement',
                }}
              >
                <option aria-label="None" value="" />
                <option value="Virement">Virement</option>
                <option value="Prélèvement">Prélèvement</option>
              </Select>
            </FormControl>
          )}

          {idpar !== undefined ? (
            <TextField
              name="delaisPaiment"
              label={delaisPaiementLabel}
              error={errorInput?.delaisPaiment}
              value={bonDeCommande?.delaisPaiment}
              autoComplete="new-password"
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : prevStep ? (
            <TextField
              name="delaisPaiment"
              label={delaisPaiementLabel}
              error={errorInput?.delaisPaiment}
              value={bonDeCommande?.delaisPaiment}
              autoComplete="new-password"
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <TextField
              name="delaisPaiment"
              label={delaisPaiementLabel}
              error={errorInput?.delaisPaiment}
              value={bonDeCommande?.delaisPaiment}
              autoComplete="new-password"
              onChange={handleChange}
              variant="outlined"
            />
          )}

          <TextField
            label="Résumé de la prestation"
            name="resumePrestation"
            value={bonDeCommande?.resumePrestation}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            multiline
            rows={5}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="diffusions"
            label="Liste de diffusion facturation"
            value={bonDeCommande?.diffusions}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="adresseFacturation"
            label="Adresse de facturation"
            value={bonDeCommande?.adresseFacturation}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="descriptionGlobale"
            label={globalDescLabel}
            value={bonDeCommande?.descriptionGlobale}
            error={errorInput?.descriptionGlobale}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={2}
            InputLabelProps={{ shrink: bonDeCommande?.descriptionGlobale && true }}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography
            variant="h4"
            style={{
              textAlign: 'center',
              color: '#e75113',
              textShadow: '#828181 0.05em 0.05em 0.05em',
            }}
          >
            Ligne de facturation
          </Typography>{' '}
          <br />
          <div className={classes.alert}>
            <Collapse in={open}>
              <Alert onClose={() => onCloseAlert()}>
                Cliquer sur le bouton voir détail pour renseigner les autres
                informations de chaque ligne de facturation !
              </Alert>
            </Collapse>
          </div>{' '}
          <br />
          <Button
            startIcon={<Add />}
            color="primary"
            variant="outlined"
            onClick={handleAddLigneFacturation}
          >
            Ajouter une ligne de facturation
          </Button>
        </Grid>

        <Grid item md={12} xs={12} lg={12} sm={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className={classes.thead}>
                  <TableCell className={classes.th}>
                    Famille opération
                  </TableCell>
                  <TableCell className={classes.th}>Opération</TableCell>
                  <TableCell className={classes.th}>
                    Type de facturation
                  </TableCell>
                  <TableCell className={classes.th}>Businness unit</TableCell>
                  <TableCell className={classes.th}>
                    Langue de traitement
                  </TableCell>
                  <TableCell className={classes.th}>
                    Categorie du lead
                  </TableCell>
                  <TableCell />
                  <TableCell className={classes.th}>Paramètrage</TableCell>
                  <TableCell className={classes.th}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tabOperation.map((index) => (
                  <RowLigneFact
                    key={index.id}
                    index={index}
                    tabOperation={tabOperation}
                    familleOperation={familleOperation}
                    operation={operation}
                    typeFacturation={typeFacturation}
                    bus={bu}
                    langueTrt={dataLangueTraitement}
                    handleUpdateTarifOfLignFact={handleUpdateTarifOfLignFact}
                    handleDeleteClick={handleDeleteClick}
                    arrayLignaFacturation={tabOperation}
                    idFamilleOperationNotEditableOnLignFactList={
                      idFamilleOperationNotEditableOnLignFactList
                    }
                    statutLeadDeletableOfLignaFact={
                      statutLeadDeletableOfLignaFact
                    }
                    idOperationDeletableOfLignaFact={
                      idOperationDeletableOfLignaFact
                    }
                    bonDeCommande={bonDeCommande}
                    objectifQuantitatif={objectifQuantitatif}
                    objectifQual={objectifQual}
                    allcoutHoraire={coutHoraire}
                    paysProduction={paysProd as any}
                    idBuManuel={idBuManuel}
                    idLangTrtManuel={idLangTrtManuel}
                    statutBdc={bonDeCommande?.statutLead}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSaveBdc}
        >
          {!isClickSave ? (
            'Enregistrer'
          ) : (
            <CircularProgress color="inherit" size={18} />
          )}
        </Button>
      </Box>{' '}
      <br />
      <div>{messageValidation}</div> <br />
      {idpar === undefined && (
        <div className={classes.root}>
          <Alert variant="filled" severity="warning">
            Pour ajouter une ligne de facturation, merci de revenir sur la fiche
            qualification !
          </Alert>
        </div>
      )}
      <CustomModal
        title="Ajouter une ligne de facturation"
        onClose={handleClose}
        open={openModal}
      >
        <LigneFacturationForm
          onSave={handleSaveLigneFacturation}
          value={ligneFacturationSave}
          paysProduction={dataPaysProduction}
          paysFacturation={dataPaysFacturation}
          paysProdBdc={bonDeCommande?.paysProduct}
          paysFactBdc={bonDeCommande?.paysFacturation?.id}
          horaireProduction={dataHoraireProduction}
          familleOperation={familleOperation}
          typeFacturation={typeFacturation}
          businessUnit={bu}
          langueTraitement={dataLangueTraitement}
          statutBdc={bonDeCommande?.statutLead}
        />
      </CustomModal>
    </Box>
  );

  /**
   * Fin.............................................
   */

  /**
   * Logique loading page............................
   */
  const pageBonDeCommande = !loading ? form : progress;

  return (
    <Fragment>
      {bonDeCommande?.activeForm === 'ok' ? bdc1 : pageBonDeCommande}
    </Fragment>
  );
};

export default BdcForm;
