import { Button } from '@mui/material';
import { useRef } from 'react';
import { useLocation } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';

const ExportPdf = () => {
  const location = useLocation();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <ComponentToPrint ref={componentRef}>{location.state}</ComponentToPrint>{' '}
      <br />
      <div>
        <Button
          variant="contained"
          style={{ backgroundColor: '#e75113', marginLeft: '30px' }}
          size="medium"
          onClick={handlePrint}
          startIcon={<DownloadForOfflineRoundedIcon />}
        >
          Télécharger
        </Button>
      </div> <br />
    </div>
  );
};

export default ExportPdf;
