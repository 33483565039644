import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      margin: '-8px 4px',
      width: 'auto',
      height: 30,
    },
    deconnect: {
      margin: '-8px 4px',
      width: 'auto',
      height: 22,
    },
    navItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    searchInput: {
      backgroundColor: '#FFF',
      marginLeft: '10px',
      borderRadius: '4px'
    }
  })
);
