import {
    Box,
    Typography,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Reducers } from '../../redux/reducers';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';

const useStyles = makeStyles({
    page: {
        width: '100%',
    },
    formControl: {
        width: '20%',
    },
    button: {
        marginTop: '10px',
    },
    buttonCanvas: {
        backgroundColor: '#8C8888',
    },
});

const RaisonSocialEdit = () => {
    const classes = useStyles();

    const logger = useSelector<Reducers>((store) => store.logger);

    const urlApi = process.env.REACT_APP_BACKEND_URL;

    const [encodedFile, setEncodedFile] = useState<string>();

    const showMessageToastError = () => {
        toast.error("Une erreure s'est produite, merci de ressayer.", {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        });
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleChangeFile = async (e) => {
        const base64Front = await convertBase64(e.target.files[0]);

        setEncodedFile(base64Front as string);
    };

    const handleSaveFile = () => {
        if (encodedFile) {
            const data = { file: encodedFile };

            axios
                .post(`${urlApi}edit/raison/social`, data, {
                    headers: {
                        Authorization: `Bearer ${(logger as any)?.token}`,
                    },
                })
                .then((res) => {
                })
                .catch((error) => {
                    showMessageToastError();
                    console.log(error.message);
                });
        }
    };

    return (
        <Box className={classes.page}>
            <Box my={2} style={{ color: '#e75113' }}>
                <Typography variant="h4" style={{ position: 'relative' }}>
                    <div style={{ textShadow: '#828181 0.05em 0.05em 0.05em' }}>
                        Mettre à jour les raisons social des client
                    </div>
                </Typography>
            </Box>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box>
                        <label htmlFor="file">Importer</label> <br />
                        <input
                            accept="*"
                            className={classes.formControl}
                            // onError={errorInput?.name}
                            id="raised-button-file"
                            name="piecesJointes"
                            onChange={handleChangeFile}
                            multiple
                            type="file"
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box className={classes.button}>
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => handleSaveFile()}
                >
                    Enregistrer
                </Button>
            </Box>
        </Box>
    );
};

export default RaisonSocialEdit;
