import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { FC, useEffect, useState } from 'react';

import { useStyles } from './styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import Contact from '../../../Interface/Contact';
import CustomTextField, {
  MyCustomEvent,
} from '../../commons/custom-text-field';

export interface ContactProfile {
  id: number;
  libelle: string;
}

export interface ContactFormProps {
  onSave: (value: Contact) => void;
  value?: Contact;
  contactProfiles: ContactProfile[];
}

const ContactForm: FC<ContactFormProps> = ({
  onSave,
  value,
  contactProfiles,
}) => {
  const classes = useStyles();
  const [constactToSave, setconstactToSave] = useState<Contact | undefined>();
  const [errorInput, setErrorInput] = useState<any>({
    profilContact: false,
    civilite: false,
    prenom: false,
    nom: false,
    tel: false,
    email: false,
  });
  const [showPrincipal, setShowPrincipal] = useState<boolean>(false);
  const [openSelectMultiple, setOpenSelectMultiple] = useState<boolean>(false);

  useEffect(() => {
    setconstactToSave(value);
    if (
      (value && value?.isFacturationPrincipal === 1) ||
      (value && value?.isCopieFacture === 1)
    ) {
      setShowPrincipal(true);
    }
  }, [value]);

  const handleCloseSelectMulti = () => {
    setOpenSelectMultiple((prev) => !prev);
  };

  const handleChangeSelectMultitple = (e: any) => {
    e.stopPropagation();
    handleCloseSelectMulti();
    setconstactToSave((prev) => ({
      ...prev,
      profilContactIds: e.target.value,
    }));
  };

  const handleChange = (e: any) => {
    setconstactToSave((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePhone = (phone: string, data: any) => {
    setconstactToSave((prev) => ({
      ...prev,
      tel: phone,
    }));
  };

  const handleChangeRadio = (_e: any, value: string) => {
    setconstactToSave((prev) => ({
      ...prev,
      isCopieFacture: value === 'non' ? 0 : 1,
    }));
    if (value === 'non') {
      setconstactToSave((prev) => ({ ...prev, isFacturationPrincipal: 0 }));
    }
    setShowPrincipal(value === 'oui');
  };

  const handleChangePrincipaleRadio = (_e: any, value: string) => {
    setconstactToSave((prev) => ({
      ...prev,
      isFacturationPrincipal: value === 'non' ? 0 : 1,
    }));
  };

  const handleChangeDateDesactivation = (e: MyCustomEvent) => {
    setconstactToSave((prev) => ({ ...prev, dateDesactivation: e.value }));
  };

  const handleSave = () => {
    if (
      constactToSave &&
      constactToSave?.profilContactIds?.length &&
      constactToSave?.civilite?.length &&
      constactToSave?.prenom?.length &&
      constactToSave?.nom?.length &&
      constactToSave?.tel?.length &&
      constactToSave?.email?.length &&
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
        constactToSave?.email
      )
    ) {
      const contactToSave: Contact = constactToSave?.id ? { ...constactToSave, isEditedContact: 1 } : { ...constactToSave, isNewContact: 1 };

      onSave(contactToSave);
    }

    if (!constactToSave?.profilContactIds?.length) {
      setErrorInput((prev: any) => ({ ...prev, profilContact: true }));
    }
    if (!constactToSave?.civilite?.length) {
      setErrorInput((prev: any) => ({ ...prev, civilite: true }));
    }
    if (!constactToSave?.prenom?.length) {
      setErrorInput((prev: any) => ({ ...prev, prenom: true }));
    }
    if (!constactToSave?.nom?.length) {
      setErrorInput((prev: any) => ({ ...prev, nom: true }));
    }
    if (!constactToSave?.tel?.length) {
      setErrorInput((prev: any) => ({ ...prev, tel: true }));
    }
    if (
      !constactToSave?.email?.length ||
      !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
        constactToSave?.email
      )
    ) {
      setErrorInput((prev: any) => ({ ...prev, email: true }));
    }
  };

  return (
    <Box className={classes.root}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="profil-contact">
          Profil Contact (Possibilité de séléctionner plusieurs profils)
        </InputLabel>
        <Select
          open={openSelectMultiple}
          onClick={() => handleCloseSelectMulti()}
          multiple
          error={errorInput.profilContact}
          displayEmpty
          value={constactToSave?.profilContactIds || []}
          onChange={handleChangeSelectMultitple}
          renderValue={(selected) => {
            return (
              <div className={classes.chips}>
                {((selected as string[]) || []).map((value) => (
                  <Chip
                    key={value}
                    label={
                      (contactProfiles || []).find(
                        (item) => item.id === parseInt(value)
                      )?.libelle
                    }
                    className={classes.chip}
                  />
                ))}
              </div>
            );
          }}
          label="Profil Contact (Possibilité de séléctionner plusieurs profils)"
          inputProps={{
            name: 'profilContact',
            id: 'profil-contact',
          }}
        >
          <MenuItem disabled value="">
            <em>Profil Contact</em>
          </MenuItem>
          {(contactProfiles || []).map((item, key) => (
            <MenuItem key={key} value={item.id}>
              {item.libelle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="civilite">Civilité *</InputLabel>
        <Select
          native
          required
          error={errorInput.civilite}
          label="Civilité *"
          value={constactToSave?.civilite}
          onChange={handleChange}
          inputProps={{
            name: 'civilite',
            id: 'civilite',
          }}
        >
          <option aria-label="None" value="" />
          <option value="Madame">Madame</option>
          <option value="Mademoiselle">Mademoiselle</option>
          <option value="Monsieur">Monsieur</option>
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          error={errorInput.prenom}
          required
          variant="outlined"
          label="Prenom"
          name="prenom"
          autoComplete="new-password"
          value={constactToSave?.prenom || null}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          error={errorInput.nom}
          required
          variant="outlined"
          label="Nom"
          name="nom"
          autoComplete="new-password"
          value={constactToSave?.nom || null}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          variant="outlined"
          label="Fonction"
          name="fonction"
          value={constactToSave?.fonction}
          onChange={handleChange}
        />
      </FormControl>
      <PhoneInput
        placeholder="Tél *"
        inputStyle={{
          width: '100%',
          height: 54,
          borderColor: errorInput.tel ? 'red' : 'none',
        }}
        enableSearch
        value={constactToSave?.tel}
        onChange={handleChangePhone}
      />
      <FormControl style={{ marginTop: 16 }} className={classes.formControl}>
        <TextField
          variant="outlined"
          type="email"
          label="Email"
          required
          name="email"
          autoComplete="new-password"
          error={errorInput.email}
          value={constactToSave?.email}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          variant="outlined"
          label="Skype"
          name="skype"
          value={constactToSave?.skype}
          onChange={handleChange}
        />
      </FormControl>
      {constactToSave?.createdAt ? (
        <CustomTextField
          type="date"
          name="dateDesactivation"
          onChange={handleChangeDateDesactivation}
          label="Date de désactivation"
          value={moment(constactToSave?.createdAt).format('yyyy-MM-DD')}
        />
      ) : (
        ''
      )}
      <FormControl component="fieldset">
        <FormLabel component="legend">Copie facture ?</FormLabel>
        <RadioGroup
          row
          value={
            constactToSave?.isCopieFacture === 0 ||
              constactToSave?.isCopieFacture === 1
              ? constactToSave?.isCopieFacture === 0
                ? 'non'
                : 'oui'
              : ''
          }
          onChange={handleChangeRadio}
        >
          <FormControlLabel
            value="oui"
            control={<Radio color="primary" />}
            label="Oui"
          />
          <FormControlLabel
            value="non"
            control={<Radio color="primary" />}
            label="Non"
          />
        </RadioGroup>
      </FormControl>
      <br />
      {showPrincipal && (
        <FormControl style={{ marginTop: 16 }} component="fieldset">
          <FormLabel component="legend">Contact facture principal ?</FormLabel>
          <RadioGroup
            row
            value={
              constactToSave?.isFacturationPrincipal === 0 ||
                constactToSave?.isFacturationPrincipal === 1
                ? constactToSave?.isFacturationPrincipal === 0
                  ? 'non'
                  : 'oui'
                : ''
            }
            onChange={handleChangePrincipaleRadio}
          >
            <FormControlLabel
              value="oui"
              control={<Radio color="primary" />}
              label="Oui"
            />
            <FormControlLabel
              value="non"
              control={<Radio color="primary" />}
              label="Non"
            />
          </RadioGroup>
        </FormControl>
      )}
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSave}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
