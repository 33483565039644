import { useState, useCallback, useEffect, FC } from 'react';
import {
  Box,
  Button,
  Collapse,
  Grid,
  Typography,
  Chip,
  MenuItem,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../redux/reducers';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';
import LigneFacturationForm from '../ligne-facturation-form/LigneFacturationForm';
import LigneFacturation from '../../../Interface/LigneFacturation';
import CustomModal from '../../commons/custom-modal';
import CircularProgress from '@mui/material/CircularProgress';
import PaysFacturation from '../../../Interface/PaysFacturation';
import Bdc from '../../../Interface/Bdc';
import { Add } from '@material-ui/icons';
import RowLigneFact from './RowLigneFact';
import { useHistory } from 'react-router';

toast.configure();

export const generateId = () => Math.floor(Math.random() * 100) + Date.now();
const urlApi = process.env.REACT_APP_BACKEND_URL;
const urlViewDoc = process.env.REACT_APP_VIEW_DOC_URL;

const statutLeadDeletableOfLignaFact = [null, -1, 3, 5, 7, 9, 10, 14, 16, 18, 19];

const idFamilleOperationNotEditableOnLignFactList = [9, 10];
const idOperationDeletableOfLignaFact = [15];
const tabIdOperationNotView = ['12', '1004'];
const tabOpAuto = [
  1, 13, 12, 16, 17, 1004, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 15, 14,
];

export interface Ref {
  id: number;
  libelle: string;
}

export interface RefCoutHoraire {
  id: number;
  dateDebut: Date;
  dateFin: Date;
  pays: string;
  niveau: string;
  langueSpecialite: string;
  coutHoraire: number;
  coutFormation: number;
  bu: string;
}

export interface RetourResumeLead {
  nombreBdc: number;
  uniqId: string[];
  oneBdc: string;
}

export interface ProfilContact {
  id: number;
  libelle: string;
}

export interface ContactHasProfilContact {
  id: number;
  profilContact: ProfilContact;
}

export interface Contact {
  id: number;
  nom: string;
  prenom: string;
  fonction: string;
  isCopieFacture: number;
  contactHasProfilContacts: ContactHasProfilContact[];
}

export interface Client {
  id?: number;
  contacts: Contact[];
}

export interface BdcFormProps {
  dataFicheQualification: RetourResumeLead;
  dataPaysProduction: Ref[];
  dataHoraireProduction: Ref[];
  dataLangueTraitement: Ref[];
  nextPage;
  forthbdc;
  operation;
  familleOperation;
  typeFacturation;
  bu;
  langueTrt;
}

const BdcForm3: FC<BdcFormProps> = ({
  dataFicheQualification,
  dataPaysProduction,
  dataHoraireProduction,
  dataLangueTraitement,
  nextPage,
  forthbdc,
  operation,
  familleOperation,
  typeFacturation,
  bu,
  langueTrt,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const logger = useSelector<Reducers>((store) => store.logger);
  const [bonDeCommande, setBonDeCommande] = useState<Bdc>();
  const [isClickSave, setIsClickSave] = useState(false);
  const [objectifQuantitatif, setObjectQuantitatif] = useState<Ref[]>([]);
  const [objectifQual, setObjectifQual] = useState<Ref[]>([]);
  const [coutHoraire, setCoutHoraire] = useState<RefCoutHoraire[]>([]);
  const [dataPaysFacturation, setDataPaysFacturation] = useState<PaysFacturation[]>([]);
  const [dataSocieteFacturation, setDataSocieteFacturation] = useState<Ref[]>([]);
  const [dataDevise, setDataDevise] = useState<Ref[]>([]);
  const [bdcs, setBdc] = useState<Bdc[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [paysProd, setPaysProd] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [ligneFacturationSave, setligneFacturationSave] = useState<
    LigneFacturation | undefined
  >();
  // eslint-disable-next-line no-unused-vars
  const [a, b, c, d] = dataFicheQualification?.uniqId
    ? dataFicheQualification?.uniqId
    : [];
  const [openSelectMultiple, setOpenSelectMultiple] = useState<boolean>(false);
  const [openSelectMultiple2, setOpenSelectMultiple2] =
    useState<boolean>(false);
  const [customerContacts, setCustomerContacts] = useState<Client[]>([]);
  const [errorInput, setErrorInput] = useState({
    paysFact: false,
    societeFacturation: false,
    tva: false,
    devise: false,
    modeReglement: false,
    delaisPaiment: false,
    destinataireSignataire: false,
    destinataireFacture: false,
    descriptionGlobale: false
  });

  /**
   * Logique destinataire facture
   * Si le contact is copie facture est égal oui, alors on a la valeur dans le champ destinataire facture
   */
  const tabDestinataireFacture = [];
  for (const i in customerContacts) {
    for (const j in customerContacts[i]?.contacts) {
      if (Number(customerContacts[i]?.contacts[j]?.isCopieFacture) === 1) {
        tabDestinataireFacture.push(customerContacts[i]?.contacts[j]);
      }
    }
  }

  /**
   * Logique destinataire signataire
   * Afficher uniquement la liste dans le select destinataire signataire si le contact ayant
   * de profil signatire
   */
  const tabDestinataireSignataire = [];
  for (const i in customerContacts) {
    for (const j in customerContacts[i]?.contacts) {
      for (const k in customerContacts[i]?.contacts[j]
        ?.contactHasProfilContacts) {
        if (
          Number(
            customerContacts[i]?.contacts[j]?.contactHasProfilContacts[k]
              ?.profilContact?.id
          ) === 4
        ) {
          tabDestinataireSignataire.push(customerContacts[i]?.contacts[j]);
        }
      }
    }
  }

  // Supprimer les doublons dans le tableau destinataire signataire
  const uniqTabDestinataireSignataire = [
    ...(new Set(tabDestinataireSignataire) as any),
  ];

  const tabIdTva = [];
  for (const i in bdcs) {
    for (const j in bdcs[i]?.paysFacturation?.tvas) {
      tabIdTva.push(bdcs[i]?.paysFacturation?.tvas[j]?.id);
    }
  }

  const affichAutoPaysFact = () => {
    if (dataPaysFacturation.length === 1) {
      return Number(dataPaysFacturation[0]?.id);
    } else {
      return bonDeCommande?.paysFact;
    }
  };

  const affichAutoSocieteFact = () => {
    if (dataSocieteFacturation.length === 1) {
      return Number(dataSocieteFacturation[0]?.id);
    } else {
      return bonDeCommande?.societeFacturation;
    }
  };

  const affichAutoDevise = () => {
    if (dataDevise.length === 1) {
      return Number(dataDevise[0]?.id);
    } else {
      return bonDeCommande?.devise;
    }
  };

  const affichAutoTva = () => {
    if (tabIdTva.length === 1) {
      return Number(tabIdTva[0]);
    } else {
      return bonDeCommande?.tva;
    }
  };

  const idData = +bdcs?.map((item) => item?.id);
  const showData = useCallback(() => {
    setBonDeCommande(bdcs?.find((indice) => indice?.id === idData));
  }, [bdcs]);

  /**
   * Logique affichage liste opération du bon de commande (ligne de facturation)
   * avec quelque opérations ajoutés automatiques ne sont pas afficher
   */
  const tabOperation = [];

  for (const k in bonDeCommande?.bdcOperations) {
    if (
      !tabIdOperationNotView.includes(
        String(bonDeCommande?.bdcOperations[k]?.operation)
      )
    ) {
      tabOperation.push(bonDeCommande?.bdcOperations[k]);
    }
  }

  useEffect(() => {
    showData();
  }, [showData]);

  useEffect(() => {
    // get pays de facturation
    axios.get(`${urlApi}bon/de/commande/ref/PaysFacturation`, {
      headers: {
        Authorization: `Bearer ${(logger as any)?.token}`,
      },
    }).then((res) => {
      console.log(res.data);
      Array.isArray(res.data) && setDataPaysFacturation(res.data);
    }).catch((e) => {
      console.log(e.message);
    });

    // get ref cout horaire......................
    axios
      .get(`${urlApi}cout/horaire`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setCoutHoraire(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get ref objectif qualitatif
    axios
      .get(`${urlApi}bon/de/commande/ref/ObjectifQualitatif`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setObjectifQual(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get ref objectif quantitatif
    axios
      .get(`${urlApi}bon/de/commande/ref/ObjectifQuantitatif`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setObjectQuantitatif(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  const handleChange = (event) => {
    setBonDeCommande({
      ...bonDeCommande,
      [event.target.name]: event.target.value,
    });
  };

  const onCloseAlert = () => {
    setOpen(false);
  };

  /**
   * get id bu pour les opérations ajouté manuels and
   * id langue de traitement pour les opérations ajouté manuel
   */
  const idBuManuel = [];
  const idLangTrtManuel = [];
  for (const k in bonDeCommande?.bdcOperations) {
    if (!tabOpAuto.includes(bonDeCommande?.bdcOperations[k]?.operation)) {
      idBuManuel.push(bonDeCommande?.bdcOperations[k]?.bu);
      idLangTrtManuel.push(bonDeCommande?.bdcOperations[k]?.langueTrt);
    }
  }

  const showMessageWarningValidation = (param) => {
    toast.warning(`Merci de remplir le champ ${param} SVP ?`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const handleSaveBdc = useCallback(() => {
    setIsClickSave(true);

    // Validation form avant l'ajout data bdc
    if (!affichAutoPaysFact()) {
      setErrorInput((prev) => ({ ...prev, paysFact: true }));
      showMessageWarningValidation('Pays de facturation');
      setIsClickSave(false);
    }
    if (!affichAutoSocieteFact()) {
      setErrorInput((prev) => ({ ...prev, societeFacturation: true }));
      showMessageWarningValidation('Société de facturation');
      setIsClickSave(false);
    }
    if (!affichAutoTva()) {
      setErrorInput((prev) => ({ ...prev, tva: true }));
      showMessageWarningValidation('TVA de facturation');
      setIsClickSave(false);
    }
    if (!affichAutoDevise()) {
      setErrorInput((prev) => ({ ...prev, devise: true }));
      showMessageWarningValidation('Devise de facturation');
      setIsClickSave(false);
    }
    if (!bonDeCommande?.modeReglement?.length) {
      setErrorInput((prev) => ({ ...prev, modeReglement: true }));
      showMessageWarningValidation('Mode de règlement');
      setIsClickSave(false);
    }
    if (!bonDeCommande?.delaisPaiment?.length) {
      setErrorInput((prev) => ({ ...prev, delaisPaiment: true }));
      showMessageWarningValidation('Delais de paiement');
      setIsClickSave(false);
    }
    if (!bonDeCommande?.destinataireSignataire?.length) {
      setErrorInput((prev) => ({ ...prev, destinataireSignataire: true }));
      showMessageWarningValidation('Destinataire signataire');
      setIsClickSave(false);
    }
    if (!bonDeCommande?.destinataireFacture?.length) {
      setErrorInput((prev) => ({ ...prev, destinataireFacture: true }));
      showMessageWarningValidation('Destinataire facture');
      setIsClickSave(false);
    }
    if (!bonDeCommande?.descriptionGlobale) {
      setErrorInput((prev) => ({ ...prev, descriptionGlobale: true }));
      showMessageWarningValidation('Description globale');
      setIsClickSave(false);
    }

    // save data
    if (
      bonDeCommande &&
      affichAutoPaysFact() &&
      affichAutoSocieteFact() &&
      affichAutoDevise() &&
      affichAutoTva() &&
      bonDeCommande?.modeReglement?.length &&
      bonDeCommande?.delaisPaiment?.length &&
      bonDeCommande?.destinataireFacture?.length &&
      bonDeCommande?.destinataireSignataire?.length &&
      bonDeCommande?.descriptionGlobale?.length
    ) {
      // On vérifie d'abord si la marge cible est inférieur au seuil
      axios.get(`${urlApi}check/marge/cible/${bonDeCommande?.id}`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        }
      }).then((res) => {
        if (res.data === 'Merci de paramétrés SVP tous les lignes de facturations !') {
          /**
           * Dans le cas s'il y a une ligne de facturation non paramétré
           */
          setIsClickSave(false);
          toast.warning(res.data, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        } else {
          /**
          * Si res.data === 1, alors la marge cible est supérieur au seuil et on passe à l'enregistrement.
          * Sinon, on alerte l'utilisateur pour qu'il modifier les prix horaires
          */
          if (res.data === 1) {
            return axios
              .put(
                `${urlApi}bon/de/commande/${bonDeCommande?.id}`,
                {
                  ...bonDeCommande,
                  paysFact: affichAutoPaysFact(),
                  societeFacturation: affichAutoSocieteFact(),
                  devise: affichAutoDevise(),
                  tva: affichAutoTva(),
                },
                {
                  headers: {
                    Authorization: `Bearer ${(logger as any)?.token}`,
                  },
                }
              )
              .then((res) => {
                setIsClickSave(false);
                if (res.data.error) {
                  toast.warning(res.data.message, {
                    position: 'top-right',
                    autoClose: 60000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                  });
                } else {
                  const idMere = res?.data?.idMere;
                  window.open(
                    `${urlViewDoc}/bdc/bdc_${idMere}.pdf`,
                    '_blank'
                  );

                  history.push('/bdc_list');
                }
              })
              .catch((e) => {
                alert(e.message);
                showMessageToastError();
                setIsClickSave(false);
              });
          } else {
            setIsClickSave(false);
            toast.warning(`Merci de modifier les prix horaires car la marge cible est inférieur à ${res.data}% !`, {
              position: 'top-right',
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
        }
      }).catch(error => {
        console.log(error.message);
      });
    }
  }, [bonDeCommande]);

  const handleSaveLigneFacturation = useCallback(
    (data: LigneFacturation) => {
      const tempData = {
        ...data,
        id: generateId(),
        newOperation: 'ok',
        uniq: generateId(),
      };
      let ligneFacturations = bonDeCommande?.bdcOperations;
      if (!ligneFacturations) ligneFacturations = [];
      ligneFacturations?.push(tempData);
      setBonDeCommande((prev) => ({ ...prev, ligneFacturations }));
      setOpenModal(false);
    },
    [bonDeCommande]
  );

  const handleCloseSelectMulti = () => {
    setOpenSelectMultiple((prev) => !prev);
  };

  const handleChageSelectMulti = (e) => {
    e.stopPropagation();
    handleCloseSelectMulti();
    setBonDeCommande((prev) => ({
      ...prev,
      destinataireSignataire: e.target.value,
    }));
  };

  const handleCloseSelectMulti2 = () => {
    setOpenSelectMultiple2((prev) => !prev);
  };

  const handleAddLigneFacturation = () => {
    setOpenModal(true);
    setligneFacturationSave(undefined);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChageSelectMulti2 = (e) => {
    e.stopPropagation();
    handleCloseSelectMulti2();
    setBonDeCommande((prev) => ({
      ...prev,
      destinataireFacture: e.target.value,
    }));
  };

  // get bdc by id
  useEffect(() => {
    if (forthbdc === 0) {
      axios
        .get(`${urlApi}bdc/${d}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setBdc(
            (Array.isArray(res.data) && res.data).map((item) => {
              // Debut logique adresse de facturation................
              const adresseCustomer = item.resumeLead.customer.adresse;
              const isAdresseFactDiff =
                item.resumeLead.customer.isAdressFactDiff;
              const adrFacturation =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.adresse
                  : null;
              const cp =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.cp
                  : null;
              const ville =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.ville
                  : null;
              const pays =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.pays
                  : null;

              const adrCpVillePays =
                'adresse: ' +
                adrFacturation +
                ' - ' +
                'cp: ' +
                cp +
                ' - ' +
                'ville: ' +
                ville +
                ' - ' +
                'pays: ' +
                pays;

              const adresse =
                isAdresseFactDiff[0] > 0 ? adrCpVillePays : adresseCustomer;
              // Fin logique adresse de facturation.......................................

              return {
                ...item,
                raisonSocial: item?.resumeLead?.customer?.raisonSocial
                  ? item?.resumeLead?.customer?.raisonSocial
                  : undefined,
                marqueCommercial: item?.resumeLead?.customer?.marqueCommercial
                  ? item?.resumeLead?.customer?.marqueCommercial
                  : undefined,
                statutClient: item?.resumeLead?.customer?.categorieClient
                  ?.libelle
                  ? item?.resumeLead?.customer?.categorieClient?.libelle
                  : undefined,
                adresseFacturation: adresse,
                resumeLead: item?.resumeLead?.id
                  ? item?.resumeLead?.id
                  : undefined,
                paysProduction: item?.paysProduction?.libelle
                  ? item?.paysProduction?.libelle
                  : undefined,
                paysProduct: item?.paysProduction?.id
                  ? item?.paysProduction?.id
                  : undefined,
                paysFact: item?.paysFacturation?.id,
                societeFacturation: item?.societeFacturation?.id
                  ? item?.societeFacturation?.id
                  : undefined,
                devise: item?.devise?.id ? item?.devise?.id : undefined,
                tva: item?.tva?.id ? item?.tva?.id : undefined,
                bdcOperations: (item?.bdcOperations || []).map((index) => {
                  return {
                    ...index,
                    operation: index?.operation?.id
                      ? index?.operation?.id
                      : undefined,
                    designationActe: index?.designationActe?.id
                      ? index?.designationActe?.id
                      : undefined,
                    langueTrt: index?.langueTrt?.id
                      ? index?.langueTrt?.id
                      : undefined,
                    typeFacturation: index?.typeFacturation?.id
                      ? index?.typeFacturation?.id
                      : undefined,
                    familleOperation: index?.operation?.familleOperation?.id
                      ? index?.operation?.familleOperation?.id
                      : undefined,
                    bu: index?.bu?.id ? index?.bu?.id : undefined,
                    coutHoraire: index?.coutHoraire?.id
                      ? index?.coutHoraire?.id
                      : undefined,
                    objectifQualitatif: index?.objectifQualitatif?.map(obj => {
                      return obj?.id;
                    }),
                    objectifQuantitatif: index?.objectifQuantitatif?.map(obj => {
                      return obj?.id;
                    }),
                    indicateurQt: index?.indicatorQuantitatifs?.map(indicQt => {
                      return {
                        indicator: indicQt?.indicator,
                        objectifQt: String(indicQt?.objectifQuantitatif?.id),
                      };
                    }),
                    indicateurQl: index?.indicatorQualitatifs?.map(indicQl => {
                      return {
                        indicator: indicQl?.indicator,
                        objectifQl: String(indicQl?.objectifQualitatif?.id),
                      };
                    }),
                  } as LigneFacturation;
                }),
              } as Bdc;
            })
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else {
      /*
       * Si cutomer different de zero, alors on cherche le BDC via l'id du customer
       */
      axios
        .get(`${urlApi}bonDeCommande/via/id/${forthbdc[3]}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setBdc(
            (Array.isArray(res.data) && res.data).map((item) => {
              // Debut logique adresse de facturation................
              const adresseCustomer = item.resumeLead.customer.adresse;
              const isAdresseFactDiff =
                item.resumeLead.customer.isAdressFactDiff;
              const adrFacturation =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.adresse
                  : null;
              const cp =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.cp
                  : null;
              const ville =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.ville
                  : null;
              const pays =
                isAdresseFactDiff[0] > 0
                  ? item.resumeLead.customer.adresseFacturation.pays
                  : null;

              const adrCpVillePays =
                'adresse: ' +
                adrFacturation +
                ' - ' +
                'cp: ' +
                cp +
                ' - ' +
                'ville: ' +
                ville +
                ' - ' +
                'pays: ' +
                pays;

              const adresse =
                isAdresseFactDiff[0] > 0 ? adrCpVillePays : adresseCustomer;
              // Fin logique adresse de facturation.......................................

              return {
                ...item,
                raisonSocial: item?.resumeLead?.customer?.raisonSocial
                  ? item?.resumeLead?.customer?.raisonSocial
                  : undefined,
                marqueCommercial: item?.resumeLead?.customer?.marqueCommercial
                  ? item?.resumeLead?.customer?.marqueCommercial
                  : undefined,
                statutClient: item?.resumeLead?.customer?.categorieClient
                  ?.libelle
                  ? item?.resumeLead?.customer?.categorieClient?.libelle
                  : undefined,
                adresseFacturation: adresse,
                resumeLead: item?.resumeLead?.id
                  ? item?.resumeLead?.id
                  : undefined,
                paysProduction: item?.paysProduction?.libelle
                  ? item?.paysProduction?.libelle
                  : undefined,
                paysProduct: item?.paysProduction?.id
                  ? item?.paysProduction?.id
                  : undefined,
                paysFact: item?.paysFacturation?.id,
                societeFacturation: item?.societeFacturation?.id
                  ? item?.societeFacturation?.id
                  : undefined,
                devise: item?.devise?.id ? item?.devise?.id : undefined,
                tva: item?.tva?.id ? item?.tva?.id : undefined,
                bdcOperations: (item?.bdcOperations || []).map((index) => {
                  return {
                    ...index,
                    operation: index?.operation?.id
                      ? index?.operation?.id
                      : undefined,
                    designationActe: index?.designationActe?.id
                      ? index?.designationActe?.id
                      : undefined,
                    langueTrt: index?.langueTrt?.id
                      ? index?.langueTrt?.id
                      : undefined,
                    typeFacturation: index?.typeFacturation?.id
                      ? index?.typeFacturation?.id
                      : undefined,
                    familleOperation: index?.operation?.familleOperation?.id
                      ? index?.operation?.familleOperation?.id
                      : undefined,
                    bu: index?.bu?.id ? index?.bu?.id : undefined,
                    coutHoraire: index?.coutHoraire?.id
                      ? index?.coutHoraire?.id
                      : undefined,
                    objectifQualitatif: index?.objectifQualitatif?.map(obj => {
                      return obj?.id;
                    }),
                    objectifQuantitatif: index?.objectifQuantitatif?.map(obj => {
                      return obj?.id;
                    }),
                    indicateurQt: index?.indicatorQuantitatifs?.map(indicQt => {
                      return {
                        indicator: indicQt?.indicator,
                        objectifQt: String(indicQt?.objectifQuantitatif?.id),
                      };
                    }),
                    indicateurQl: index?.indicatorQualitatifs?.map(indicQl => {
                      return {
                        indicator: indicQl?.indicator,
                        objectifQl: String(indicQl?.objectifQualitatif?.id),
                      };
                    }),
                  } as LigneFacturation;
                }),
              } as Bdc;
            })
          );
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }

    // get customer with contacts
    if (localStorage.getItem('id_customer')) {
      axios
        .get(`${urlApi}customer/${localStorage.getItem('id_customer')}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          Array.isArray(res.data) && setCustomerContacts(res.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [logger]);

  useEffect(() => {
    if (bonDeCommande?.paysFact) {
      const data = dataPaysFacturation?.find(paysFact => Number(paysFact?.id) === Number(bonDeCommande?.paysFact));
      setDataSocieteFacturation(data?.societeFacturations);
      setDataDevise(data?.devises);
    }
  }, [bonDeCommande?.paysFact]);

  useEffect(() => {
    if (bonDeCommande?.paysProduction !== undefined) {
      setPaysProd(String(bonDeCommande?.paysProduction));
    }
  }, [bonDeCommande]);

  const handleDeleteClick = useCallback(
    (id) => {
      if (id.toString().length === 13) {
        setBonDeCommande({
          ...bonDeCommande,
          bdcOperations: bonDeCommande?.bdcOperations?.filter(
            (item) => item.id !== id
          ),
        });
      } else {
        if (window.confirm('Etes-vous sûr de vouloir supprimer ?')) {
          axios
            .delete(`${urlApi}delete/operation/${id}`, {
              headers: {
                Authorization: `Bearer ${(logger as any)?.token}`,
              },
            })
            .then((res) => {
              setBonDeCommande({
                ...bonDeCommande,
                bdcOperations: bonDeCommande?.bdcOperations?.filter(
                  (item) => item.id !== id
                ),
              });
              toast.success(
                "Suppression d'une ligne de facturation avec succès",
                {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                }
              );
            })
            .catch((e) => {
              showMessageToastError();
            });
        }
      }
    },
    [bonDeCommande]
  );

  const globalDescLabel = (
    <span style={{ color: 'red', fontSize: 20 }}>
      <span style={{ color: '#AAAAAA', fontSize: 16 }}>Description globale</span>{' '}
      *
    </span>
  );

  /**
   * Loader style...........................
   */
  const progress = (
    <div className={classes.loading}>
      <LinearProgress />
      <LinearProgress color="secondary" />
    </div>
  );

  /**
   * Logique show message validarion forms........................
   */
  const messageValidation =
    affichAutoPaysFact() === undefined ||
      affichAutoSocieteFact() === undefined ||
      bonDeCommande?.modeReglement == null ||
      bonDeCommande?.delaisPaiment == null ||
      affichAutoDevise() === undefined ||
      affichAutoTva() === undefined ? (
      <Alert variant="filled" severity="error">
        Attention tous les champs en <b>*</b> rouge sont obligatoire, merci de
        remplir SVP !
      </Alert>
    ) : null;

  /**
   * Formulaire bdc assigné dans une variable "form".......................
   */
  const form = (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography
            variant="h3"
            style={{
              color: '#e75113',
              textShadow: '#828181 0.05em 0.05em 0.05em',
            }}
          >
            Bon de commande
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} xl={6}>
          <TextField
            name="raisonSocial"
            label="Raison sociale"
            value={bonDeCommande?.raisonSocial}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="marqueCommerciale"
            label="Marque commerciale"
            value={bonDeCommande?.marqueCommercial}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="paysProduction"
            label="Pays de production"
            value={bonDeCommande?.paysProduction}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="outlined-age-native-simple"
              shrink={true}
            >
              Pays de facturation{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              native
              error={errorInput?.paysFact}
              value={affichAutoPaysFact()}
              onChange={handleChange}
              style={{
                backgroundColor: dataPaysFacturation.length === 1 && '#EAECEE',
              }}
              label="Pays de facturation"
              inputProps={{
                name: 'paysFact',
              }}
            >
              <option value=""></option>
              {dataPaysFacturation?.map((paysFact) =>
                <option key={paysFact?.id} value={paysFact?.id}>
                  {paysFact?.libelle}
                </option>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              Société de facturation{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              native
              error={errorInput?.societeFacturation}
              value={affichAutoSocieteFact()}
              onChange={handleChange}
              style={{
                backgroundColor: dataSocieteFacturation.length === 1 && '#EAECEE',
              }}
              label="Société de facturation"
              inputProps={{
                name: 'societeFacturation',
              }}
            >
              <option value=""></option>
              {dataSocieteFacturation?.map((socifact) =>
                <option key={socifact?.id} value={socifact?.id}>
                  {socifact.libelle}
                </option>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              Devise de facturation{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              native
              error={errorInput?.devise}
              value={affichAutoDevise()}
              onChange={handleChange}
              style={{ backgroundColor: dataDevise.length === 1 && '#EAECEE' }}
              label="Devise de facturation"
              inputProps={{
                name: 'devise',
              }}
            >
              <option value=""></option>
              {dataDevise?.map((devise) =>
                <option key={devise?.id} value={devise?.id}>
                  {devise?.libelle}
                </option>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              TVA de facturation{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              native
              value={affichAutoTva()}
              onChange={handleChange}
              disabled={tabIdTva.length === 1 && true}
              style={{ backgroundColor: tabIdTva.length === 1 && '#EAECEE' }}
              label="TVA de facturation"
              inputProps={{
                name: 'tva',
              }}
            >
              <option value=""></option>
              {bdcs.map((x) =>
                x.paysFacturation.tvas.map((y) => (
                  <option key={y.id} value={y.id}>
                    {y.libelle}
                  </option>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} lg={6} xl={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="destinataire">
              Destinataire signataire{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              open={openSelectMultiple}
              onClick={() => handleCloseSelectMulti()}
              multiple
              displayEmpty
              error={errorInput?.destinataireSignataire}
              value={bonDeCommande?.destinataireSignataire || []}
              onChange={handleChageSelectMulti}
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {((selected as string[]) || []).map((value) => (
                      <Chip
                        key={value}
                        label={
                          uniqTabDestinataireSignataire?.find(
                            (item) => item.id === parseInt(value)
                          )?.prenom
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                );
              }}
              label="Destinataire signataire"
              inputProps={{
                name: 'destinataireSignataire',
              }}
            >
              <MenuItem disabled value="">
                <em>Destinataire signataire</em>
              </MenuItem>
              {uniqTabDestinataireSignataire?.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.prenom} - {row.nom} - {row.fonction}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="destinataire">
              Destinataire facture{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              open={openSelectMultiple2}
              onClick={() => handleCloseSelectMulti2()}
              multiple
              displayEmpty
              error={errorInput?.destinataireFacture}
              value={bonDeCommande?.destinataireFacture || []}
              onChange={handleChageSelectMulti2}
              renderValue={(selected) => {
                return (
                  <div className={classes.chips}>
                    {((selected as string[]) || []).map((value) => (
                      <Chip
                        key={value}
                        label={
                          tabDestinataireFacture?.find(
                            (item) => item.id === parseInt(value)
                          )?.prenom
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                );
              }}
              label="Destinataire facture"
              inputProps={{
                name: 'destinataireFacture',
              }}
            >
              <MenuItem disabled value="">
                <em>Destinataire facture</em>
              </MenuItem>
              {tabDestinataireFacture?.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.prenom} - {row.nom} - {row.fonction}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="statutClient"
            label="Statut client"
            value={bonDeCommande?.statutClient}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              Mode de règlement{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              native
              error={errorInput?.modeReglement}
              value={bonDeCommande?.modeReglement}
              onChange={handleChange}
              label="Mode de règlement"
              inputProps={{
                name: 'modeReglement',
              }}
            >
              <option aria-label="None" value="" />
              <option value="Virement">Virement</option>
              <option value="Prélèvement">Prélèvement</option>
            </Select>
          </FormControl>
          <TextField
            name="delaisPaiment"
            label="Délais de paiement"
            error={errorInput?.delaisPaiment}
            value={bonDeCommande?.delaisPaiment || ''}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            label="Résumé de la prestation"
            name="resumePrestation"
            value={bonDeCommande?.resumePrestation}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            rows={5}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="diffusions"
            label="Liste de diffusion facturation"
            value={bonDeCommande?.diffusions}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="adresseFacturation"
            label="Adresse de facturation"
            value={bonDeCommande?.adresseFacturation}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name="descriptionGlobale"
            label={globalDescLabel}
            value={bonDeCommande?.descriptionGlobale}
            error={errorInput?.descriptionGlobale}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={2}
            InputLabelProps={{ shrink: bonDeCommande?.descriptionGlobale && true }}
          />
        </Grid>

        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <Typography
            variant="h4"
            style={{
              textAlign: 'center',
              color: '#e75113',
              textShadow: '#828181 0.05em 0.05em 0.05em',
            }}
          >
            Ligne de facturation
          </Typography>{' '}
          <br />
          <div className={classes.alert}>
            <Collapse in={open}>
              <Alert onClose={() => onCloseAlert()}>
                Cliquer sur le bouton voir détail pour renseigner les autres
                informations de chaque ligne de facturation !
              </Alert>
            </Collapse>
          </div>{' '}
          <br />
          <Button
            startIcon={<Add />}
            color="primary"
            variant="outlined"
            onClick={handleAddLigneFacturation}
          >
            Ajouter une ligne de facturation
          </Button>
        </Grid>

        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.thead}>
                <TableCell className={classes.th}>
                  Famille opération
                </TableCell>
                <TableCell className={classes.th}>
                  Opération
                </TableCell>
                <TableCell className={classes.th}>
                  Type de facturation
                </TableCell>
                <TableCell className={classes.th}>
                  Businness unit
                </TableCell>
                <TableCell className={classes.th}>
                  Langue de traitement
                </TableCell>
                <TableCell className={classes.th}>
                  Categorie du lead
                </TableCell>
                <TableCell />
                <TableCell className={classes.th}>
                  Paramètrage
                </TableCell>
                <TableCell className={classes.th}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tabOperation.map((index) => (
                <RowLigneFact
                  key={index.id}
                  index={index}
                  tabOperation={tabOperation}
                  familleOperation={familleOperation}
                  operation={operation}
                  typeFacturation={typeFacturation}
                  bus={bu}
                  langueTrt={langueTrt}
                  handleDeleteClick={handleDeleteClick}
                  arrayLignaFacturation={tabOperation}
                  idFamilleOperationNotEditableOnLignFactList={
                    idFamilleOperationNotEditableOnLignFactList
                  }
                  statutLeadDeletableOfLignaFact={
                    statutLeadDeletableOfLignaFact
                  }
                  idOperationDeletableOfLignaFact={
                    idOperationDeletableOfLignaFact
                  }
                  bonDeCommande={bonDeCommande}
                  objectifQuantitatif={objectifQuantitatif}
                  objectifQual={objectifQual}
                  allcoutHoraire={coutHoraire}
                  paysProduction={paysProd as any}
                  idBuManuel={idBuManuel}
                  idLangTrtManuel={idLangTrtManuel}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Box marginTop={4} display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSaveBdc}
        >
          {!isClickSave ? (
            'Enregistrer'
          ) : (
            <CircularProgress color="inherit" size={18} />
          )}
        </Button>
      </Box>{' '}
      <br />
      <div>{messageValidation}</div> <br />
      <div className={classes.root}>
        <Alert variant="filled" severity="warning">
          Pour ajouter une ligne de facturation, merci de revenir sur la fiche
          qualification !
        </Alert>
      </div>
      <CustomModal
        title="Ajouter une ligne de facturation"
        onClose={handleClose}
        open={openModal}
      >
        <LigneFacturationForm
          onSave={handleSaveLigneFacturation}
          value={ligneFacturationSave}
          paysProduction={dataPaysProduction}
          paysFacturation={dataPaysFacturation}
          horaireProduction={dataHoraireProduction}
          paysProdBdc={bonDeCommande?.paysProduct}
          paysFactBdc={bonDeCommande?.paysFacturation?.id}
          familleOperation={familleOperation}
          typeFacturation={typeFacturation}
          businessUnit={bu}
          langueTraitement={dataLangueTraitement}
          statutBdc={bonDeCommande?.statutLead}
        />
      </CustomModal>
    </Box>
  );

  /**
   * Fin.............................................
   */

  /**
   * Logique loading page............................
   */
  const pageBonDeCommande = !loading ? form : progress;

  return <div>{pageBonDeCommande}</div>;
};

export default BdcForm3;
