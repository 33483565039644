/* eslint-disable camelcase */
// import { useEffect, useState } from 'react';
import { useStyles } from './styles';
import accueilImage from '../../images/accueilIcon.png';
import personDollard from '../../images/personDollars.png';
import dashboard from '../../images/dashboard.png';
import nouvelleVente from '../../images/nouvelleVente.png';
import iconAdmin from '../../images/iconAdmin.png';
// import icondeconnexion from '../../images/icondeconnexion.png';
import { Clear, Menu, Search } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ResponsiveMenu from 'react-responsive-navbar';
import './styleNative.css';
import { useSelector } from 'react-redux';
import { Reducers } from '../../redux/reducers';
import { Box, InputAdornment, TextField } from '@material-ui/core';

const home = process.env.REACT_APP_HOME_URL;
const stat = process.env.REACT_APP_STAT_URL;
const dashboard_url = process.env.REACT_APP_DASHBOARD_REPS;
const admin_url = process.env.REACT_APP_ADMIN;
const searchUrl = process.env.REACT_APP_SEARCH_URL;

const Navigation = () => {
  const classes = useStyles();
  const nameUserConnecte = useSelector<Reducers>(
    (store) => store?.logger?.username
  );
  const Role = useSelector<Reducers>(
    (store) => store?.logger?.role
  );
  const createurVente: string[] = ['ROLE_USER', 'ROLE_DG'];

  // const labelShearch = <span style={{ color: 'white' }}>Recherche ...</span>;

  return (
    <ResponsiveMenu
      menuOpenButton={<Menu />}
      menuCloseButton={<Clear />}
      changeMenuOn="900px"
      menu={
        <ul className="menu-list">
          <li>
            <a
              onClick={() => window.location.assign(home)}
              className="menu-link"
            >
              <img
                className={classes.img}
                src={accueilImage}
                alt="statEudIcon"
              />
            </a>
          </li>
          <li>
            <a
              onClick={() => window.location.assign(stat)}
              className="menu-link"
            >
              <div className={classes.navItem}>
                <span>Statistiques études et lancements en prod</span>
                <img
                  src={personDollard}
                  className={classes.img}
                  alt="statEudIcon"
                />
              </div>
            </a>
          </li>
          <li>
            <a
              onClick={() => window.location.assign(dashboard_url)}
              className="menu-link"
            >
              <div className={classes.navItem}>
                <span>Tableau de bord resp indus</span>
                <img src={dashboard} className={classes.img} alt="dashboard" />
              </div>
            </a>
          </li>
          <li>
           { createurVente.includes(Role as string) ? <Link to="/creation_vente" className="menu-link">
              <div className={classes.navItem}>
                <span>Créer nouvelle vente</span>
                <img
                  src={nouvelleVente}
                  className={classes.img}
                  alt="newVente"
                />
              </div>
            </Link> : <a></a>
          }
          </li>
          <li>
            <a
              onClick={() => window.location.assign(admin_url)}
              className="menu-link"
            >
              <div className={classes.navItem}>
                <span>{nameUserConnecte}</span>
                <img src={iconAdmin} className={classes.img} alt="admin" />
              </div>
            </a>
          </li>
          {/*  <li>
            <a
              // onClick={() => window.location.assign(admin_url)}
              className="menu-link"
            >
              <img
                src={icondeconnexion}
                className={classes.deconnect}
                alt="deconnect"
              />
            </a>
          </li> */}
          <li>
            <Box className={classes.searchInput}>
              <form action={searchUrl} method="POST">
                <TextField
                  size="small"
                  id="outlined-basic"
                  // label="Recherche ..."
                  placeholder='Recherche ...'
                  variant="outlined"
                  name="n_devis_rapide"
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  /*  InputLabelProps={{
                    shrink: true
                  }} */
                />
              </form>
            </Box>
          </li>
        </ul>
      }
    />
  );
};

export default Navigation;
