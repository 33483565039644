import { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from './components/commons/private-route';
import FicheClient from './components/Contact/ficheClient/FicheClient';
import BdcForm from './components/forms/bdc-form/BdcForm';
import ResumeLeadForm from './components/forms/resume-lead-form/ResumeLeadForm';
import FicheSocieteForm from './pages/qualification-vente';
import ExportPdf from './components/Contact/pdf/ExportPdf';
import ListeCoutAgent from './components/coutAgent/ListeCoutAgent';
import RaisonSocialEdit from './components/Contact/RaisonSocialEdit';
import HomeBdc from './components/BonDeCommande/HomeBdc';
import HausseIndiceSyntec from './components/HausseIndiceSyntec/HausseIndiceSyntec';

const QualificationVente = lazy(() => import('./pages/qualification-vente'));
const BdcListe = lazy(() => import('./components/BonDeCommande/BdcList/BdcListe'));
const CustomerList = lazy(() => import('./components/Contact/CustomerList'));
const FicheQualificationList = lazy(() => import('./components/FicheQualification/FicheQualificationList'));

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <PrivateRoute
          path="/creation_vente"
          component={QualificationVente}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/fiche_qualif_list"
          component={FicheQualificationList}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/modif_resumelead/:idfq"
          component={ResumeLeadForm}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/bdc_list"
          component={BdcListe}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/home/bdc/:token"
          component={HomeBdc}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/modif_bdc/:idpar"
          component={BdcForm}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/contact_list"
          component={CustomerList}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/update/raison/social"
          component={RaisonSocialEdit}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/customer/:id"
          component={FicheSocieteForm}
          exact
        />
        <PrivateRoute
          path="/fiche_client"
          exact
          component={FicheClient}
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/export_pdf_fiche_client"
          component={ExportPdf}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/import_cout_agent"
          component={ListeCoutAgent}
          exact
        />
      </Switch>
      <Switch>
        <PrivateRoute
          path="/HausseIndiceSyntec"
          component={HausseIndiceSyntec}
          exact
        />
      </Switch>
    </Suspense>
  );
};

export default Routes;
