import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = (colorHelperText, fullWidth: boolean = true) => {
  let objectStyle = {};
  if (fullWidth) {
    objectStyle = { ...objectStyle, width: '100%' };
  }
  return makeStyles((theme: Theme) =>
    createStyles({
      root: {
        ...objectStyle,
        marginBottom: theme.spacing(2),
      },
      textField: {
        width: '100%',
      },
      helperText: {
        color: !colorHelperText ? theme.palette.common.black : colorHelperText,
      },
    })
  );
};
