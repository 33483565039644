import { Box, Container, Step, StepButton, Stepper } from '@material-ui/core';
import axios from 'axios';
import { useState, FC, useEffect } from 'react';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { Reducers } from '../../redux/reducers';
import { useHistory, useLocation } from 'react-router';
import ResumeLeadForm, {
  customer,
  ResumeLead,
} from '../../components/forms/resume-lead-form/ResumeLeadForm';
import FicheSocieteForm from '../../components/forms/fiche-societe-form';
import BdcForm, { RetourResumeLead } from '../../components/forms/bdc-form/BdcForm';
import Bdc from '../../Interface/Bdc';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FicheSociete } from '../../components/forms/fiche-societe-form/FicheSocieteForm';

toast.configure();

const urlApi = process.env.REACT_APP_BACKEND_URL;
const urlViewDoc = process.env.REACT_APP_VIEW_DOC_URL;

const QualificationVente: FC = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [clickStep, setClickStep] = useState(false);
  const [disableStep, setDisableStep] = useState(false);
  const [dataResumeLead, setDataResumeLead] = useState<RetourResumeLead>();
  const [customerId, setCustomerId] = useState<customer>();
  const location = useLocation();
  const [idCustomer, setIdCustomer] = useState(0);
  const [idFq, setIdFq] = useState(0);
  const [idBdc, setIdBdc] = useState(0);
  const [createdCustomer, setCreatedCustomer] = useState([]);
  const [isClickSaveBdc, setIsClickSaveBdc] = useState(false);

  const logger = useSelector<Reducers>((state) => state.logger);
  const stepLead = location.state ? location.state : [];
  const steps = [
    'Fiche société et ses contacts',
    'Fiche qualification',
    'Bon de commande',
    'BDC créer en brouillon',
    'BDC créer et à valider par Dir. Prod.',
    'BDC validé par Dir. Prod. et à valider par S. Financier',
    'BDC validé par le S. Financier à valider par le DG',
    'BDC validé par le DG et à signer par le Commercial',
    'BDC à signer par le Client',
  ];

  const handleStep = (step) => () => {
    // Si le step n'est pas disable
    if (!disableStep) {
      if (step < 3) {
        setClickStep(true);
        setActiveStep(step);
      } else if (step === 3 && stepLead[1] === 3) {
        axios.get(`${urlApi}send/notification/to/superior/${stepLead[1]}/${stepLead[1]}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          }
        }).then((res) => {
          toast.success(res.data, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false
          });
        });
      }
    } else {
      if (step === 4 && stepLead[1] === 4) {
        axios.get(`${urlApi}send/notification/to/customer/${stepLead[1]}/${stepLead[0]}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          }
        }).then((res) => {
          toast.success(res.data, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false
          });
        });
      } else if (step === 5 && stepLead[1] === 5) {
        axios.get(`${urlApi}send/notification/to/financial/${stepLead[1]}/${stepLead[0]}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          }
        }).then((res) => {
          toast.success(res.data, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false
          });
        });
      }
      if (step <= 3) {
        toast.warning('Desolé! Vous ne pouvez plus révenir sur cette étape !', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false
        });
      }
    }
  };

  const checkAttribut = (attr) => {
    if (attr === undefined || attr === null || String(attr).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (stepLead) {
      if (typeof (stepLead[0]) === 'string') {
        if (stepLead[0] === 'RESUME_LEAD') {
          setIdFq(stepLead[1]);
          setActiveStep(1);
        } else {
          if (stepLead[0] === 'BDC') {
            // eslint-disable-next-line no-unused-vars
            const [mod, nombreBdc, uniqId] = stepLead as any;

            if (Number(nombreBdc) > 1) {
              toast.info(`Merci de mettre à jour les ${nombreBdc} nouveaux bons de commandes créé`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
              });
            }

            setDataResumeLead({ nombreBdc: nombreBdc, uniqId: uniqId, oneBdc: '' });
          } else {
            setIdBdc(stepLead[1]);
          }
          setActiveStep(2);
        }
      } else {
        if (stepLead[1] <= 3 || stepLead[1] === 7) {
          if (stepLead[1] === 7) {
            // steps[4] = 'Bon de commande rejeter par le N+1';
            setIdCustomer(stepLead[0]);
            setActiveStep(4);
          } else {
            setIdCustomer(stepLead[0]);
            setActiveStep(stepLead[1] as number);
          }
        } else if (stepLead[1] >= 4) {
          if (stepLead[1] === 4) {
            setDisableStep(true);
            setActiveStep(stepLead[1] as number);
          } else if (stepLead[1] === 5) {
            setDisableStep(true);
            setActiveStep(stepLead[1] as number);
          } else if (stepLead[1] === 6) {
            setDisableStep(true);
            setActiveStep(stepLead[1] as number);
          } else if (stepLead[1] === 9) {
            setDisableStep(true);
            setActiveStep(4);
          }
        }
      }
    } else {
      setActiveStep(0);
    }
  }, [setActiveStep]);

  const nextPage = () => {
    if (activeStep < 3) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const getIdCustomer = (customer) => {
    setCreatedCustomer(customer);
  };

  const getStepContent = (
    stepIndex: number,
    handleClickSave: (type: string, data: FicheSociete | ResumeLead | Bdc) => void
  ) => {
    switch (stepIndex) {
      case 0:
        return (
          <FicheSocieteForm
            mode="ADD"
            onSave={(data) => handleClickSave('FICHE_SOCIETE', data)}
            valeur={clickStep}
            parentCallback={getIdCustomer}
            cust={customerId ? Number(customerId) : Number(stepLead[0])}
          />
        );
      case 1:
        return (
          <ResumeLeadForm
            mode="ADD"
            onSave={(data) => handleClickSave('RESUME_LEAD', data)}
            client={typeof (customerId) !== 'undefined' ? customerId : undefined}
            idOfEditFq={checkAttribut(idFq) ? Number(idFq) : 0}
            prevStep={clickStep}
            callbackOne={createdCustomer}
          />
        );
      case 2:
        return (
          <BdcForm
            mode="EDIT"
            onSave={(data) => handleClickSave('BON_DE_COMMANDE', data)}
            dataFicheQualification={dataResumeLead}
            nextPage={nextPage}
            prevStep={clickStep}
            customer={idCustomer}
            idBdc={idBdc}
            isSave={isClickSaveBdc}
            callbackTwo={createdCustomer}
          />
        );
      case 3:
        return 'BDC créer en brouillon';
      case 4:
        return 'Bon de commande créer et à valider par le Directeur de Production';
      case 5:
        return 'Bdc validé par le Directeur de Production et à valider par le Service Juridique';
      case 6:
        return 'BDC validé par le Service Juridique et à valider par le Directeur Générale.';
      case 7:
        return 'BDC à signer par le Commerciale';
      case 8:
        return 'BDC à signer par le Client';
      default:
        return 'Fin!!!';
    }
  };

  const handleClickSave = (
    type: string,
    data: FicheSociete | ResumeLead | Bdc
  ) => {
    type === 'FICHE_SOCIETE' && saveCustomer(data as FicheSociete);
    type === 'RESUME_LEAD' && saveResumeLead(data as ResumeLead);
    type === 'BON_DE_COMMANDE' && editBdc(data as Bdc);
  };

  const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  // Fonction ajout et mise à jour du client
  const saveCustomer = (data: FicheSociete) => {
    if (data.id) {
      // update a cutomer
      axios
        .put(`${urlApi}update-customer/${data.id}`, data, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then(res => {
          setCustomerId(res.data);
          localStorage.setItem('id_customer', res.data);
          nextPage();
        });
    } else {
      //  create new Customer
      axios
        .post(`${urlApi}customer`, data, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setCustomerId(res.data);
          localStorage.setItem('id_customer', res.data);
          nextPage();
        })
        .catch((e) => {
          alert(e.message);
          showMessageToastError();
        });
    }
  };

  // Fonction ajout fiche qualification ou resumé du lead
  const saveResumeLead = (data: ResumeLead) => {
    if (data.id) {
      // if (data.id || createdCustomer[1] > 1) {
      // update resumé du lead
      axios
        .put(`${urlApi}resume/lead/${data.id}`, data, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then(res => {
          console.log(res.data);
          setDataResumeLead(res.data);
          nextPage();
        })
        .catch((e) => {
          alert(e.message);
          showMessageToastError();
        });
    } else {
      // ajout resumé du lead
      axios
        .post(`${urlApi}resume/lead`, data, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setDataResumeLead(res.data);
          localStorage.setItem('id_resume_lead', res.data.idFq);
          localStorage.setItem('uniq_id_bdc', res.data.oneBdc);
          nextPage();
          if (res.data.nombreBdc !== 1) {
            toast.info(`Total Bon de commande restant à créer : ${res.data.nombreBdc - 1}`, {
              position: 'top-right',
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          } else {
            return null;
          }
        })
        .catch((e) => {
          alert(e.message);
          showMessageToastError();
        });
    }
  };

  // Fonction pour mettre à jour le bon de commmande et ouvrir nouvel onglet 2 PDF bdc
  const editBdc = (data: Bdc) => {
    if (data.id) {
      axios
        .put(`${urlApi}bon/de/commande/${data.id}`, data, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          setIsClickSaveBdc(true);
          if (res.data.error) {
            toast.warning(res.data.message, {
              position: 'top-right',
              autoClose: 60000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          } else {
            const idMere = res?.data?.idMere;
            window.open(
              `${urlViewDoc}/bdc/bdc_${idMere}.pdf`,
              '_blank'
            );
            // nextPage();
            history.push('/bdc_list');
          }
        })
        .catch((e) => {
          alert(e.message);
          showMessageToastError();
        });
    }
  };

  return (
    <Container>
      <Box className={classes.root}>
        {/* <Box className={classes.rightNav}></Box> */}
        <Box className={classes.formContainer} style={{ position: 'relative' }}>
          <Stepper
            nonLinear
            className={classes.stepper}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={handleStep(index)} >
                  {label}
                </StepButton>
              </Step>
            ))}

          </Stepper>
          <Box className={classes.formContent} mt={1}>
            {getStepContent(activeStep, handleClickSave)}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default QualificationVente;
