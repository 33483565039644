// import React from 'react'
import {
  Box,
  Typography,
  Button,
  InputLabel,
  Select,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Reducers } from '../../redux/reducers';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';

const urlApi = process.env.REACT_APP_BACKEND_URL;
toast.configure();

const useStyles = makeStyles({
  page: {
    width: '100%',
  },
  formControl: {
    width: '20%',
  },
  button: {
    marginTop: '10px',
  },
  buttonCanvas: {
    backgroundColor: '#8C8888',
  },
});

export interface File {
  name: string;
  annee: string;
}

const ImportCoutAgent = () => {
  const classes = useStyles();
  const logger = useSelector<Reducers>((store) => store.logger);
  const [fichier, setFichier] = useState<File>();
  const [errorInput, setErrorInput] = useState<any>({
    annee: false,
    name: false,
  });

  // Avoir une date actuelle
  const nowDate = new Date();

  const showToastSuccess = () => {
    toast.success('Fichier importé', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const showToastMessageModelNonConform = (param) => {
    toast.warning(`${param}, il faut télécharger le model canvas SVP?`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const showToastMessageAlert = () => {
    toast.warning(
      "L'importation de ce fichier prend beaucoup de temps, veuillez patienter un peu!",
      {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      }
    );
  };

  const showToMessageRequiredInput = (param) => {
    toast.warning(`Merci de ${param} SVP?`, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const handleChangeAnnee = (e) => {
    setFichier({
      ...fichier,
      annee: e.target.value,
    });
  };

  const handleChangeFile = async (e) => {
    const base64Front = await convertBase64(e.target.files[0]);
    setFichier({
      ...fichier,
      name: base64Front as any,
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSaveFile = (data) => {
    if (data && data?.annee?.length && data?.name?.length) {
      showToastMessageAlert();
      axios
        .post(`${urlApi}import/cout/annuel`, data, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          if (res?.data?.messageAlert) {
            showToastMessageModelNonConform(res?.data?.messageAlert);
          } else {
            showToastSuccess();
            window?.location?.reload();
          }
        })
        .catch((error) => {
          showMessageToastError();
          console.log(error.message);
        });
    }

    if (!data?.annee?.length) {
      setErrorInput((prev) => ({ ...prev, annee: true }));
      showToMessageRequiredInput('remplir le champ Année');
    }
    if (!data?.name?.length) {
      setErrorInput((prev) => ({ ...prev, name: true }));
      showToMessageRequiredInput('choisir le fichier à importer');
    }
  };

  return (
    <Box className={classes.page}>
      <Box my={2} style={{ color: '#e75113' }}>
        <Typography variant="h4" style={{ position: 'relative' }}>
          <div style={{ textShadow: '#828181 0.05em 0.05em 0.05em' }}>
            Import coûts annuels
          </div>
        </Typography>
      </Box>
      <Box>
        <Button
          color="primary"
          className={classes.buttonCanvas}
          size="small"
          variant="contained"
          startIcon={<FileDownloadDoneIcon />}
        >
          <Link
            style={{ textDecoration: 'none' }}
            to="/model_canvas_couts_agents.xlsx"
            target="_blank"
            download
          >
            Télécharger Model Canvas
          </Link>
        </Button>
      </Box>{' '}
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <InputLabel id="annee">
              Année
              <span style={{ color: 'red', fontSize: 20 }}> * </span>
            </InputLabel>
            <Select
              id="annee"
              error={errorInput?.annee}
              native
              label="Année"
              name="annee"
              // color="primary"
              value={fichier?.annee || ''}
              onChange={handleChangeAnnee}
            >
              <option aria-label="None" value="" />
              <option value={nowDate?.getFullYear()}>
                {nowDate?.getFullYear()}
              </option>
              <option value={nowDate?.getFullYear() + 1}>
                {nowDate?.getFullYear() + 1}
              </option>
            </Select>
          </FormControl>

          <Box>
            <label htmlFor="file">Importer</label> <br />
            <input
              accept="*"
              className={classes.formControl}
              // onError={errorInput?.name}
              id="raised-button-file"
              name="piecesJointes"
              onChange={handleChangeFile}
              multiple
              type="file"
            />
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.button}>
        <Button
          color="primary"
          size="small"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={() => handleSaveFile(fichier)}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

export default ImportCoutAgent;
