// import React from 'react';
import { styled } from '@mui/system';

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const Root = styled('div')(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};
  }
  `
);

const TableEvolution = ({ data }) => {
  return (
    <Root sx={{ minWidth: 100 }}>
      <table aria-label="custom pagination table">
        <thead>
          <tr>
            <th>Années</th>
            <th>CA facturé</th>
            <th>CA budget</th>
            <th>Ecart CA</th>
            <th>Tx de marge réalisé</th>
            <th>Tx de marge budget</th>
            <th>Ecart tx marge budget</th>
            <th>Marge réalisée</th>
            <th>Marge budget</th>
            <th>Ecart marge budget</th>
          </tr>
        </thead>
        <tbody>
              {/* <tr key={null}>
                <td>{null}</td>
                <td style={{ width: 120 }} align="right">
                  {null}
                </td>
                <td style={{ width: 120 }} align="right">
                  {null}
                </td>
                <td style={{ width: 120 }} align="right">
                  {null}
                </td>
                <td style={{ width: 120 }} align="right">
                  {null}
                </td>
                <td style={{ width: 120 }} align="right">
                  {null}
                </td>
                <td style={{ width: 120 }} align="right">
                  {null}
                </td>
                <td style={{ width: 120 }} align="right">
                  {null}
                </td>
                <td style={{ width: 120 }} align="right">
                  {null}
                </td>
                <td style={{ width: 120 }} align="right">
                  {null}
                </td>
              </tr> */}
        </tbody>
        <tfoot>
          <tr>
            <td>TOTAUX</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </Root>
  );
};

export default TableEvolution;
