import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  LinearProgress,
  InputAdornment
} from '@material-ui/core';
import { useState, FC, useEffect, Fragment } from 'react';
import { useStyles } from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../redux/reducers';
import LigneFacturation from '../../../Interface/LigneFacturation';
import Operation from '../../../Interface/Operation';
import categoryLead from '../../../constants/categoryLead';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'react-toastify/dist/ReactToastify.css';

const urlApi = process.env.REACT_APP_BACKEND_URL;
toast.configure();
export interface Ref {
  id: number;
  libelle: string;
}

export interface RefData {
  id: number;
  libelle: string;
}
export interface RefCoutHoraire {
  id: number;
  dateDebut: Date;
  dateFin: Date;
  pays: string;
  niveau: string;
  langueSpecialite: string;
  coutHoraire: number;
  coutFormation: number;
  bu: string;
}

export interface IndicateurQualitatif {
  indicator: string;
  objectifQualitatif?: number;
}

export interface LigneFacturationFormProps {
  onSave: (value: LigneFacturation) => void;
  value?: LigneFacturation;
  familleOperation: RefData[];
  typeFacturation: RefData[];
  businessUnit: RefData[];
  horaireProduction: RefData[];
  paysFacturation: RefData[];
  langueTraitement: RefData[];
  paysProduction: RefData[];
  paysProdBdc: number;
  paysFactBdc: number;
  statutBdc: number;
}

const tabIdOperationForDmtInput: number[] = [1005, 1006];
const showTarifHoraireCibleByTyFactArray: number[] = [1, 7];
const statutBdcEnProduction: number[] = [11, 20];

const LigneFacturationForm: FC<LigneFacturationFormProps> = ({
  onSave,
  value,
  familleOperation,
  typeFacturation,
  horaireProduction,
  paysFacturation,
  businessUnit,
  langueTraitement,
  paysProduction,
  paysProdBdc,
  paysFactBdc,
  statutBdc
}) => {
  const logger = useSelector<Reducers>((store) => store.logger);
  const [ligneFacturation, setLigneFacturation] = useState<LigneFacturation | undefined>();
  const [allOperations, setAllOperations] = useState<Operation[]>([]);
  const [operations, setOperations] = useState<Operation[]>([]);
  const [operationsActe, setOperationsActe] = useState<Operation[]>([]);
  const classes = useStyles();
  const [familyOeration, setFamilyOperation] = useState<Ref[]>([]);
  const [loading, setLoading] = useState(true);
  const [libelleFamilleOperation, setLibelleFamilleOperation] = useState({
    libelle: '',
  });
  const [openSelectMultiple, setOpenSelectMultiple] = useState<boolean>(false);
  const [openSelectMultiple2, setOpenSelectMultiple2] = useState<boolean>(false);
  const [objectifQuantitatif, setObjectQuantitatif] = useState<Ref[]>([]);
  const [objectifQual, setObjectifQual] = useState<Ref[]>([]);
  const [coutHoraire, setCoutHoraire] = useState<RefCoutHoraire[]>([]);
  const [indicatorQuant, setIndicatorQuant] = useState([]);
  const [indicatorQual, setIndicatorQual] = useState([]);
  const [tempLibelleBu, setTempLibelleBu] = useState<String>('');
  const [paysProdValue, setPaysProdValue] = useState<String>('');

  const [errorInput, setErrorInput] = useState<any>({
    langueTrt: false,
    paysProduction: false,
    paysFacturation: false,
    bu: false,
    familleOperation: false,
    operation: false,
    coutHoraire: false,
    horaireProduction: false,
    categorieLead: false,
  });

  const [errorInputTypeFactActe, setErrorInputTypeFactActe] = useState<any>({
    tempsProductifs: false,
    tarifHoraireCible: false,
    volumeATraite: false,
  });

  const [errorInputTypeFactHeureAndForfait, setErrorInputTypeFactHeureAndForfait] = useState<any>({
    nbHeureMensuel: false,
    nbEtp: false,
  });

  // eslint-disable-next-line no-unused-vars
  const [errorInputTypeFactMixte, setErrorInputTypeFactMixte] = useState<any>({
    productiviteActe: false,
    prixUnitaireActe: false,
    prixUnitaireHeure: false
  });

  const categoryClients: Ref[] = categoryLead;

  const transformText = (text: string): string => {
    return (text)?.toLowerCase()?.replace(/\s+/g, '');
  };

  useEffect(() => {
    setLigneFacturation(value);
  }, [value]);

  useEffect(() => {
    // Recupère tout les operations
    axios
      .get(`${urlApi}bon/de/commande/ref/Operation`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setAllOperations(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    if (!statutBdcEnProduction?.includes(statutBdc)) {
      setLigneFacturation({
        ...ligneFacturation,
        paysProduction: paysProdBdc,
        paysFacturation: paysFactBdc
      });
    }
  }, []);

  useEffect(() => {
    setLigneFacturation({
      ...ligneFacturation,
      indicateurQt: indicatorQuant as any,
      indicateurQl: indicatorQual as any,
    });
  }, [indicatorQual, indicatorQuant]);

  useEffect(() => {
    const arrayFamilleOperation = [];
    for (const i in familleOperation) {
      const eachFamilleOperationLibelle = (familleOperation[i]?.libelle)?.toLowerCase()?.replace(/\s+/g, '');
      if (tempLibelleBu === 'etudes') {
        if ((eachFamilleOperationLibelle)?.indexOf('etude') > -1) {
          arrayFamilleOperation.push(familleOperation[i]);
        }
      } else if (tempLibelleBu === 'devinfo') {
        if ((eachFamilleOperationLibelle)?.indexOf('dev') > -1) {
          arrayFamilleOperation.push(familleOperation[i]);
        }
      } else {
        if ((eachFamilleOperationLibelle)?.indexOf(tempLibelleBu?.toLowerCase()) > -1) {
          arrayFamilleOperation.push(familleOperation[i]);
        }
      }
    }
    setFamilyOperation(arrayFamilleOperation);

    // Recupère les operations pour designation acte
    if (ligneFacturation?.bu) {
      const tmpBu: string = getbusLabel(ligneFacturation?.bu);

      const familleActe: number = familleOperation?.find(fo => (transformText(fo?.libelle)?.includes(transformText(tmpBu)) && transformText(fo?.libelle)?.includes('acte')))?.id;

      const acteOperation: Operation[] = allOperations?.filter(elem => (elem?.familleOperation?.id === familleActe && !(elem?.libelle?.toLowerCase())?.includes('hno')));

      setOperationsActe(acteOperation);
    }
  }, [ligneFacturation?.bu]);

  useEffect(() => {
    // Recupère les operations necessaire dans l'input operation
    if (allOperations?.length > 0) {
      const tabOperations = allOperations?.filter(elem => elem?.familleOperation?.id === Number(ligneFacturation?.familleOperation) && !(elem?.libelle?.toLowerCase())?.includes('hno'));

      setOperations(tabOperations);
    }
  }, [ligneFacturation?.familleOperation, ligneFacturation?.bu]);

  /**
   * Retourne ma valeur par defaut du type de facturation
   */
  useEffect(() => {
    let valTypeFact: number;
    if (Number(ligneFacturation?.operation) === 1005) {
      valTypeFact = 6;
    } else if (
      libelleFamilleOperation?.libelle?.toLowerCase()?.indexOf('heure') > -1 ||
      getLabelTypeFacturation(ligneFacturation?.operation)?.indexOf(
        'heure'
      ) > -1
    ) {
      valTypeFact = 3;
    } else if (
      libelleFamilleOperation?.libelle?.toLowerCase()?.indexOf('forfait') > -1 ||
      getLabelTypeFacturation(ligneFacturation?.operation)?.indexOf(
        'forfait'
      ) > -1
    ) {
      valTypeFact = 5;
    } else if (
      libelleFamilleOperation?.libelle?.toLowerCase()?.indexOf('acte') > -1 ||
      getLabelTypeFacturation(ligneFacturation?.operation)?.indexOf(
        'acte'
      ) > -1
    ) {
      valTypeFact = 1;
    }

    setLigneFacturation({
      ...ligneFacturation,
      typeFacturation: valTypeFact
    });
  }, [
    libelleFamilleOperation?.libelle,
    ligneFacturation?.operation
  ]);

  useEffect(() => {
    if (statutBdcEnProduction?.includes(statutBdc)) {
      setPaysProdValue(getPaysProdLabel(Number(ligneFacturation?.paysProduction)));
    } else {
      setPaysProdValue(getPaysProdLabel(paysProdBdc));
    }
  }, [ligneFacturation?.paysProduction]);

  useEffect(() => {
    if (operations.length === 1) {
      setLigneFacturation({
        ...ligneFacturation,
        operation: Number(operations[0]?.id),
      });
    }
  }, [operations]);

  useEffect(() => {
    if (Number(ligneFacturation?.typeFacturation) === 7) {
      if (checkAttribut(ligneFacturation?.productiviteActe) &&
        checkAttribut(ligneFacturation?.nbHeureMensuel) &&
        checkAttribut(ligneFacturation?.nbEtp)) {
        /**
        * Si type de facturation est mixte
        * Volume mensuel = productiviteActe * nbHeureMensuel * nbEtp
        */
        const volumeMens: number = Number(ligneFacturation?.productiviteActe) * Number(ligneFacturation?.nbHeureMensuel) * Number(ligneFacturation?.nbEtp);

        setLigneFacturation({
          ...ligneFacturation,
          volumeATraite: String(volumeMens?.toFixed(2)),
        });
      }
    }
  }, [
    ligneFacturation?.typeFacturation,
    ligneFacturation?.nbHeureMensuel,
    ligneFacturation?.nbEtp,
    ligneFacturation?.productiviteActe,
  ]);

  useEffect(() => {
    if (Number(ligneFacturation?.typeFacturation) === 7) {
      if (checkAttribut(ligneFacturation?.tarifHoraireCible) &&
        checkAttribut(ligneFacturation?.productiviteActe) &&
        checkAttribut(ligneFacturation?.prixUnitaireHeure)) {
        /**
        * Si type de facturation est mixte
        * prix Unitaire Acte = (tarifHoraireCible - prixUnitaireHeure) / productiviteActe
        */
        const prixActe: number = (Number(ligneFacturation?.tarifHoraireCible) - Number(ligneFacturation?.prixUnitaireHeure)) / Number(ligneFacturation?.productiviteActe);

        setLigneFacturation({
          ...ligneFacturation,
          prixUnitaireActe: String(prixActe?.toFixed(2))
        });
      }
    }
  }, [
    ligneFacturation?.typeFacturation,
    ligneFacturation?.tarifHoraireCible,
    ligneFacturation?.prixUnitaireHeure,
    ligneFacturation?.productiviteActe
  ]);

  /**
  * Logique calcul prix unitaire
  * Prix unitaire = tarifHoraireCible / (tempProdictiifs / dmt)
  */
  useEffect(() => {
    if (Number(ligneFacturation?.typeFacturation) === 1) {
      if (ligneFacturation?.tarifHoraireCible && ligneFacturation?.tempsProductifs && ligneFacturation?.dmt) {
        const tarifHoraireCibleValue = ligneFacturation?.tarifHoraireCible;
        const tempProdValue =
          parseInt(ligneFacturation?.tempsProductifs?.slice(0, 2)) +
          parseInt(ligneFacturation?.tempsProductifs?.slice(3, 5)) / 60 +
          parseInt(ligneFacturation?.tempsProductifs?.slice(6, 8)) / 3600;
        const dmtValue =
          parseInt(ligneFacturation?.dmt?.slice(0, 2)) +
          parseInt(ligneFacturation?.dmt?.slice(3, 5)) / 60 +
          parseInt(ligneFacturation?.dmt?.slice(6, 8)) / 3600;

        const price = tarifHoraireCibleValue / (tempProdValue / dmtValue);

        setLigneFacturation({
          ...ligneFacturation,
          prixUnit: price.toFixed(2)
        });
      }
    }

    if (Number(ligneFacturation?.typeFacturation) === 7) {
      setLigneFacturation({
        ...ligneFacturation,
        prixUnit: null
      });
    }
  }, [
    ligneFacturation?.typeFacturation,
    ligneFacturation?.tarifHoraireCible,
    ligneFacturation?.tempsProductifs,
    ligneFacturation?.dmt
  ]);

  const handleDateChange = (date: Date | null, name: string) => {
    setLigneFacturation((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleChange = (e) => {
    // Ici on va faire le mise à jour via la fonction handleChange
    if (e.target.name === 'bu') {
      setTempLibelleBu(getbusLabel(e.target.value)?.toLowerCase()?.replace(/\s+/g, ''));
    } else if (e.target.name === 'familleOperation') {
      // get libelle famille opération selectionné
      setLibelleFamilleOperation({
        ...libelleFamilleOperation,
        libelle: String(
          familleOperation.find((i) => i.id === Number(e.target.value))?.libelle
        ),
      });
    }

    /**
     * La valeur de productivité acte doit être comprise entre 0 et 1
     */
    if (e.target.name === 'productiviteActe') {
      const productiviteActeValue: number = e.target.value < 0 ? 0 : e.target.value > 1 ? 1 : e.target.value;

      setLigneFacturation({
        ...ligneFacturation,
        productiviteActe: productiviteActeValue,
      });
    } else {
      setLigneFacturation({
        ...ligneFacturation,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCloseSelectMulti = () => {
    setOpenSelectMultiple((prev) => !prev);
  };

  const handleCloseSelectMulti2 = () => {
    setOpenSelectMultiple2((prev) => !prev);
  };

  const handleChangeSelectMultitple = (e: any) => {
    e.stopPropagation();
    handleCloseSelectMulti();
    if (ligneFacturation?.objectifQuantitatif?.length > e.target.value.length) {
      const key = ligneFacturation?.objectifQuantitatif?.findIndex(obj => !e.target.value.includes(obj));
      const values = [...indicatorQuant];
      values.splice(key, 1);
      setIndicatorQuant(values);
    } else {
      setIndicatorQuant([
        ...indicatorQuant,
        {
          indicator: '',
          objectifQt: ''
        }
      ]);
    }
    setLigneFacturation((prev) => ({
      ...prev,
      objectifQuantitatif: e.target.value,
    }));
  };

  const handleChangeSelectMultitple2 = (e: any) => {
    e.stopPropagation();
    handleCloseSelectMulti2();

    if (ligneFacturation?.objectifQualitatif?.length > e.target.value.length) {
      const key = ligneFacturation?.objectifQualitatif?.findIndex(obj => !e.target.value.includes(obj));
      const values = [...indicatorQual];
      values.splice(key, 1);
      setIndicatorQual(values);
    } else {
      setIndicatorQual([
        ...indicatorQual,
        {
          indicator: '',
          objectifQl: ''
        }
      ]);
    }
    setLigneFacturation((prev) => ({
      ...prev,
      objectifQualitatif: e.target.value,
    }));
  };

  const checkAttribut = (attr) => {
    if (attr === undefined || attr === null || String(attr).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const checkAllRequiredInput = () => {
    if (
      !checkAttribut(ligneFacturation?.typeFacturation) ||
      !checkAttribut(ligneFacturation?.langueTrt) ||
      // !checkAttribut(ligneFacturation?.paysProduction) ||
      // !checkAttribut(ligneFacturation?.paysFacturation) ||
      !checkAttribut(ligneFacturation?.bu) ||
      !checkAttribut(ligneFacturation?.familleOperation) ||
      !checkAttribut(ligneFacturation?.operation) ||
      !checkAttribut(ligneFacturation?.coutHoraire) ||
      !checkAttribut(ligneFacturation?.horaireProduction) ||
      !checkAttribut(ligneFacturation?.categorieLead) ||
      ([1, 7].includes(Number(ligneFacturation?.typeFacturation)) &&
        (!checkAttribut(ligneFacturation?.tarifHoraireCible) ||
          !checkAttribut(ligneFacturation?.volumeATraite) ||
          Math.sign(Number(ligneFacturation?.volumeATraite)) === -1)) ||
      ([3, 5, 7].includes(ligneFacturation?.typeFacturation) && (!checkAttribut(ligneFacturation?.nbHeureMensuel) ||
        Math.sign(Number(ligneFacturation?.nbHeureMensuel)) === -1 ||
        !checkAttribut(ligneFacturation?.nbEtp) ||
        Math.sign(Number(ligneFacturation?.nbEtp)) === -1)) ||
      ((Number(ligneFacturation?.typeFacturation) === 7) &&
        (!checkAttribut(ligneFacturation?.productiviteActe) ||
          !checkAttribut(ligneFacturation?.prixUnitaireActe) ||
          !checkAttribut(ligneFacturation?.prixUnitaireHeure)))
    ) {
      return 'Not OK';
    } else {
      return 'Ok';
    }
  };

  const resetValueOfAllRequiredInput = () => {
    setErrorInput((prev: any) => ({
      ...prev,
      bu: false,
      typeFacturation: false,
      langueTrt: false,
      paysProduction: false,
      paysFacturation: false,
      familleOperation: false,
      operation: false,
      coutHoraire: false,
      horaireProduction: false,
      categorieLead: false,
      volumeATraite: false
    }));

    setErrorInputTypeFactActe((prev: any) => ({
      ...prev,
      tempsProductifs: false,
      tarifHoraireCible: false,
      dmt: false,
      volumeATraite: false,
    }));

    setErrorInputTypeFactMixte((prev: any) => ({
      ...prev,
      productiviteActe: false,
      prixUnitaireActe: false,
      prixUnitaireHeure: false
    }));

    setErrorInputTypeFactHeureAndForfait((prev: any) => ({
      ...prev,
      nbheureMensuel: false,
      nbEtp: false
    }));
  };

  const validationFront = () => {
    // Control champs par defaut
    if (statutBdcEnProduction?.includes(statutBdc)) {
      if (!checkAttribut(ligneFacturation?.paysProduction)) {
        setErrorInput((prev: any) => ({ ...prev, paysProduction: true }));
      }
      if (!checkAttribut(ligneFacturation?.paysFacturation)) {
        setErrorInput((prev: any) => ({ ...prev, paysFacturation: true }));
      }
    }
    if (!checkAttribut(ligneFacturation?.bu)) {
      setErrorInput((prev: any) => ({ ...prev, bu: true }));
    }
    if (!checkAttribut(ligneFacturation?.familleOperation)) {
      setErrorInput((prev: any) => ({ ...prev, familleOperation: true }));
    }
    if (!checkAttribut(ligneFacturation?.operation)) {
      setErrorInput((prev: any) => ({ ...prev, operation: true }));
    }
    if (!checkAttribut(ligneFacturation?.langueTrt)) {
      setErrorInput((prev: any) => ({ ...prev, langueTrt: true }));
    }
    if (!checkAttribut(ligneFacturation?.coutHoraire)) {
      setErrorInput((prev: any) => ({ ...prev, coutHoraire: true }));
    }
    if (!checkAttribut(ligneFacturation?.typeFacturation)) {
      setErrorInput((prev: any) => ({ ...prev, typeFacturation: true }));
    }
    if (!checkAttribut(ligneFacturation?.horaireProduction)) {
      setErrorInput((prev: any) => ({ ...prev, horaireProduction: true }));
    }
    if (!checkAttribut(ligneFacturation?.categorieLead)) {
      setErrorInput((prev: any) => ({ ...prev, categorieLead: true }));
    }

    if (Number(ligneFacturation?.typeFacturation) === 1) {
      if (!checkAttribut(ligneFacturation?.tempsProductifs)) {
        setErrorInputTypeFactActe((prev: any) => ({ ...prev, tempsProductifs: true }));
      }
    }

    // Control champs obligatoire pour le type de facturation acte et mixte
    if ([1, 7].includes(Number(ligneFacturation?.typeFacturation))) {
      if (!checkAttribut(ligneFacturation?.tarifHoraireCible)) {
        setErrorInputTypeFactActe((prev: any) => ({ ...prev, tarifHoraireCible: true }));
      }
      if (!checkAttribut(ligneFacturation?.volumeATraite)) {
        setErrorInputTypeFactActe((prev: any) => ({ ...prev, volumeATraite: true }));
      }
      if (Math.sign(Number(ligneFacturation?.volumeATraite)) === -1) {
        setErrorInputTypeFactActe((prev: any) => ({ ...prev, volumeATraite: true }));
        if (Math.sign(Number(ligneFacturation?.volumeATraite)) === -1) {
          toast.error('Désolé, veuillez entrer un nombre positif au champ volume à traiter !', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
          });
        }
      }
    }

    // Control champs obligatoire pour le type de facturation Heure et Forfait
    if ([3, 5, 7].includes(Number(ligneFacturation?.typeFacturation))) {
      if (!checkAttribut(ligneFacturation?.nbHeureMensuel)) {
        setErrorInputTypeFactHeureAndForfait((prev: any) => ({ ...prev, nbheureMensuel: true }));
      }
      if (!checkAttribut(ligneFacturation?.nbEtp)) {
        setErrorInputTypeFactHeureAndForfait((prev: any) => ({ ...prev, nbEtp: true }));
      }
      if (Math.sign(Number(ligneFacturation?.nbHeureMensuel)) === -1) {
        setErrorInputTypeFactHeureAndForfait((prev: any) => ({ ...prev, nbheureMensuel: true }));
        toast.error('Désolé, veuillez entrer un nombre positif au champ nombre d\'heure mensuel !', {
          position: 'top-right',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
      if (Math.sign(Number(ligneFacturation?.nbEtp)) === -1) {
        setErrorInputTypeFactHeureAndForfait((prev: any) => ({ ...prev, nbEtp: true }));
        toast.error('Désolé, veuillez entrer un nombre positif au champ nombre ETP !', {
          position: 'top-right',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    }

    // Control champs obligatoire pour le type de facturation mixte
    if (Number(ligneFacturation?.typeFacturation) === 7) {
      // Productivité Acte
      if (!checkAttribut(ligneFacturation?.productiviteActe)) {
        setErrorInputTypeFactMixte((prev: any) => ({ ...prev, productiviteActe: true }));
      }
      // Prix Acte
      if (!checkAttribut(ligneFacturation?.prixUnitaireActe)) {
        setErrorInputTypeFactMixte((prev: any) => ({ ...prev, prixUnitaireActe: true }));
      }
      // Prix Heure
      if (!checkAttribut(ligneFacturation?.prixUnitaireHeure)) {
        setErrorInputTypeFactMixte((prev: any) => ({ ...prev, prixUnitaireHeure: true }));
      }
    }
  };

  const handleSave = () => {
    // reinitialiser à false les valeurs des attributs qui controle les champs obligatoire
    resetValueOfAllRequiredInput();

    // Control de tout les champs obligatoire
    validationFront();

    // Si tout les champs obligatoire sont rempli alors, on enregistrer l'operation
    if (checkAllRequiredInput() === 'Ok') {
      onSave({
        ...ligneFacturation,
        isParamPerformed: 1,
        newOperation: 'ok',
        indicateurQt: indicatorQuant as any,
        indicateurQl: indicatorQual as any,
        dateDebutCross:
          ligneFacturation?.categorieLead === 'Cross'
            ? (moment(ligneFacturation?.dateDebutCross).format(
              'YYYY-MM-DD'
            ) as any)
            : null,
      });
    } else {
      if (Math.sign(Number(ligneFacturation?.volumeATraite)) !== -1 &&
        Math.sign(Number(ligneFacturation?.nbHeureMensuel)) !== -1 &&
        Math.sign(Number(ligneFacturation?.nbEtp)) !== -1) {
        toast.error('SVP, veuillez remplir tous les champs en rouge !', {
          position: 'top-right',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    }
  };

  const handleChangeIndicatorQt = (index, event) => {
    const values = [...indicatorQuant];
    /* eslint dot-notation: 2 */
    values[index].objectifQt = String(ligneFacturation?.objectifQuantitatif[index]);
    values[index][event.target.name] = event.target.value;

    setIndicatorQuant(values);
  };

  const handleChangeIndicatorQual = (index, event) => {
    const values = [...indicatorQual];
    /* eslint dot-notation: 2 */
    values[index].objectifQl = String(ligneFacturation?.objectifQualitatif[index]);
    values[index][event.target.name] = event.target.value;

    setIndicatorQual(values);
  };

  const getPaysProdLabel = (pays: number) => {
    return String(paysProduction.find((x) => x.id === pays)?.libelle);
  };

  const getbusLabel = (bu) => {
    return businessUnit.find((x) => x.id === Number(bu))?.libelle;
  };

  const getlangLabel = (lng) => {
    return langueTraitement.find((x) => x.id === Number(lng))?.libelle;
  };

  const checkPaysProdBuLangTrt = () => {
    if (
      checkAttribut(paysProdValue) &&
      checkAttribut(ligneFacturation?.langueTrt) &&
      checkAttribut(ligneFacturation?.bu)
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Retourne la valeur du langue de traitement
   * si l'un de cettes conditions est remplis
   */
  useEffect(() => {
    if (checkAttribut(ligneFacturation?.operation)) {
      let lngTrtVal: number;

      if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' FR'
        ) > -1
      ) {
        lngTrtVal = 1;
      } else if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' UK'
        ) > -1
      ) {
        lngTrtVal = 2;
      } else if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' ES'
        ) > -1
      ) {
        lngTrtVal = 3;
      } else if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' IT'
        ) > -1
      ) {
        lngTrtVal = 4;
      } else if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' DE'
        ) > -1
      ) {
        lngTrtVal = 5;
      } else if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' NL'
        ) > -1
      ) {
        lngTrtVal = 6;
      } else if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' PT'
        ) > -1
      ) {
        lngTrtVal = 7;
      } else if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' BR'
        ) > -1
      ) {
        lngTrtVal = 8;
      } else if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' MA'
        ) > -1
      ) {
        lngTrtVal = 9;
      } else if (
        getLabelTypeFacturationUpper(ligneFacturation?.operation)?.indexOf(
          ' MG'
        ) > -1
      ) {
        lngTrtVal = 10;
      }

      setLigneFacturation({
        ...ligneFacturation,
        langueTrt: lngTrtVal
      });
    }
  }, [ligneFacturation?.operation]);

  /**
   * Injection data index dans notre intreface LigneFacturation
   */
  useEffect(() => {
    // get ref objectif qualitatif
    axios
      .get(`${urlApi}bon/de/commande/ref/ObjectifQualitatif`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setObjectifQual(res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });

    // get ref objectif quantitatif
    axios
      .get(`${urlApi}bon/de/commande/ref/ObjectifQuantitatif`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setObjectQuantitatif(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, [ligneFacturation?.bu]);

  // get Refs
  useEffect(() => {
    // get ref cout horaire
    if (checkPaysProdBuLangTrt()) {
      const data = {
        paysProd: paysProdValue,
        lnguetrt: getlangLabel(ligneFacturation?.langueTrt),
        bu: getbusLabel(ligneFacturation?.bu),
      };

      axios
        .post(`${urlApi}get/profil/agent`, data, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          Array.isArray(res.data) && setCoutHoraire(res.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [
    logger,
    ligneFacturation?.paysProduction,
    ligneFacturation?.langueTrt,
    ligneFacturation?.bu,
    ligneFacturation?.operation,
  ]);

  const getLabelTypeFacturation = (id) => {
    return String(
      operations.find((z) => z.id === parseInt(id))?.libelle
    )?.toLowerCase();
  };

  const getLabelTypeFacturationUpper = (id) => {
    return String(operations.find((z) => z.id === parseInt(id))?.libelle);
  };

  /**
   * Retourne la valeur par defaut du profil agent
   */
  useEffect(() => {
    if (coutHoraire?.length === 1) {
      setLigneFacturation({
        ...ligneFacturation,
        coutHoraire: Number(coutHoraire[0]?.id)
      });
    }
  }, [coutHoraire]);

  let tarifHoraireCibleLabel: any = 'Tarif horaire cible';
  let volumeAtraiterLabel: any = 'Volume mensuel à traiter';

  if ([1, 7].includes(Number(ligneFacturation?.typeFacturation))) {
    const tarifHoraireTxt = Number(ligneFacturation?.typeFacturation) === 1 ? 'Tarif horaire cible' : 'Tarif horaire cible globale';
    const volumeMensuelTxt = Number(ligneFacturation?.typeFacturation) === 1 ? 'Volume mensuel à traiter' : 'Volume mensuel à traiter pour l\'acte';

    tarifHoraireCibleLabel = (
      <Fragment>
        <span>{tarifHoraireTxt}</span>
        <span style={{ color: 'red', fontSize: 20 }}> *</span>
      </Fragment>
    );

    volumeAtraiterLabel = (
      <Fragment>
        <span>{volumeMensuelTxt}</span>
        <span style={{ color: 'red', fontSize: 20 }}> *</span>
      </Fragment>
    );
  }

  const productiviteActeLabel = (
    <Fragment>
      <span>Productivité acte</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const prixUnitAHeureLabel = (
    <Fragment>
      <span>Prix unitaire à l’heure</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const tempProductifLabel = (
    <Fragment>
      <span>Temps productifs</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const dmtLabel =
    ligneFacturation?.typeFacturation === 1 ? (
      <Fragment>
        <span>Durée moyen traitement</span>
        <span style={{ color: 'red', fontSize: 20 }}> *</span>
      </Fragment>
    ) : (
      'Durée moyen traitement'
    );

  const prixUnitaire = (
    <Fragment>
      <span>Prix unitaire</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  /**
   * Logique page loading...........................
   */
  const progress = (
    <div className={classes.loading}>
      <LinearProgress color="secondary" />
    </div>
  );

  const nbheureMensuelLabel = (
    <Fragment>
      <span>Nombre d&apos;heure mensuel à facturé par ETP</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  const nbEtpLabel = (
    <Fragment>
      <span>Nombre ETP</span>
      <span style={{ color: 'red', fontSize: 20 }}> *</span>
    </Fragment>
  );

  /**
   * Logique show message validarion forms........................
   */
  const messageValidation =
    // ligneFacturation?.paysProduction == null ||
    // ligneFacturation?.paysFacturation == null ||
    ligneFacturation?.bu === 0 ||
      ligneFacturation?.familleOperation == null ||
      // saveLeadDetailOperation?.operation == null ||
      ligneFacturation?.langueTrt == null ||
      ligneFacturation?.volumeATraite == null ||
      ligneFacturation?.horaireProduction == null ||
      ligneFacturation?.categorieLead == null ||
      ligneFacturation?.typeFacturation === 0 ? (
      <Alert variant="filled" severity="error">
        Attention tous les champs en <b>*</b> rouge sont obligatoire, merci de
        remplir SVP !
      </Alert>
    ) : null;

  const nbEtpInput = (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        id="outlined-number"
        name="nbEtp"
        label={nbEtpLabel}
        error={errorInputTypeFactHeureAndForfait?.nbEtp}
        type="number"
        value={ligneFacturation?.nbEtp}
        placeholder="0.00"
        onChange={handleChange}
        InputLabelProps={{
          shrink: ligneFacturation?.nbEtp && true,
        }}
        variant="outlined"
      />
    </FormControl>
  );

  const nbHeureMensuelInput = (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        name="nbHeureMensuel"
        label={nbheureMensuelLabel}
        type="number"
        error={errorInputTypeFactHeureAndForfait?.nbHeureMensuel}
        value={ligneFacturation?.nbHeureMensuel}
        placeholder="0.00"
        onChange={handleChange}
        InputLabelProps={{
          shrink: ligneFacturation?.nbHeureMensuel && true,
        }}
        variant="outlined"
      />
    </FormControl>
  );

  const volumeATraiteInput = (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        name="volumeATraite"
        type="number"
        label={volumeAtraiterLabel}
        value={ligneFacturation?.volumeATraite}
        error={errorInputTypeFactActe?.volumeATraite}
        placeholder="0.00"
        onChange={handleChange}
        InputProps={{
          readOnly: Number(ligneFacturation?.typeFacturation) === 7 && true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: ligneFacturation?.volumeATraite && true
        }}
      />
    </FormControl>
  );

  const nbHeureAndVolumeMensuelInput = () => {
    switch (Number(ligneFacturation?.typeFacturation)) {
      case 3:
      case 5:
        return (
          <Fragment>
            {nbEtpInput}
            {nbHeureMensuelInput}
          </Fragment>
        );
      case 7:
        return (
          <Fragment>
            {nbEtpInput}
            {nbHeureMensuelInput}
            {volumeATraiteInput}
          </Fragment>
        );
      default:
        return volumeATraiteInput;
    }
  };

  const objectifInput = () => {
    return (
      <Fragment>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="profil-contact">
            Objectifs quantitatifs
          </InputLabel>
          <Select
            open={openSelectMultiple}
            onClick={() => handleCloseSelectMulti()}
            multiple
            displayEmpty
            label="Objectifs quantitatifs"
            inputProps={{
              name: 'objectifQuantitatif',
            }}
            value={ligneFacturation?.objectifQuantitatif || []}
            onChange={handleChangeSelectMultitple}
            renderValue={(selected) => {
              return (
                <div className={classes.chips}>
                  {((selected as string[]) || []).map((value) => (
                    <Chip
                      key={value}
                      label={
                        (objectifQuantitatif || []).find(
                          (item) => item.id === parseInt(value)
                        )?.libelle
                      }
                      className={classes.chip}
                    // onClick={(e) => handleChickChips(value)}
                    />
                  ))}
                </div>
              );
            }}
          >
            <MenuItem disabled value="">
              <em>Objectifs quantitatifs</em>
            </MenuItem>
            {(objectifQuantitatif || []).map((item, key) => (
              <MenuItem key={key} value={item.id}>
                {item.libelle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {(ligneFacturation?.objectifQuantitatif || []).map((quantitatif, index) => (
          <FormControl variant="outlined" className={classes.formControl} key={quantitatif}>
            <TextField
              name="indicator"
              label={`Indicateur pour ${objectifQuantitatif.find((x) => x.id === quantitatif)?.libelle}`}
              value={indicatorQuant[index].indicator}
              onChange={(e) => handleChangeIndicatorQt(index, e)}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </FormControl>
        ))}
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="profil-contact">
            Objectifs qualitatifs
          </InputLabel>
          <Select
            open={openSelectMultiple2}
            onClick={() => handleCloseSelectMulti2()}
            multiple
            displayEmpty
            value={ligneFacturation?.objectifQualitatif || []}
            onChange={handleChangeSelectMultitple2}
            renderValue={(selected) => {
              return (
                <div className={classes.chips}>
                  {((selected as string[]) || []).map((value) => (
                    <Chip
                      key={value}
                      label={
                        (objectifQual || []).find(
                          (item) => item.id === parseInt(value)
                        )?.libelle
                      }
                      className={classes.chip}
                    />
                  ))}
                </div>
              );
            }}
            label="Objectifs qualitatifs"
            inputProps={{
              name: 'objectifQualitatif',
            }}
          >
            <MenuItem disabled value="">
              <em>Objectifs qualitatifs</em>
            </MenuItem>
            {(objectifQual || []).map((item, key) => (
              <MenuItem key={key} value={item.id}>
                {item.libelle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {(ligneFacturation?.objectifQualitatif || []).map((qualitatif, index) => (
          <FormControl variant="outlined" className={classes.formControl} key={qualitatif}>
            <TextField
              name="indicator"
              label={`Indicateur pour ${objectifQual.find((z) => z.id === qualitatif)?.libelle}`}
              value={indicatorQual[index].indicator}
              onChange={(event) => handleChangeIndicatorQual(index, event)}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </FormControl>
        ))}
      </Fragment>
    );
  };

  const designationActeInput = (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="operation" shrink={ligneFacturation?.designationActe && true}>
        Désignation acte <span style={{ color: 'red', fontSize: 20 }}>*</span>
      </InputLabel>
      <Select
        value={ligneFacturation?.designationActe}
        onChange={handleChange}
        label="Désignation acte"
        // error={errorInput?.operation}
        inputProps={{
          name: 'designationActe',
        }}
        native
      >
        <option value=""></option>
        {operationsActe.map((item) => (
          <option key={item.id} value={item.id}>
            {item.libelle}
          </option>
        ))}
      </Select>
    </FormControl>
  );

  const tarifHoraiCibleInput = () => {
    if (showTarifHoraireCibleByTyFactArray?.includes(Number(ligneFacturation?.typeFacturation))) {
      return (
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            id="outlined-number"
            name="tarifHoraireCible"
            fullWidth
            error={errorInputTypeFactActe?.tarifHoraireCible}
            label={tarifHoraireCibleLabel}
            type="number"
            value={ligneFacturation?.tarifHoraireCible}
            placeholder="0.00"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="CA/H cible">
                    <HelpOutlineIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: ligneFacturation?.tarifHoraireCible && true,
            }}
            variant="outlined"
          />
        </FormControl>
      );
    }
  };

  const tempProductifInput = (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        label={tempProductifLabel}
        type="time"
        fullWidth
        name="tempsProductifs"
        error={errorInputTypeFactActe?.tempsProductifs}
        value={ligneFacturation?.tempsProductifs || null}
        onChange={handleChange}
        defaultValue="00:00:00"
        InputLabelProps={{
          shrink: ligneFacturation?.tempsProductifs && true,
        }}
        inputProps={{
          step: 2,
        }}
      />
    </FormControl>
  );

  const productiviteInput = (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        label={productiviteActeLabel}
        name="productiviteActe"
        type="number"
        fullWidth
        error={errorInputTypeFactMixte?.productiviteActe}
        value={ligneFacturation?.productiviteActe}
        onChange={handleChange}
        InputLabelProps={{
          shrink: (ligneFacturation?.productiviteActe ||
            ligneFacturation?.productiviteActe === 0) && true,
        }}
        inputProps={{
          step: 0.05
        }}
        variant="outlined"
      />
    </FormControl>
  );

  const prixUnitaireHeureInput = (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        label={prixUnitAHeureLabel}
        name="prixUnitaireHeure"
        type="number"
        fullWidth
        error={errorInputTypeFactMixte?.prixUnitaireHeure}
        value={ligneFacturation?.prixUnitaireHeure}
        onChange={handleChange}
        InputLabelProps={{
          shrink: ligneFacturation?.prixUnitaireHeure && true,
        }}
        variant="outlined"
      />
    </FormControl>
  );

  const prixUnitaireActe = (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        label='Prix unitaire à l’acte'
        name="prixUnitaireActe"
        type="number"
        fullWidth
        error={errorInputTypeFactMixte?.prixUnitaireActe}
        value={ligneFacturation?.prixUnitaireActe}
        onChange={handleChange}
        InputLabelProps={{
          shrink: ligneFacturation?.prixUnitaireActe && true,
        }}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    </FormControl>
  );

  const dureeMoyenTraitement = () => {
    if (tabIdOperationForDmtInput.includes(ligneFacturation?.operation)) {
      return (
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            label={dmtLabel}
            name="dmt"
            fullWidth
            type="time"
            value={ligneFacturation?.dmt || null}
            onChange={handleChange}
            defaultValue="00:00:00"
            InputLabelProps={{
              shrink: ligneFacturation?.dmt && true,
            }}
            inputProps={{
              step: 2,
              readOnly: true,
            }}
          />
        </FormControl>
      );
    } else {
      return (
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            label={dmtLabel}
            name="dmt"
            type="time"
            value={ligneFacturation?.dmt || null}
            onChange={handleChange}
            defaultValue="00:00:00"
            InputLabelProps={{
              shrink: ligneFacturation?.dmt && true,
            }}
            inputProps={{
              step: 2,
            }}
          />
        </FormControl>
      );
    }
  };

  const prixUnitInput = (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        label={Number(ligneFacturation?.operation) === 1006 ? prixUnitaire : 'Prix unitaire'}
        name="prixUnit"
        type="number"
        value={ligneFacturation?.prixUnit}
        placeholder="0.00"
        onChange={handleChange}
        InputLabelProps={{
          shrink: ligneFacturation?.typeFacturation && true,
        }}
        InputProps={{
          readOnly: Number(ligneFacturation?.typeFacturation) === 1 && true,
        }}
        variant="outlined"
      />
    </FormControl>
  );

  const profilAgentInput = () => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-coutHoraire-always-notched" shrink={ligneFacturation?.coutHoraire && true}>
          Profil agent <span style={{ color: 'red', fontSize: 20 }}>*</span>
        </InputLabel>
        <Select
          native
          label="Profil agent"
          value={ligneFacturation?.coutHoraire}
          error={errorInput?.coutHoraire}
          onChange={handleChange}
          inputProps={{
            name: 'coutHoraire',
          }}
        >
          <option value=""></option>
          {coutHoraire.map((item) => (
            <option key={item.id} value={item.id}>
              {item.pays} - {item.bu} - {item.langueSpecialite} - {item.coutHoraire}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  };

  /**
   * Varibale pour forms lead detail operation
   */
  const formDetailOperation = (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {
            statutBdcEnProduction?.includes(statutBdc) && (
              <Fragment>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="paysProduction">
                    Pays de production{' '}
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  </InputLabel>
                  <Select
                    native
                    required
                    error={errorInput?.paysProduction}
                    label="Pays de production"
                    value={ligneFacturation?.paysProduction}
                    onChange={handleChange}
                    inputProps={{
                      name: 'paysProduction',
                      id: 'paysProduction',
                    }}
                  >
                    <option value=""></option>
                    {paysProduction.map((item, key) => (
                      <option key={key} value={item.id}>
                        {item.libelle}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="paysFacturation">
                    Pays de facturation{' '}
                    <span style={{ color: 'red', fontSize: 20 }}>*</span>
                  </InputLabel>
                  <Select
                    native
                    error={errorInput?.paysFacturation}
                    label="Pays de facturation"
                    value={ligneFacturation?.paysFacturation}
                    onChange={handleChange}
                    inputProps={{
                      name: 'paysFacturation',
                    }}
                  >
                    <option value=""></option>
                    {paysFacturation.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.libelle}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Fragment>
            )
          }

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="bu">
              Business unit{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              value={ligneFacturation?.bu}
              onChange={handleChange}
              error={errorInput?.bu}
              label="Business unit"
              inputProps={{
                name: 'bu',
              }}
              native
            >
              <option value=""></option>
              {businessUnit.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.libelle}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-always-notched-1">
              Famille opération{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              value={ligneFacturation?.familleOperation}
              label="Famille opération"
              onChange={handleChange}
              error={errorInput?.familleOperation}
              inputProps={{
                name: 'familleOperation',
              }}
              native
            >
              <option value=""></option>
              {familyOeration?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.libelle}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="operation" shrink={ligneFacturation?.operation && true}>
              Opération <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              value={ligneFacturation?.operation}
              onChange={handleChange}
              label="Opération"
              error={errorInput?.operation}
              inputProps={{
                name: 'operation',
              }}
              native
            >
              <option value=""></option>
              {operations.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.libelle}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="langueTrt" shrink={ligneFacturation?.langueTrt && true}>
              Langue de traitement{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              value={ligneFacturation?.langueTrt}
              label=" Langue de traitement"
              error={errorInput?.langueTrt}
              onChange={handleChange}
              inputProps={{
                name: 'langueTrt',
              }}
              native
            >
              <option value=""></option>
              {langueTraitement.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.libelle}
                </option>
              ))}
            </Select>
          </FormControl>

          {profilAgentInput()}

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="typeFacturation" shrink={ligneFacturation?.typeFacturation && true}>
              Type de facturation{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              label="Type de facturation"
              value={ligneFacturation?.typeFacturation}
              error={errorInput?.typeFacturation}
              onChange={handleChange}
              inputProps={{
                name: 'typeFacturation',
              }}
              native
            >
              <option value=""></option>
              {typeFacturation.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.libelle}
                </option>
              ))}
            </Select>
          </FormControl>

          {Number(ligneFacturation?.typeFacturation) === 7 && designationActeInput}

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="horaireProduction">
              Horaire de production{' '}
              <span style={{ color: 'red', fontSize: 20 }}>*</span>
            </InputLabel>
            <Select
              value={ligneFacturation?.horaireProduction}
              label=" Horaire de production"
              error={errorInput?.horaireProduction}
              onChange={handleChange}
              inputProps={{
                name: 'horaireProduction',
              }}
              native
            >
              <option value=""></option>
              {horaireProduction.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.libelle}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="categorieLead">
              Catégorie lead{' '}
              <span style={{ color: 'red', fontSize: 20 }}> * </span>
            </InputLabel>
            <Select
              native
              required
              label="Catégorie lead"
              value={ligneFacturation?.categorieLead}
              error={errorInput?.categorieLead}
              onChange={handleChange}
              inputProps={{
                name: 'categorieLead',
                id: 'categorieLead',
              }}
            >
              <option aria-label="None" value="" />
              {categoryClients?.map((item) => (
                <option key={item?.id} value={item?.libelle}>{item?.libelle}</option>
              ))}
            </Select>
          </FormControl>

          {ligneFacturation?.categorieLead === 'Cross' && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  fullWidth
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date de début"
                  value={ligneFacturation?.dateDebutCross}
                  onChange={(date: Date) =>
                    handleDateChange(date, 'dateDebutCross')
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        </Grid>

        <Grid item xs={6}>
          {tarifHoraiCibleInput()}

          {Number(ligneFacturation?.typeFacturation) === 1 && tempProductifInput}

          {Number(ligneFacturation?.typeFacturation) === 1 && dureeMoyenTraitement()}

          {Number(ligneFacturation?.typeFacturation) === 7 && productiviteInput}

          {nbHeureAndVolumeMensuelInput()}

          {Number(ligneFacturation?.typeFacturation) === 7 && prixUnitaireHeureInput}

          {Number(ligneFacturation?.typeFacturation) === 7 && prixUnitaireActe}

          {Number(ligneFacturation?.typeFacturation) !== 7 && prixUnitInput}

          {objectifInput()}

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="hno">Ajout HNO</InputLabel>
            <Select
              native
              label="Ajout HNO"
              value={ligneFacturation?.hno}
              onChange={handleChange}
              inputProps={{
                name: 'hno',
              }}
            >
              <option aria-label="None" value="" />
              <option value="Oui">Oui</option>
              <option value="Non">Non</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Button color="primary" size="large" variant="contained" onClick={handleSave}>
          Enregistrer
        </Button>
      </Box>{' '}
      <br />
      <div>{messageValidation}</div>
    </Box>
  );

  const loader = loading ? progress : formDetailOperation;

  return <div>{loader}</div>;
};

export default LigneFacturationForm;
