import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    root2: {
      width: '100%',
      paddingTop: '1px',
      paddingRight: '40px',
      paddingLeft: '40px',
    },
    formControl: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    TextField: {
      width: '100%',
    },
    warning: {
      color: 'red',
      display: 'flex',
      alignItems: 'center',
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    titleContactsContainer: {
      display: 'flex',
      alignItems: 'center',
      '& h3': {
        marginRight: theme.spacing(3),
      },
      flexWrap: 'wrap',
    },
    loading: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      }
    },
    table: {},
    actionBtn: {
      cursor: 'pointer',
    },
  })
);
