import { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import {
  Box,
  Paper,
  Pagination,
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Typography,
  Grid,
  LinearProgress,
  Autocomplete,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import ImportCoutAgent from './ImportCoutAgent';
import { Reducers } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import axios from 'axios';
import dateFormat from 'dateformat';
import { Button, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

// On declare ici notre variable constant
const urlApi = process.env.REACT_APP_BACKEND_URL;
toast.configure();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#848484',
    color: theme.palette.common.white,
    fontSize: 11,
    borderStyle: 'none',
    paddingBottom: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const UnderTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#848484',
    color: theme.palette.common.white,
    fontSize: 11,
    paddingTop: 0,
  },
}));

export interface CoutAgent {
  id?: number;
  dateDebut: string;
  dateFin: string;
  pays: string;
  bu: string;
  niveau: string;
  langueSpecialite: string;
  coutHoraire: string;
  coutFormation: string;
}

const ListeCoutAgent = () => {
  const logger = useSelector<Reducers>((state) => state.logger);
  const [coutAgent, setCoutAgent] = useState<CoutAgent[]>([]);
  const [dataEdit, setDataEdit] = useState<CoutAgent>();
  const [rowsParPage, setRowsParPage] = useState<number>(5);
  const [page, setPage] = useState<number>(1);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClickSave, setIsClickSave] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [refresh, setRefresh] = useState<boolean>(false);
  const [paysSearch, setPaysSearch] = useState<string>('');
  const [buSearch, setbuSearch] = useState<string>('');

  // Message d'alerte (Toaster message)
  const showToastSuccess = () => {
    toast.success('Mise à jour effectée avec succès', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  useEffect(() => {
    // Activer un loader dans notre page
    setIsLoading(true);

    // Recevoir tous les couts agents de l'année en cours via l'API
    axios
      .get(`${urlApi}cout/horaire`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        // Desactiver un loader dans notre page
        setIsLoading(false);

        if (res?.data?.length) {
          // Si la reponse n'est pas vide, on rentre ici
          setCoutAgent(
            (Array.isArray(res.data) && res.data)?.map((index) => {
              return {
                ...index,
                dateDebut: index?.date_debut ? index?.date_debut : undefined,
                dateFin: index?.date_fin ? index?.date_fin : undefined,
                langueSpecialite: index?.langue_specialite
                  ? index?.langue_specialite
                  : undefined,
                coutHoraire: index?.cout_horaire
                  ? index?.cout_horaire
                  : undefined,
                coutFormation: index?.cout_formation
                  ? index?.cout_formation
                  : undefined,
              } as CoutAgent;
            })
          );
        } else {
          // Si non, vide
          setIsEmpty(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const showDataTable = () => {
    const dataTab = coutAgent
      .filter((row) => {
        if (paysSearch === '' || paysSearch === null) {
          return row;
        } else {
          return row?.pays === paysSearch;
        }
      })
      .filter((row) => {
        if (buSearch === '' || buSearch === null) {
          return row;
        } else {
          return row?.bu === buSearch;
        }
      });
    return dataTab;
  };

  const SuggestionForInputSelect = () => {
    const arrayPays = [];
    const arrayBu = [];
    for (const cout of coutAgent) {
      if (!arrayPays.includes(cout?.pays)) {
        arrayPays.push(cout?.pays);
      }
      if (!arrayBu.includes(cout?.bu)) {
        arrayBu.push(cout?.bu);
      }
    }
    return [arrayPays, arrayBu];
  };

  // Fonction qui retourne le nombre de pages disponibles
  const nbpagination = () => {
    return showDataTable().length;
  };

  // Fonction qui calcule le nombre de pages
  const calculatedNumberPage = () => {
    const indexOfLastPost = page * rowsParPage;

    const indexOfFirstPost = indexOfLastPost - rowsParPage;

    return showDataTable().slice(indexOfFirstPost, indexOfLastPost);
  };

  const handleChangeInput = (e: any, row: any) => {
    const { name, value } = e.target;
    setDataEdit({
      ...dataEdit,
      id: Number(row?.id),
      [name]: value,
    });
  };

  // Fonction qui mit à jour un agent selectionné
  const editCoutAgent = () => {
    if (dataEdit?.id) {
      // Petit loader dans notre boton (Activer)
      setIsClickSave(true);
      axios
        .put(`${urlApi}edit/cout/horaire/${dataEdit?.id}`, dataEdit, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((res) => {
          showToastSuccess();

          // Petit loader dans notre boton (Desactiver)
          setIsClickSave(false);

          // Renitialiser le data posté en vide
          setDataEdit({
            ...dataEdit,
            id: null,
            coutHoraire: null,
            coutFormation: null,
          });

          // Chargement component
          window?.location?.reload();
          // setRefresh(!refresh);
        })
        .catch((error) => {
          console.log(error.message);
          showMessageToastError();

          // Petit loader dans notre boton (Desactiver)
          setIsClickSave(false);
        });
    }
  };

  // Notre rendu JSX
  return (
    <Container maxWidth="xl">
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isClickSave}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <ImportCoutAgent /> <br /> <br />
      <Divider />
      <Box my={2} style={{ color: '#e75113' }}>
        <Typography variant="h4" style={{ position: 'relative' }}>
          <div style={{ textShadow: '#828181 0.05em 0.05em 0.05em' }}>
            Liste des coûts annuels
          </div>
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Box mt={1} mb={2}>
            <Stack direction="row" spacing={2}>
              <Stack direction="row" spacing={2} sx={{ marginTop: '5px' }}>
                <InputLabel sx={{ marginTop: '4px' }}>
                  Lignes par page :
                </InputLabel>
                <FormControl variant="standard" sx={{ minWidth: 40 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    color="warning"
                    value={rowsParPage}
                    onChange={(event) =>
                      setRowsParPage(event.target.value as number)
                    }
                    defaultValue={5}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              {nbpagination() > rowsParPage && (
                <Pagination
                  count={Math.ceil(nbpagination() / rowsParPage)}
                  color="standard"
                  showFirstButton={true}
                  showLastButton={true}
                  hideNextButton={true}
                  hidePrevButton={true}
                  defaultPage={page}
                  onChange={(event, value) => setPage(value)}
                />
              )}
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">DATE DE DEBUT</StyledTableCell>
                <StyledTableCell align="center">DETE DE FIN</StyledTableCell>
                <StyledTableCell align="center">PAYS</StyledTableCell>
                <StyledTableCell align="center">BU</StyledTableCell>
                <StyledTableCell align="center">NIVEAUX</StyledTableCell>
                <StyledTableCell align="center">
                  LANGUES / PROFILS SPE
                </StyledTableCell>
                <StyledTableCell align="center">COUTS HORAIRE</StyledTableCell>
                <StyledTableCell align="center">
                  COUTS FORMATION
                </StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
              <TableRow>
                <UnderTableCell />
                <UnderTableCell />
                <UnderTableCell>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      setPaysSearch(String(newValue));
                    }}
                    id="combo-box-demo"
                    options={SuggestionForInputSelect()[0]}
                    size="small"
                    noOptionsText="Non trouvé"
                    style={{
                      backgroundColor: 'white',
                      width: 120,
                      borderRadius: '4px',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Rechercher ..."
                        color="primary"
                      />
                    )}
                  />
                </UnderTableCell>
                <UnderTableCell>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      setbuSearch(String(newValue));
                    }}
                    id="combo-box-demo"
                    options={SuggestionForInputSelect()[1]}
                    size="small"
                    noOptionsText="Non trouvé"
                    style={{
                      backgroundColor: 'white',
                      width: 120,
                      borderRadius: '4px',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Rechercher ..."
                        color="primary"
                      />
                    )}
                  />
                </UnderTableCell>
                <UnderTableCell></UnderTableCell>
                <UnderTableCell></UnderTableCell>
                <UnderTableCell></UnderTableCell>
                <UnderTableCell></UnderTableCell>
                <UnderTableCell></UnderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <StyledTableCell align="center" colSpan={9}>
                    <Box my={2}>
                      <LinearProgress color="warning" />
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ) : isEmpty ? (
                <TableRow>
                  <StyledTableCell align="center" colSpan={9}>
                    Auccun resultat a été trouvé.
                  </StyledTableCell>
                </TableRow>
              ) : (
                (calculatedNumberPage() || [])?.map((row) => (
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell align="center">
                      {dateFormat(row?.dateDebut, 'dd/mm/yyyy')}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dateFormat(row?.dateFin, 'dd/mm/yyyy')}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.pays}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row?.bu}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.niveau}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.langueSpecialite}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TextField
                        name="coutHoraire"
                        type="number"
                        value={row[dataEdit?.coutHoraire]}
                        defaultValue={row?.coutHoraire || ''}
                        onChange={(e) => handleChangeInput(e, row)}
                        placeholder="Couts horaire..."
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TextField
                        name="coutFormation"
                        type="number"
                        value={row[dataEdit?.coutFormation]}
                        defaultValue={row?.coutFormation || ''}
                        onChange={(e) => handleChangeInput(e, row)}
                        placeholder="Couts formation..."
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={editCoutAgent}
                      >
                        Enregistrer
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {
        <Box mt={4} mb={10} mr={3}>
          <Stack direction="row" sx={{ position: 'relative' }}>
            {!isEmpty && (
              <Stack
                direction="row"
                spacing={6}
                sx={{ position: 'absolute', right: '2%' }}
              >
                {nbpagination() > rowsParPage && (
                  <Pagination
                    count={Math.ceil(nbpagination() / rowsParPage)}
                    color="standard"
                    showFirstButton={true}
                    showLastButton={true}
                    hideNextButton={true}
                    hidePrevButton={true}
                    defaultPage={page}
                    onChange={(event, value) => setPage(value)}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Box>
      }
    </Container>
  );
};

export default ListeCoutAgent;
