// import React from 'react'
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { Fragment, useState, useEffect } from 'react';
import ContactsIcon from '@mui/icons-material/Contacts';
import ListIcon from '@mui/icons-material/List';
import { useHistory, useLocation } from 'react-router';
import { useStyles } from './styles';
import { Stack } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../redux/reducers';
import TableNumeroBdc from './TableNumeroBdc';
import TableCummulAnnuel from './TableCummulAnnuel';
import TableEvolution from './TableEvolution';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import { toast } from 'react-toastify';
// import { customer } from '../../forms/resume-lead-form/ResumeLeadForm';

// toast.configure();
const urlApi = process.env.REACT_APP_BACKEND_URL;

export interface Ref {
  id?: number;
  libelle: string;
}

export interface TabObjectifQualitatif {
  id: number;
  libelle: string;
}

export interface TabOjectifQuantitatif {
  id: number;
  libelle: string;
}

export interface TabBdcOperation {
  id: number;
  quantite: string;
  prixUnit: string;
  tarifHoraireCible: string;
  tempsProductifs: string;
  dmt: string;
  operation: {
    id: number;
    libelle: string;
  };
  coutHoraire: {
    id: number;
    coutHoraire: string;
  };
  objectifQualitatif: TabObjectifQualitatif[];
  objectifQuantitatif: TabOjectifQuantitatif[];
  bu: {
    id: number;
    libelle: string;
  };
  typeFacturation: {
    id: number;
    libelle: string;
  };
}

export interface TabBdc {
  id: number;
  dateModification: Date;
  dateSignature: Date;
  modeReglement: string;
  bdcOperations: TabBdcOperation[];
  paysProduction: {
    id: number;
    libelle: string;
  };
  devise: {
    id: number;
    libelle: string;
  };
}

export interface TabContact {
  id?: number;
  civilite: string;
  nom: string;
  prenom: string;
  fonction: string;
  tel: string;
  email: string;
  skype: string;
}

export interface TabLeadDetailOperation {
  id: number;
  typeFacturation: {
    id: number;
    libelle: string;
  };
  langueTrt: {
    id: number;
    libelle: string;
  };
  paysProduction: {
    id: number;
    libelle: string;
  };
  operation: {
    id: number;
    libelle: string;
  };
  bu: {
    id: number;
    libelle: string;
  };
}

export interface Data {
  id: number;
  pays: string;
  caAnnuelNplusUn: string;
}

export interface DataBudgetAnnuel {
  sommeCaAnnuelNplusUn: number;
  data: Data[];
}

export interface DataBudgetMmoinsUn {
  caOctobre: string;
  pays: string;
}

export interface FormDataFicheClient {
  id?: number;
  rc: string;
  // naturePrestation: number;
  activiteContexte: string;
  gestionnaireDeCompte: string;
  rapportActivite: string;
  statutContrat: string;
  taciteReconduction: string;
  versementAcompte: string;
  typeProfil: string;
  niveau: string;
  langueTrt: string;
  formation: string;
  dateDemarragePartenariat: string;
  dateContrat: Date;
  dimensionnement: string;
  dureeAnciennete: string;
  budgetDeMiseEnPlace: string;
  budgetFormation: string;
  budgetAnnuel: string;
  budgetMoyenMensuel: string;
  budgetM1: string; // Budget M-1....
  moyenneQualite: string;
  moyenneSatcli: string;
  confSpecifique: string;
  specificiteContractuelles: string;
  customer?: number; // champ obligatoire.....
  forfaitPilotage: string;
  chiffreAffaireRealise: string;
  caM1: string; // chiffre d'affaire du M-1.....
  raisonSocial: string;
  marqueCommercial: string;
  adresse: string;
  tel: string;
  outils: string;
  dureeContrat: string;
  mappingOutsourcia: string;
  modalitePaiement: string;
  detailPrixVente: string;
  registreTraitement: string;
  annexeContrat: string;
  commercial: string;
  mappingClient: {
    id: number;
    libelle: string;
  };
  contacts: TabContact[];
  user: {
    id: number;
    email: string;
    username: string;
  };
  resumeLeads: [
    {
      id: number;
      potentielCA: string;
      dureeTrt: {
        id: number;
        libelle: string;
      };
      leadDetailOperations: TabLeadDetailOperation[];
      bdcs: TabBdc[];
    }
  ];
}

const FicheClient = () => {
  const history = useHistory();
  const logger = useSelector<Reducers>((state) => state.logger);
  const location = useLocation();
  const classes = useStyles();
  const [color, setColor] = useState('');
  const [ficheClient, setFicheClient] = useState<FormDataFicheClient>();
  const [customer, setCustomer] = useState<FormDataFicheClient>();
  // eslint-disable-next-line no-unused-vars
  const [naturePrest, setNaturePrest] = useState<Ref[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [bA, setBA] = useState<DataBudgetAnnuel>();
  // eslint-disable-next-line no-unused-vars
  const [budgetMmoinsUn, setBudgetMmoinsUn] = useState<DataBudgetMmoinsUn[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [inputError, setInputError] = useState({
    gestionnaireDeCompte: false,
    versementAcompte: false,
    typeProfil: false,
    outils: false,
    confSpecifique: false,
  });

  // const [showBudgetAnnuel, setShowBudgetAnnuel] = useState(false);

  const loaderStyle = (
    <div className={classes.loading}>
      <LinearProgress />
      {/* <LinearProgress color="secondary" /> */}
    </div>
  );

  /* let somme = 0;
  budgetMmoinsUn.forEach(function (element) {
    somme += +element?.caOctobre;
  }); */

  /* const budgetAnnuel = {
    pays1: customer?.resumeLeads
      ?.map((x) => x?.bdcs[0]?.paysProduction?.libelle)
      ?.toString(),
    pays2: customer?.resumeLeads
      ?.map((x) => x?.bdcs[1]?.paysProduction?.id)
      ?.toString(),
    pays3: customer?.resumeLeads
      ?.map((x) => x?.bdcs[2]?.paysProduction?.id)
      ?.toString(),
    pays4: customer?.resumeLeads
      ?.map((x) => x?.bdcs[3]?.paysProduction?.id)
      ?.toString(),
  };
 */
  const styles = {
    backgroundColor: color,
  };

  const listSideBar = [
    {
      text: 'Créer client',
      icon: <ContactsIcon />,
    },
    {
      text: 'Liste clients',
      icon: <ListIcon />,
    },
  ];

  /* const message =
    ficheClient?.id !== undefined
      ? 'Mise à jour a été effectué avec succès.'
      : 'L&#39;enregistrement a été effectué avec succès.';
  const showMessageToastSuccess = () => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  }; */

  /* const showMessageToastError = () => {
    toast.error("Une erreure s'est produite, merci de ressayer.", {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  }; */

  const handleChangeInput = (e) => {
    setFicheClient({
      ...ficheClient,
      [e.target.name]: e.target.value,
      customer: +location.state,
    });
  };

  const handleSaveUpdateFicheClient = () => {
    if (ficheClient?.id === undefined) {
      if (
        ficheClient &&
        ficheClient?.gestionnaireDeCompte?.length &&
        ficheClient?.versementAcompte?.length &&
        ficheClient?.typeProfil?.length &&
        ficheClient?.outils?.length &&
        ficheClient?.confSpecifique?.length
      ) {
        axios
          .post(`${urlApi}save/fiche/client`, ficheClient, {
            headers: {
              Authorization: `Bearer ${(logger as any)?.token}`,
            },
          })
          .then((res) => {
            history.push('contact_list');
          })
          .catch((e) => {
            console.log(e.message);
          });
      } else {
        setInputError({
          ...inputError,
          gestionnaireDeCompte: true,
          versementAcompte: true,
          typeProfil: true,
          outils: true,
          confSpecifique: true,
        });
      }
    } else {
      if (
        ficheClient &&
        ficheClient?.gestionnaireDeCompte?.length &&
        ficheClient?.versementAcompte?.length &&
        ficheClient?.typeProfil?.length &&
        ficheClient?.outils?.length &&
        ficheClient?.confSpecifique?.length
      ) {
        axios
          .put(`${urlApi}edit/fiche/client/${ficheClient?.id}`, ficheClient, {
            headers: {
              Authorization: `Bearer ${(logger as any)?.token}`,
            },
          })
          .then((res) => {
            history.push('contact_list');
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        setInputError({
          ...inputError,
          gestionnaireDeCompte: true,
          versementAcompte: true,
          typeProfil: true,
          outils: true,
          confSpecifique: true,
        });
      }
    }
  };

  const redirectToListContact = () => {
    history.push('contact_list');
  };

  const handleExportPdf = () => {
    history.push({
      pathname: '/export_pdf_fiche_client',
      state: location.state,
    });
  };

  // Get DATA fiche client
  useEffect(() => {
    if (location.state !== undefined) {
      axios
        .get(`${urlApi}fiche/client/${location.state}`, {
          headers: {
            Authorization: `Bearer ${(logger as any)?.token}`,
          },
        })
        .then((result) => {
          if (result.data[0] === null) {
            setCustomer(result.data[1]);
            setLoading(false);
          } else {
            axios
              .get(`${urlApi}get/username`, {
                headers: {
                  Authorization: `Bearer ${(logger as any)?.token}`,
                },
              })
              .then((res) => {
                setFicheClient({
                  ...result.data[0],
                  gestionnaireDeCompte: res.data,
                } as FormDataFicheClient);
              })
              .catch((e) => {
                console.log(e.message);
              });
            setCustomer(result.data[1]);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }

    // Get entité referentiel....
    axios
      .get(`${urlApi}bon/de/commande/ref/NaturePrestation`, {
        headers: {
          Authorization: `Bearer ${(logger as any)?.token}`,
        },
      })
      .then((res) => {
        Array.isArray(res.data) && setNaturePrest(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, [logger]);

  // Get data budget
  /* useEffect(() => {
    // Get data budget annuel....
    if (budgetAnnuel?.pays1 !== undefined) {
      const timer = setTimeout(() => {
        axios
          .post(`${urlApi}budget/annuel`, budgetAnnuel, {
            headers: {
              Authorization: `Bearer ${(logger as any)?.token}`,
            },
          })
          .then((res) => {
            setBA(res.data);
          })
          .catch((e) => {
            console.log(e.message);
          });

        // Get data du budget M-1.....
        axios
          .post(`${urlApi}budget/mmoins/un`, budgetAnnuel, {
            headers: {
              Authorization: `Bearer ${(logger as any)?.token}`,
            },
          })
          .then((res) => {
            Array.isArray(res.data) && setBudgetMmoinsUn(res.data);
          })
          .catch((e) => {
            console.log(e.message);
          });
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [logger, budgetAnnuel]); */

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Divider />
        <Grid container spacing={4}>
          <Grid item xs={2} style={{ marginTop: '4%' }}>
            <Box mt={4}>
              <List>
                {listSideBar.map((item) => {
                  const { text, icon } = item;
                  return (
                    <Fragment key={text}>
                      <ListItem
                        button
                        style={
                          text === 'Créer client'
                            ? { backgroundColor: '#e75113' }
                            : styles
                        }
                        onMouseEnter={
                          text === 'Liste clients'
                            ? () => setColor('#e75113')
                            : null
                        }
                        onMouseLeave={
                          text === 'Liste clients' ? () => setColor('') : null
                        }
                      >
                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                        <ListItemText
                          primary={text}
                          onClick={
                            text === 'Liste clients'
                              ? redirectToListContact
                              : null
                          }
                        />
                      </ListItem>
                      <Divider />
                    </Fragment>
                  );
                })}
              </List>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box
              className={classes.bodyStyle}
              my={2}
              ml={0}
              style={{ color: '#e75113' }}
            >
              <Typography
                variant="h5"
                style={{
                  textShadow: '#828181 0.05em 0.05em 0.05em',
                  textAlign: 'center',
                  fontSize: '30px',
                }}
              >
                Fiche client : {customer?.raisonSocial} <br />
              </Typography>{' '}
              <br />
              <Typography variant="h5">
                <div
                  style={{
                    color: '#77777B',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    fontSize: '17px',
                  }}
                >
                  Recap Bon(s) de Commande :
                </div>
                <TableNumeroBdc numBdc={customer} />
              </Typography>
            </Box>
            {/* <Divider /> */}
            <Box className={classes.bodyStyle}>
              {loading ? (
                loaderStyle
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        COORDONNEES CLIENT DO
                      </h5>
                      <h6 className={classes.simpleTitle}>Société :</h6>
                      <FormControl className={classes.formControl}>
                        <TextField
                          className={classes.TextField}
                          value={customer?.raisonSocial || ''}
                          name=""
                          onChange={null}
                          label="Raison sociale"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <TextField
                          className={classes.TextField}
                          value={
                            customer?.marqueCommercial
                              ? customer?.marqueCommercial
                              : customer?.raisonSocial
                          }
                          name=""
                          onChange={null}
                          label="Nom commercial"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <TextField
                          className={classes.TextField}
                          value={null}
                          name=""
                          onChange={null}
                          label="Activité"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <TextField
                          className={classes.TextField}
                          value={customer?.tel || null}
                          name=""
                          onChange={null}
                          label="Numéro de téléphone (Standard)"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <TextField
                          className={classes.TextField}
                          value={customer?.adresse || null}
                          name=""
                          onChange={null}
                          label="Adresse"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <Fragment>
                        <Grid item xs={12} md={12}>
                          <Typography
                            style={{
                              color: '#77777B',
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              fontSize: '17px',
                            }}
                            component="div"
                          >
                            Contacts :
                          </Typography>
                          <List>
                            {customer?.contacts?.map((row) => (
                              <ListItem key={row.id}>
                                <ListItemText>
                                  {' '}
                                  - {row.prenom} {row.nom} {row.fonction}{' '}
                                  {row.email} {row.tel}
                                </ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      </Fragment>
                      <Fragment>
                        <h5 className={classes.titleBackGround}>
                          CONTACTS OUTSOURCIA
                        </h5>{' '}
                        <br />
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={classes.TextField}
                            value={null}
                            name="commercial"
                            placeholder="Prénom + Nom + fonction"
                            onChange={handleChangeInput}
                            label="Commercial"
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={classes.TextField}
                            value={ficheClient?.gestionnaireDeCompte}
                            error={inputError?.gestionnaireDeCompte}
                            name="gestionnaireDeCompte"
                            placeholder="Prénom + Nom + fonction"
                            onChange={handleChangeInput}
                            label={
                              <span>
                                Gestionnaire de compte
                                <span style={{ color: 'red', fontSize: 20 }}>
                                  {' '}
                                  *
                                </span>
                              </span>
                            }
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              inputError?.gestionnaireDeCompte === true
                                ? 'Ce champ est obligatoire, merci de remplir ?'
                                : null
                            }
                          />
                        </FormControl>
                      </Fragment>
                      <Fragment>
                        <h5 className={classes.titleBackGround}>
                          ELEMENTS CONTRACTUEL
                        </h5>
                        <h6 className={classes.simpleTitle}>Contrats :</h6>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="civilite" /* shrink={ true } */>
                            Contrat
                          </InputLabel>
                          <Select
                            native
                            label="Statut contrat"
                            value={
                              ficheClient?.statutContrat === null
                                ? 'non existant'
                                : ficheClient?.statutContrat
                            }
                            onChange={handleChangeInput}
                            inputProps={{
                              name: 'statutContrat',
                            }}
                          >
                            <option aria-label="None" value="" />
                            <option value="non existant">non existant</option>
                            <option value="ok">ok</option>
                            <option value="en cours">en cours</option>
                            <option value="non démarré">non démarré</option>
                          </Select>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="civilite" /* shrink={ true } */>
                            Annexe contrat RGPD
                          </InputLabel>
                          <Select
                            native
                            label="Annexe contrat RGPD"
                            value={
                              ficheClient?.annexeContrat !== null
                                ? ficheClient?.annexeContrat
                                : 'non existant'
                            }
                            onChange={handleChangeInput}
                            inputProps={{
                              name: 'annexeContrat',
                            }}
                          >
                            <option aria-label="None" value="" />
                            <option value="non existant">non existant</option>
                            <option value="ok">ok</option>
                            <option value="en cours">en cours</option>
                            <option value="non démarré">non démarré</option>
                          </Select>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="civilite" /* shrink={ true } */>
                            Registre de traitement RGPD
                          </InputLabel>
                          <Select
                            native
                            label="Registre de traitement"
                            value={ficheClient?.registreTraitement || ''}
                            onChange={handleChangeInput}
                            inputProps={{
                              name: 'registreTraitement',
                            }}
                          >
                            <option aria-label="None" value="" />
                            <option value="en cours">en cours</option>
                            <option value="non démarré">non démarré</option>
                            <option value="finaliser">finaliser</option>
                          </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={classes.TextField}
                            value={
                              customer?.resumeLeads?.map(
                                (x) => x?.dureeTrt?.libelle
                              ) || null
                            }
                            name=""
                            onChange={null}
                            label="Durée du contrat"
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={classes.TextField}
                            value={
                              String(
                                customer?.resumeLeads?.map(
                                  (x) => x?.dureeTrt?.libelle
                                )
                              ) === 'récurrente'
                                ? 'OUI'
                                : 'NON'
                            }
                            onChange={handleChangeInput}
                            name="taciteReconduction"
                            label="Tacite reconduction"
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={classes.TextField}
                            value={String(
                              customer?.resumeLeads?.map((a) =>
                                a?.bdcs?.map((b) => b?.modeReglement)
                              )
                            )}
                            onChange={handleChangeInput}
                            name="modalitePaiement"
                            label="Modalités de paiement"
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={classes.TextField}
                            error={inputError?.versementAcompte}
                            value={ficheClient?.versementAcompte || ''} // Champ obligatoire
                            name="versementAcompte"
                            onChange={handleChangeInput}
                            label={
                              <span>
                                Versement d’un acompte
                                <span style={{ color: 'red', fontSize: 20 }}>
                                  {' '}
                                  *
                                </span>
                              </span>
                            }
                            variant="outlined"
                            helperText={
                              inputError?.versementAcompte === true
                                ? 'Ce champ est obligatoire, merci de remplir ?'
                                : null
                            }
                          />
                        </FormControl>
                        <h6 className={classes.simpleTitle}>
                          KPI&#39;s Contractuels :
                        </h6>
                        <Fragment>
                          <Grid item xs={12} md={12}>
                            <div>
                              {customer?.resumeLeads?.map((a) =>
                                a?.bdcs?.map((b) =>
                                  b?.bdcOperations?.map((row) => (
                                    <div key={row.id}>
                                      <strong>
                                        {b?.paysProduction?.libelle} /{' '}
                                        {row?.bu?.libelle} /{' '}
                                        {row?.operation?.libelle}
                                      </strong>{' '}
                                      {row?.objectifQuantitatif?.map((x) => (
                                        <div key={x.id}>
                                          <h6>Objectifs quantitatif :</h6>
                                          <p style={{ marginLeft: '15px' }}>
                                            - {x.libelle} : 70%
                                          </p>
                                          <p style={{ marginLeft: '15px' }}>
                                            - DMT : {row.dmt}
                                          </p>
                                        </div>
                                      ))}
                                      {row?.objectifQualitatif?.map((x) => (
                                        <div key={x.id}>
                                          <h6>Objectifs qualitatif :</h6>
                                          <p style={{ marginLeft: '15px' }}>
                                            - {x.libelle} : 55%
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  ))
                                )
                              )}
                            </div>
                          </Grid>
                        </Fragment>
                      </Fragment>
                      <Fragment>
                        <h5 className={classes.titleBackGround}>
                          DIMENSIONNEMENT ET OFFRE FINANCIERE
                        </h5>
                        <Grid item xs={12} md={12}>
                          <Typography
                            style={{
                              color: '#77777B',
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              fontSize: '17px',
                            }}
                            component="div"
                          >
                            Offre financière :
                          </Typography>
                          <List>
                            {customer?.resumeLeads?.map((a) =>
                              a?.bdcs?.map((b) =>
                                b?.bdcOperations?.map((row) => (
                                  <ListItem key={row.id}>
                                    <ListItemText>
                                      - {b?.paysProduction?.libelle} /{' '}
                                      {row?.bu?.libelle} /{' '}
                                      {row?.operation?.libelle} /{' '}
                                      {row?.typeFacturation?.libelle} /{' '}
                                      {row?.coutHoraire?.coutHoraire}{' '}
                                      {b?.devise?.libelle} /
                                    </ListItemText>
                                  </ListItem>
                                ))
                              )
                            )}
                          </List>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Typography
                            style={{
                              color: '#77777B',
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              fontSize: '17px',
                            }}
                            component="div"
                          >
                            Détails prix de vente :
                          </Typography>
                          <List>
                            {customer?.resumeLeads?.map((a) =>
                              a?.bdcs?.map((b) =>
                                b?.bdcOperations?.map((row) => (
                                  <ListItem key={row.id}>
                                    <ListItemText>
                                      - {b?.paysProduction?.libelle} /{' '}
                                      {row?.bu?.libelle} /{' '}
                                      {row?.operation?.libelle} /{' '}
                                      Tarif horaire cible : {row?.tarifHoraireCible}{' '}
                                      {b?.devise?.libelle} /{' '}
                                      {row?.tempsProductifs} / DMT : {row?.dmt} /
                                    </ListItemText>
                                  </ListItem>
                                ))
                              )
                            )}
                          </List>
                        </Grid>
                      </Fragment>
                      <Fragment>
                        <h5 className={classes.titleBackGround}>
                          RECRUTEMENT ET FORMATION
                        </h5>
                        <p className={classes.simpleTitle}>Profil:</p>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={classes.TextField}
                            error={inputError?.typeProfil}
                            value={ficheClient?.typeProfil} // Champ obligatoire
                            name="typeProfil"
                            onChange={handleChangeInput}
                            placeholder="Pays de production / BU / Opération / Niveau / Langues"
                            label={
                              <span style={{ color: 'red', fontSize: 20 }}>
                                {' '}
                                *
                              </span>
                            }
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              inputError?.typeProfil === true
                                ? 'Ce champ est obligatoire, merci de remplir ?'
                                : null
                            }
                          />
                        </FormControl>
                        <Grid item xs={12} md={12}>
                          <Typography
                            style={{
                              color: '#77777B',
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              fontSize: '17px',
                            }}
                            component="div"
                          >
                            Formation :
                          </Typography>
                          <div>
                            {customer?.resumeLeads?.map((a) =>
                              a?.bdcs?.map((b) =>
                                b?.bdcOperations?.map((row) => (
                                  <div key={row.id}>
                                    {row?.operation?.libelle ===
                                      'FORMATION' && (
                                      <h6 style={{ marginLeft: '15px' }}>
                                        - {b?.paysProduction?.libelle} /{' '}
                                        {row?.bu?.libelle} /{' '}
                                        {row?.operation?.libelle} / ? jours
                                      </h6>
                                    )}
                                  </div>
                                ))
                              )
                            )}
                          </div>
                        </Grid>
                      </Fragment>
                      <Fragment>
                        <h5 className={classes.titleBackGround}>
                          RAPPORTS D’ACTIVITE
                        </h5>
                        <Grid item xs={12} md={12}>
                          <List>
                            {customer?.resumeLeads?.map((a) =>
                              a?.bdcs?.map((b) =>
                                b?.bdcOperations?.map((row) => (
                                  <ListItem key={row.id}>
                                    <ListItemText>
                                      <a
                                        rel="noreferrer"
                                        href="http://172.25.52.152/"
                                        target="_blank"
                                        title="Redirection vers Cloud Sourcia..."
                                        className={classes.lienRapportActivite}
                                      >
                                        - {b?.paysProduction?.libelle} /{' '}
                                        {row?.bu?.libelle}
                                      </a>
                                    </ListItemText>
                                  </ListItem>
                                ))
                              )
                            )}
                          </List>
                        </Grid>
                      </Fragment>
                      <Fragment>
                        <h5 className={classes.titleBackGround}>
                          CONFIGURATION IT
                        </h5>
                        <h6 className={classes.simpleTitle}>Outils :</h6>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={classes.TextField}
                            error={inputError?.outils}
                            value={ficheClient?.outils} // Champ obligatoire
                            name="outils"
                            onChange={handleChangeInput}
                            placeholder="Pays de production / BU / Opération / Outils"
                            label={
                              <span style={{ color: 'red', fontSize: 20 }}>
                                {' '}
                                *
                              </span>
                            }
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              inputError?.outils === true
                                ? 'Ce champ est obligatoire, merci de remplir ?'
                                : null
                            }
                          />
                        </FormControl>
                        <h6 className={classes.simpleTitle}>
                          Configurations spécifiques :
                        </h6>
                        <FormControl className={classes.formControl}>
                          <TextField
                            className={classes.TextField}
                            error={inputError?.confSpecifique}
                            value={ficheClient?.confSpecifique} // Champ obligatoire
                            name="confSpecifique"
                            onChange={handleChangeInput}
                            placeholder="bureau à distance / espace fermé ...."
                            label={
                              <span style={{ color: 'red', fontSize: 20 }}>
                                {' '}
                                *
                              </span>
                            }
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={
                              inputError?.confSpecifique === true
                                ? 'Ce champ est obligatoire, merci de remplir ?'
                                : null
                            }
                          />
                        </FormControl>
                      </Fragment>
                    </Fragment>
                  </Grid>

                  <Grid item xs={12}>
                    {/* <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<VisibilityIcon />}
                      onClick={() => setShowBudgetAnnuel(true)}
                    >
                      Complet
                    </Button> */}
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        CUMUL ANNUEL (année en cours)
                      </h5>
                      <TableCummulAnnuel data={customer} />
                    </Fragment>
                    <Fragment>
                      <h5 className={classes.titleBackGround}>
                        Depuis le début du partenariat
                      </h5>
                      <TableEvolution data={customer} /> <br /> <br />
                    </Fragment>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <Grid item xs={4}>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          size="medium"
                          startIcon={<SaveIcon />}
                          onClick={handleSaveUpdateFicheClient}
                        >
                          Enregistrer
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          size="medium"
                          startIcon={<ImportExportIcon />}
                        >
                          Export excel
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          size="medium"
                          startIcon={<ImportExportIcon />}
                          onClick={handleExportPdf}
                        >
                          Export PDF
                        </Button>
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default FicheClient;
