import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { loggingUser } from '../../redux/actions/logginUser';
import axios from 'axios';

const urlLogin = process.env.REACT_APP_LOGIN_URL as string;

// intercept the resonse of all axios requests
axios.interceptors.response.use(
    function myFunctionResponse (response) {
        return response;
    },
    function myFunctionError (error) {
        if (error) {
            if (error?.response?.status === 401) {
                /**
                 * if there is a response which has a status code 401
                 * after the code redirect it to the login page of CRM
                 **/
                window.location.assign(urlLogin);
            }
            return Promise.reject(error);
        }
    }
);

const HomeBdc = () => {
    const { token } = useParams<{ token: any }>();

    const dispatch = useDispatch();

    const history = useHistory();

    if (token) {
        const cookies = new Cookies();

        // Set info user in navigateur coockies
        cookies.set('user_parcours_client', token, { path: '/' });

        // Get info user via coockies navigateur
        const userInformation = cookies.get('user_parcours_client');

        /**
         * save in Redux store the user information with his token
         * New code fait par Antoni
         */
        if (userInformation) {
            dispatch(
                loggingUser({
                    id: parseInt(userInformation?.userId, 10),
                    username: userInformation?.username,
                    role: userInformation?.role?.length ? userInformation?.role[0] : [],
                    token: userInformation?.payload?.token,
                    name: userInformation?.username,
                })
            );

            // Redirect to bdc list
            const role = userInformation?.role?.length ? userInformation?.role[0] : '';
            console.log(role);
            if (role === 'ROLE_JURISTE') {
                 history.push('/HausseIndiceSyntec');
            } else {
                history.push('/bdc_list');
            }
        } else {
            window.location.assign(urlLogin);
        }
    }

    return (
        <div></div>
    );
};

export default HomeBdc;
