
 export interface Ligne {
    client: string;
    operationLabel: string;
    PrixUnitaire: string;
    idBdcO : number;
  }
  export class LigneClass {
    client: string;
    operationLabel: string;
    PrixUnitaire: string;
    constructor (client: string, operationLabel: string, pp: string) {
      this.PrixUnitaire = client;
      this.operationLabel = operationLabel;
      this.PrixUnitaire = pp;
    }
  }
  export interface BdcOperationPerClient {
    raisonSocial: string;
    ligne: Ligne[];
    valide: number;
    taux : number;
    dateContart : string;
    clause : string;
    isType : string;
    initial : string;
    actuel : string;
    idCustomer : number;
    idBdcO : number;
  }
  export class BdcOperationPerClientClass {
    raisonSocial: string;
    ligne: Ligne[];
    valide: number;
    taux : number;
    dateContart : string;
    clause : string;
    isType : string;
    initial : string;
    actuel : string;
    idCustomer : number;
    idoperation : number;
    dateAplicatif : string;
    constructor (raisonSocial: string, valide: number, ligne: Ligne[], taux : number, dateContart : string, clause : string, isType : string, initial : string, actuel : string, idCustomer : number, dateAplicatif : string) {
      this.raisonSocial = raisonSocial;
      this.valide = valide;
      this.ligne = ligne;
      this.taux = taux;
      this.dateContart = dateContart;
      this.clause = clause;
      this.isType = isType;
      this.initial = initial;
      this.actuel = actuel;
      this.idCustomer = idCustomer;
      this.dateAplicatif = dateAplicatif;
    }
  }
